{
  "locale": "is",
  "countries": {
    "AD": "Andorra",
    "AE": "Sameinuðu arabísku furstadæmin",
    "AF": "Afganistan",
    "AG": "Antígva og Barbúda",
    "AI": "Angvilla",
    "AL": "Albanía",
    "AM": "Armenía",
    "AO": "Angóla",
    "AQ": "Suðurskautslandið",
    "AR": "Argentína",
    "AS": "Bandaríska Samóa",
    "AT": "Austurríki",
    "AU": "Ástralía",
    "AW": "Arúba",
    "AX": "Álandseyjar",
    "AZ": "Aserbaísjan",
    "BA": "Bosnía og Hersegóvína",
    "BB": "Barbados",
    "BD": "Bangladess",
    "BE": "Belgía",
    "BF": "Búrkína Fasó",
    "BG": "Búlgaría",
    "BH": "Barein",
    "BI": "Búrúndí",
    "BJ": "Benín",
    "BL": "Saint-Barthélemy",
    "BM": "Bermúda",
    "BN": "Brúnei",
    "BO": "Bólivía",
    "BQ": "Bonaire",
    "BR": "Brasilía",
    "BS": "Bahamas",
    "BT": "Bútan",
    "BV": "Bouveteyja",
    "BW": "Botsvana",
    "BY": "Hvíta-Rússland",
    "BZ": "Belís",
    "CA": "Kanada",
    "CC": "Kókoseyjar",
    "CD": "Lýðstjórnarlýðveldið Kongó",
    "CF": "Mið-Afríkulýðveldið",
    "CG": "Kongó",
    "CH": "Sviss",
    "CI": "Fílabeinsströndin",
    "CK": "Cooks-eyjar",
    "CL": "Síle",
    "CM": "Kamerún",
    "CN": "Kína",
    "CO": "Kólumbía",
    "CR": "Kosta Ríka",
    "CU": "Kúba",
    "CV": "Grænhöfðaeyjar",
    "CW": "Curaçao",
    "CX": "Jólaeyja",
    "CY": "Kípur",
    "CZ": "Tékkland",
    "DE": "Þýskaland",
    "DJ": "Djibútí",
    "DK": "Danmörk",
    "DM": "Dóminíka",
    "DO": "Dóminíska lýðveldið",
    "DZ": "Alsír",
    "EC": "Ekvador",
    "EE": "Eistland",
    "EG": "Egyptaland",
    "EH": "Vestur-Sahara",
    "ER": "Eritrea",
    "ES": "Spánn",
    "ET": "Eþíópía",
    "FI": "Finnland",
    "FJ": "Fídjíeyjar",
    "FK": "Falklandseyjar",
    "FM": "Míkrónesía",
    "FO": "Færeyjar",
    "FR": "Frakkland",
    "GA": "Gabon",
    "GB": "Bretland",
    "GD": "Grenada",
    "GE": "Georgía",
    "GF": "Franska Gvæjana",
    "GG": "Guernsey",
    "GH": "Gana",
    "GI": "Gíbraltar",
    "GL": "Grænland",
    "GM": "Gambía",
    "GN": "Gínea",
    "GP": "Gvadelúpeyjar",
    "GQ": "Miðbaugs-Gínea",
    "GR": "Grikkland",
    "GS": "Suður-Georgía og Suður-Sandvíkureyjar",
    "GT": "Gvatemala",
    "GU": "Gvam",
    "GW": "Gínea-Bissá",
    "GY": "Gvæjana",
    "HK": "Hong Kong",
    "HM": "Heard og McDonaldseyjar",
    "HN": "Hondúras",
    "HR": "Króatía",
    "HT": "Haítí",
    "HU": "Ungverjaland",
    "ID": "Indónesía",
    "IE": "Írland",
    "IL": "Ísrael",
    "IM": "Mön",
    "IN": "Indland",
    "IO": "Bresku Indlandshafseyjar",
    "IQ": "Írak",
    "IR": "Íran",
    "IS": "Ísland",
    "IT": "Ítalía",
    "JE": "Jersey",
    "JM": "Jamaíka",
    "JO": "Jórdanía",
    "JP": "Japan",
    "KE": "Kenía",
    "KG": "Kirgistan",
    "KH": "Kambódía",
    "KI": "Kíribatí",
    "KM": "Kómoreyjar",
    "KN": "Sankti Kristófer og Nevis",
    "KP": "Norður-Kórea",
    "KR": "Suður-Kórea",
    "KW": "Kúveit",
    "KY": "Cayman-eyjar",
    "KZ": "Kasakstan",
    "LA": "Laos",
    "LB": "Líbanon",
    "LC": "Sankti Lúsía",
    "LI": "Liechtenstein",
    "LK": "Srí Lanka",
    "LR": "Líbería",
    "LS": "Lesótó",
    "LT": "Litháen",
    "LU": "Lúxemborg",
    "LV": "Lettland",
    "LY": "Líbía",
    "MA": "Marokkó",
    "MC": "Mónakó",
    "MD": "Moldóva",
    "ME": "Svartfjallaland",
    "MF": "Saint-Martin",
    "MG": "Madagaskar",
    "MH": "Marshalleyjar",
    "MK": "Norður-Makedónía",
    "ML": "Malí",
    "MM": "Mjanmar",
    "MN": "Mongólía",
    "MO": "Makaó",
    "MP": "Norður-Maríanaeyjar",
    "MQ": "Martinique",
    "MR": "Máritanía",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Máritíus",
    "MV": "Maldívur",
    "MW": "Malaví",
    "MX": "Mexíkó",
    "MY": "Malasía",
    "MZ": "Mósambík",
    "NA": "Namibía",
    "NC": "Nýja-Kaledónía",
    "NE": "Níger",
    "NF": "Norfolkeyja",
    "NG": "Nígería",
    "NI": "Níkaragva",
    "NL": "Holland",
    "NO": "Noregur",
    "NP": "Nepal",
    "NR": "Naúrú",
    "NU": "Niue",
    "NZ": "Nýja-Sjáland",
    "OM": "Óman",
    "PA": "Panama",
    "PE": "Perú",
    "PF": "Franska Pólýnesía",
    "PG": "Papúa Nýja-Gínea",
    "PH": "Filippseyjar",
    "PK": "Pakistan",
    "PL": "Pólland",
    "PM": "Sankti Pierre og Miquelon",
    "PN": "Pitcairn",
    "PR": "Púertó Ríkó",
    "PS": "Palestína",
    "PT": "Portúgal",
    "PW": "Palá",
    "PY": "Paragvæ",
    "QA": "Katar",
    "RE": "Réunion",
    "RO": "Rúmenía",
    "RS": "Serbía",
    "RU": "Rússland",
    "RW": "Rúanda",
    "SA": "Sádi-Arabía",
    "SB": "Salómonseyjar",
    "SC": "Seychelles-eyjar",
    "SD": "Súdan",
    "SE": "Svíþjóð",
    "SG": "Singapúr",
    "SH": "Sankti Helena",
    "SI": "Slóvenía",
    "SJ": "Svalbarði",
    "SK": "Slóvakía",
    "SL": "Síerra Leóne",
    "SM": "San Marínó",
    "SN": "Senegal",
    "SO": "Sómalía",
    "SR": "Súrínam",
    "SS": "Suður-Súdan",
    "ST": "Saó Tóme og Prinsípe",
    "SV": "El Salvador",
    "SX": "Sint Maarten",
    "SY": "Sýrland",
    "SZ": "Esvatíní",
    "TC": "Turks- og Caicoseyjar",
    "TD": "Tjad",
    "TF": "Frönsku suðlægu landsvæðin",
    "TG": "Tógó",
    "TH": "Taíland",
    "TJ": "Tadsíkistan",
    "TK": "Tókelá",
    "TL": "Austur-Tímor",
    "TM": "Túrkmenistan",
    "TN": "Túnis",
    "TO": "Tonga",
    "TR": "Tyrkland",
    "TT": "Trínidad og Tóbagó",
    "TV": "Túvalú",
    "TW": "Taívan",
    "TZ": "Tansanía",
    "UA": "Úkraína",
    "UG": "Úganda",
    "UM": "Smáeyjar Bandaríkjanna",
    "US": "Bandaríkin",
    "UY": "Úrúgvæ",
    "UZ": "Úsbekistan",
    "VA": "Vatíkanið",
    "VC": "Sankti Vinsent og Grenadínur",
    "VE": "Venesúela",
    "VG": "Bresku Jómfrúaeyjar",
    "VI": "Bandarísku Jómfrúaeyjar",
    "VN": "Víetnam",
    "VU": "Vanúatú",
    "WF": "Wallis- og Fútúnaeyjar",
    "WS": "Samóa",
    "XK": "Kósovó",
    "YE": "Jemen",
    "YT": "Mayotte",
    "ZA": "Suður-Afríka",
    "ZM": "Sambía",
    "ZW": "Simbabve"
  }
}
