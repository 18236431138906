{
  "locale": "ug",
  "countries": {
    "AF": "ئافغانىستان",
    "AL": "ئالبانىيە",
    "DZ": "ئالجىرىيە",
    "AS": "ئامېرىكا ساموئا",
    "AD": "ئاندوررا",
    "AO": "ئانگولا",
    "AI": "ئانگۋىللا",
    "AQ": "ئانتاركتىكا",
    "AG": "ئانتىگۇئا ۋە باربۇدا",
    "AR": "ئارگېنتىنا",
    "AM": "ئەرمېنىيە",
    "AW": "ئارۇبا",
    "AU": "ئاۋسترالىيە",
    "AT": "ئاۋىستىرىيە",
    "AZ": "ئەزەربەيجان",
    "BS": "باھاما",
    "BH": "بەھرەين",
    "BD": "بېنگال",
    "BB": "باربادوس",
    "BY": "بېلارۇسىيە",
    "BE": "بېلگىيە",
    "BZ": "بېلىز",
    "BJ": "بېنىن",
    "BM": "بېرمۇدا",
    "BT": "بۇتان",
    "BO": "بولىۋىيە",
    "BA": "بوسىنىيە ۋە گېرتسېگوۋىنا",
    "BW": "بوتسۋانا",
    "BV": "بوۋېت ئارىلى",
    "BR": "بىرازىلىيە",
    "IO": "ئەنگلىيەگە قاراشلىق ھىندى ئوكيان تېررىتورىيەسى",
    "BN": "بىرۇنېي",
    "BG": "بۇلغارىيە",
    "BF": "بۇركىنا فاسو",
    "BI": "بۇرۇندى",
    "KH": "كامبودژا",
    "CM": "كامېرون",
    "CA": "كانادا",
    "CV": "يېشىل تۇمشۇق",
    "KY": "كايمان ئاراللىرى",
    "CF": "ئوتتۇرا ئافرىقا جۇمھۇرىيىتى",
    "TD": "چاد",
    "CL": "چىلى",
    "CN": "جۇڭگو",
    "CX": "مىلاد ئارىلى",
    "CC": "كوكوس (كىلىڭ) ئاراللىرى",
    "CO": "كولومبىيە",
    "KM": "كومورو",
    "CG": "كونگو - بىراززاۋىل",
    "CD": "كونگو - كىنشاسا",
    "CK": "كۇك ئاراللىرى",
    "CR": "كوستارىكا",
    "CI": "كوتې دې ئىۋوئىر",
    "HR": "كىرودىيە",
    "CU": "كۇبا",
    "CY": "سىپرۇس",
    "CZ": "چېخ جۇمھۇرىيىتى",
    "DK": "دانىيە",
    "DJ": "جىبۇتى",
    "DM": "دومىنىكا",
    "DO": "دومىنىكا جۇمھۇرىيىتى",
    "EC": "ئېكۋاتور",
    "EG": "مىسىر",
    "SV": "سالۋادور",
    "GQ": "ئېكۋاتور گىۋىنىيەسى",
    "ER": "ئېرىترىيە",
    "EE": "ئېستونىيە",
    "ET": "ئېفىيوپىيە",
    "FK": "فالكلاند ئاراللىرى",
    "FO": "فارو ئاراللىرى",
    "FJ": "فىجى",
    "FI": "فىنلاندىيە",
    "FR": "فىرانسىيە",
    "GF": "فىرانسىيەگە قاراشلىق گىۋىيانا",
    "PF": "فىرانسىيەگە قاراشلىق پولىنېزىيە",
    "TF": "فىرانسىيەنىڭ جەنۇبىي زېمىنى",
    "GA": "گابون",
    "GM": "گامبىيە",
    "GE": "گىرۇزىيە",
    "DE": "گېرمانىيە",
    "GH": "گانا",
    "GI": "جەبىلتارىق",
    "GR": "گىرېتسىيە",
    "GL": "گىرېنلاندىيە",
    "GD": "گىرېنادا",
    "GP": "گىۋادېلۇپ",
    "GU": "گۇئام",
    "GT": "گىۋاتېمالا",
    "GN": "گىۋىنىيە",
    "GW": "گىۋىنىيە بىسسائۇ",
    "GY": "گىۋىيانا",
    "HT": "ھايتى",
    "HM": "ھېرد ئارىلى ۋە ماكدونالد ئاراللىرى",
    "VA": "ۋاتىكان",
    "HN": "ھوندۇراس",
    "HK": "شياڭگاڭ ئالاھىدە مەمۇرىي رايونى (جۇڭگو)",
    "HU": "ۋېنگىرىيە",
    "IS": "ئىسلاندىيە",
    "IN": "ھىندىستان",
    "ID": "ھىندونېزىيە",
    "IR": "ئىران",
    "IQ": "ئىراق",
    "IE": "ئىرېلاندىيە",
    "IL": "ئىسرائىلىيە",
    "IT": "ئىتالىيە",
    "JM": "يامايكا",
    "JP": "ياپونىيە",
    "JO": "ئىيوردانىيە",
    "KZ": "قازاقىستان",
    "KE": "كېنىيە",
    "KI": "كىرىباتى",
    "KP": "چاۋشيەن",
    "KR": "كورېيە",
    "KW": "كۇۋەيت",
    "KG": "قىرغىزىستان",
    "LA": "لائوس",
    "LV": "لاتۋىيە",
    "LB": "لىۋان",
    "LS": "لېسوتو",
    "LR": "لىبېرىيە",
    "LY": "لىۋىيە",
    "LI": "لىكتېنستېين",
    "LT": "لىتۋانىيە",
    "LU": "لىيۇكسېمبۇرگ",
    "MO": "ئاۋمېن ئالاھىدە مەمۇرىي رايونى",
    "MG": "ماداغاسقار",
    "MW": "مالاۋى",
    "MY": "مالايسىيا",
    "MV": "مالدىۋې",
    "ML": "مالى",
    "MT": "مالتا",
    "MH": "مارشال ئاراللىرى",
    "MQ": "مارتىنىكا",
    "MR": "ماۋرىتانىيە",
    "MU": "ماۋرىتىيۇس",
    "YT": "مايوتى",
    "MX": "مېكسىكا",
    "FM": "مىكرونېزىيە",
    "MD": "مولدوۋا",
    "MC": "موناكو",
    "MN": "موڭغۇلىيە",
    "MS": "مونتسېررات",
    "MA": "ماراكەش",
    "MZ": "موزامبىك",
    "MM": "بىرما",
    "NA": "نامىبىيە",
    "NR": "ناۋرۇ",
    "NP": "نېپال",
    "NL": "گوللاندىيە",
    "NC": "يېڭى كالېدونىيە",
    "NZ": "يېڭى زېلاندىيە",
    "NI": "نىكاراگۇئا",
    "NE": "نىگېر",
    "NG": "نىگېرىيە",
    "NU": "نيۇئې",
    "NF": "نورفولك ئارىلى",
    "MK": "شىمالىي ماكېدونىيە",
    "MP": "شىمالىي مارىيانا ئاراللىرى",
    "NO": "نورۋېگىيە",
    "OM": "ئومان",
    "PK": "پاكىستان",
    "PW": "پالائۇ",
    "PS": "پەلەستىن زېمىنى",
    "PA": "پاناما",
    "PG": "پاپۇئا يېڭى گىۋىنىيەسى",
    "PY": "پاراگۋاي",
    "PE": "پېرۇ",
    "PH": "فىلىپپىن",
    "PN": "پىتكايرن ئاراللىرى",
    "PL": "پولشا",
    "PT": "پورتۇگالىيە",
    "PR": "پۇئېرتو رىكو",
    "QA": "قاتار",
    "RE": "رېيۇنىيون",
    "RO": "رومىنىيە",
    "RU": "رۇسىيە",
    "RW": "رىۋاندا",
    "SH": "ساينىت ھېلېنا",
    "KN": "ساينت كىتىس ۋە نېۋىس",
    "LC": "ساينت لۇسىيە",
    "PM": "ساينت پىيېر ۋە مىكېلون ئاراللىرى",
    "VC": "ساينت ۋىنسېنت ۋە گىرېنادىنېس",
    "WS": "ساموئا",
    "SM": "سان مارىنو",
    "ST": "سان تومې ۋە پرىنسىپې",
    "SA": "سەئۇدىي ئەرەبىستان",
    "SN": "سېنېگال",
    "SC": "سېيشېل",
    "SL": "سېررالېئون",
    "SG": "سىنگاپور",
    "SK": "سىلوۋاكىيە",
    "SI": "سىلوۋېنىيە",
    "SB": "سولومون ئاراللىرى",
    "SO": "سومالى",
    "ZA": "جەنۇبىي ئافرىقا",
    "GS": "جەنۇبىي جورجىيە ۋە جەنۇبىي ساندۋىچ ئاراللىرى",
    "ES": "ئىسپانىيە",
    "LK": "سىرىلانكا",
    "SD": "سۇدان",
    "SR": "سۇرىنام",
    "SJ": "سىۋالبارد ۋە يان مايېن",
    "SZ": "سىۋېزىلاند",
    "SE": "شىۋېتسىيە",
    "CH": "شىۋېتسارىيە",
    "SY": "سۇرىيە",
    "TW": "تەيۋەن",
    "TJ": "تاجىكىستان",
    "TZ": "تانزانىيە",
    "TH": "تايلاند",
    "TL": "شەرقىي تىمور",
    "TG": "توگو",
    "TK": "توكېلاۋ",
    "TO": "تونگا",
    "TT": "تىرىنىداد ۋە توباگو",
    "TN": "تۇنىس",
    "TR": "تۈركىيە",
    "TM": "تۈركمەنىستان",
    "TC": "تۇركس ۋە كايكوس ئاراللىرى",
    "TV": "تۇۋالۇ",
    "UG": "ئۇگاندا",
    "UA": "ئۇكرائىنا",
    "AE": "ئەرەب بىرلەشمە خەلىپىلىكى",
    "GB": "بىرلەشمە پادىشاھلىق",
    "US": "ئامېرىكا قوشما ئىشتاتلىرى",
    "UM": "ئا ق ش تاشقى ئاراللىرى",
    "UY": "ئۇرۇگۋاي",
    "UZ": "ئۆزبېكىستان",
    "VU": "ۋانۇئاتۇ",
    "VE": "ۋېنېسۇئېلا",
    "VN": "ۋىيېتنام",
    "VG": "ئەنگلىيە ۋىرگىن ئاراللىرى",
    "VI": "ئا ق ش ۋىرگىن ئاراللىرى",
    "WF": "ۋاللىس ۋە فۇتۇنا",
    "EH": "غەربىي ساخارا",
    "YE": "يەمەن",
    "ZM": "زامبىيە",
    "ZW": "زىمبابۋې",
    "AX": "ئالاند ئاراللىرى",
    "BQ": "كارىب دېڭىزى گوللاندىيە",
    "CW": "كۇراچاۋ",
    "GG": "گۇرنسېي",
    "IM": "مان ئارىلى",
    "JE": "جېرسېي",
    "ME": "قارا تاغ",
    "BL": "ساينت بارتېلېمى",
    "MF": "ساينت مارتىن",
    "RS": "سېربىيە",
    "SX": "سىنت مارتېن",
    "SS": "جەنۇبىي سۇدان",
    "XK": "كوسوۋو"
  }
}
