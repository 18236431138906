import { OverlayRef } from '@angular/cdk/overlay';

import { Observable, Subject } from 'rxjs';

export class SidebarOverlayRef {
  private _afterClosedSubject = new Subject<void>();

  constructor(private readonly _overlayRef: OverlayRef) {}

  close(): void {
    this._overlayRef.dispose();
    this._afterClosedSubject.next();
    this._afterClosedSubject.complete();
  }

  afterClosed(): Observable<void> {
    return this._afterClosedSubject.asObservable();
  }
}
