{
  "locale": "ca",
  "countries": {
    "AF": "Afganistan",
    "AX": "Åland, illes",
    "AL": "Albània",
    "DE": "Alemanya",
    "DZ": "Algèria",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antàrtida",
    "AG": "Antigua i Barbuda",
    "SA": "Aràbia Saudita",
    "AR": "Argentina",
    "AM": "Armènia",
    "AW": "Aruba",
    "AU": "Austràlia",
    "AT": "Àustria",
    "AZ": "Azerbaidjan",
    "BS": "Bahames",
    "BH": "Bahrain",
    "BD": "Bangla Desh",
    "BB": "Barbados",
    "BE": "Bèlgica",
    "BZ": "Belize",
    "BJ": "Benín",
    "BM": "Bermudes",
    "BT": "Bhutan",
    "BY": "Bielorússia",
    "BO": "Bolívia",
    "BQ": "Bonaire, Sint Eustatius i Saba",
    "BA": "Bòsnia i Hercegovina",
    "BW": "Botswana",
    "BV": "Bouvet",
    "BR": "Brasil",
    "BN": "Brunei",
    "BG": "Bulgària",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KY": "Caiman, illes",
    "KH": "Cambodja",
    "CM": "Camerun",
    "CA": "Canadà",
    "CV": "Cap Verd",
    "CF": "Centreafricana, República",
    "CX": "Christmas, illa",
    "CC": "Cocos, illes",
    "CO": "Colòmbia",
    "KM": "Comores",
    "CG": "Congo, República del",
    "CD": "Congo, República Democràtica del",
    "CK": "Cook, illes",
    "KP": "Corea del Nord",
    "KR": "Corea del Sud",
    "CI": "Costa d'Ivori",
    "CR": "Costa Rica",
    "HR": "Croàcia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "DK": "Dinamarca",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominicana, República",
    "EG": "Egipte",
    "EC": "Equador",
    "AE": "Emirats Àrabs Units",
    "ER": "Eritrea",
    "SK": "Eslovàquia",
    "SI": "Eslovènia",
    "ES": "Espanya",
    "US": "Estats Units (EUA)",
    "EE": "Estònia",
    "ET": "Etiòpia",
    "FO": "Fèroe, illes",
    "FJ": "Fiji",
    "PH": "Filipines",
    "FI": "Finlàndia",
    "FR": "França",
    "GA": "Gabon",
    "GM": "Gàmbia",
    "GE": "Geòrgia",
    "GS": "Geòrgia del Sud i Sandwich del Sud, illes",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Grècia",
    "GD": "Grenada",
    "GL": "Groenlàndia",
    "GP": "Guadeloupe",
    "GF": "Guaiana Francesa",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "República de Guinea",
    "GW": "Guinea Bissau",
    "GQ": "Guinea Equatorial",
    "GY": "Guyana",
    "HT": "Haití",
    "HM": "Heard, illa i McDonald, illes",
    "HN": "Hondures",
    "HK": "Hong Kong",
    "HU": "Hongria",
    "YE": "Iemen",
    "IM": "Illa de Man",
    "UM": "Illes Perifèriques Menors dels EUA",
    "IN": "Índia",
    "ID": "Indonèsia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Irlanda",
    "IS": "Islàndia",
    "IL": "Israel",
    "IT": "Itàlia",
    "JM": "Jamaica",
    "JP": "Japó",
    "JE": "Jersey",
    "JO": "Jordània",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KG": "Kirguizistan",
    "KI": "Kiribati",
    "KW": "Kuwait",
    "LA": "Laos",
    "LS": "Lesotho",
    "LV": "Letònia",
    "LB": "Líban",
    "LR": "Libèria",
    "LY": "Líbia",
    "LI": "Liechtenstein",
    "LT": "Lituània",
    "LU": "Luxemburg",
    "MO": "Macau",
    "MK": "Macedònia del Nord",
    "MG": "Madagascar",
    "MY": "Malàisia",
    "MW": "Malawi",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "FK": "Malvines, illes",
    "MP": "Mariannes Septentrionals, illes",
    "MA": "Marroc",
    "MH": "Marshall, illes",
    "MQ": "Martinica",
    "MU": "Maurici",
    "MR": "Mauritània",
    "YT": "Mayotte",
    "MX": "Mèxic",
    "FM": "Micronèsia, Estats Federats de",
    "MZ": "Moçambic",
    "MD": "Moldàvia",
    "MC": "Mònaco",
    "MN": "Mongòlia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MM": "Myanmar",
    "NA": "Namíbia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NI": "Nicaragua",
    "NE": "Níger",
    "NG": "Nigèria",
    "NU": "Niue",
    "NF": "Norfolk, illa",
    "NO": "Noruega",
    "NC": "Nova Caledònia",
    "NZ": "Nova Zelanda",
    "OM": "Oman",
    "NL": "Països Baixos",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestina",
    "PA": "Panamà",
    "PG": "Papua Nova Guinea",
    "PY": "Paraguai",
    "PE": "Perú",
    "PN": "Pitcairn, illes",
    "PF": "Polinèsia Francesa",
    "PL": "Polònia",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "GB": "Regne Unit",
    "RE": "Reunió, illa de la",
    "RO": "Romania",
    "RU": "Rússia",
    "RW": "Ruanda",
    "EH": "Sàhara Occidental",
    "KN": "Saint Kitts i Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint-Pierre i Miquelon",
    "VC": "Saint Vincent i les Grenadines",
    "BL": "Saint-Barthélemy",
    "MF": "Saint-Martin",
    "SB": "Salomó",
    "SV": "Salvador, El",
    "WS": "Samoa",
    "AS": "Samoa Nord-americana",
    "SM": "San Marino",
    "SH": "Santa Helena",
    "ST": "São Tomé i Príncipe",
    "SN": "Senegal",
    "RS": "Sèrbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapur",
    "SX": "Sint Maarten",
    "SY": "Síria",
    "SO": "Somàlia",
    "LK": "Sri Lanka",
    "ZA": "Sud-àfrica",
    "SD": "Sudan",
    "SS": "Sudan del Sud",
    "SE": "Suècia",
    "CH": "Suïssa",
    "SR": "Surinam",
    "SJ": "Svalbard i Jan Mayen",
    "SZ": "Swazilàndia",
    "TJ": "Tadjikistan",
    "TH": "Tailàndia",
    "TW": "Taiwan",
    "TZ": "Tanzània",
    "IO": "Territori Britànic de l'Oceà Índic",
    "TF": "Territoris Francesos del Sud",
    "TL": "Timor Oriental",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinitat i Tobago",
    "TN": "Tunísia",
    "TM": "Turkmenistan",
    "TC": "Turks i Caicos, illes",
    "TR": "Turquia",
    "TV": "Tuvalu",
    "TD": "Txad",
    "CZ": "Txèquia",
    "UA": "Ucraïna",
    "UG": "Uganda",
    "UY": "Uruguai",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Vaticà, Ciutat del",
    "VE": "Veneçuela",
    "VG": "Verges Britàniques, illes",
    "VI": "Verges Nord-americanes, illes",
    "VN": "Vietnam",
    "WF": "Wallis i Futuna",
    "CL": "Xile",
    "CN": "Xina",
    "CY": "Xipre",
    "ZM": "Zàmbia",
    "ZW": "Zimbabwe",
    "XK": "Kosovo"
  }
}
