import { ChangeDetectionStrategy, Component, input, TemplateRef } from '@angular/core';
import {
  FormControlDirective,
  FormControlName,
  FormsModule,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ControlType, MaxNumberLength, MinNumberLength } from '@shared/models';
import { ValidationMessageComponent } from '@shared/components';
import { NoopValueAccessorDirective } from '@shared/directives';
import { injectNgControl } from '@shared/utils';

@Component({
  selector: 'ui-input',
  standalone: true,
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective],
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    ValidationMessageComponent,
    TranslateModule,
  ],
})
export class InputComponent<T, R> {
  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();

  /**
   * Control type
   */
  controlType = input<ControlType>('text');

  /**
   * Input autocomplete
   */
  autocomplete = input<string>('');

  /**
   * Icon of input
   */
  iconTemplate = input<TemplateRef<R> | null>(null);

  /**
   * Indicates if input is non-editable and readonly
   */
  isReadonly = input<boolean>(false);

  /**
   * Placeholder for input
   */
  placeholder = input<string>('');
  min = input<number>(MinNumberLength);
  max = input<number>(MaxNumberLength);
  step = input<number>(1);
}
