{
  "locale": "br",
  "countries": {
    "AD": "Andorra",
    "AE": "Emirelezhioù Arab Unanet",
    "AF": "Afghanistan",
    "AG": "Antigua ha Barbuda",
    "AI": "Anguilla",
    "AL": "Albania",
    "AM": "Armenia",
    "AO": "Angola",
    "AQ": "Antarktika",
    "AR": "Arc'hantina",
    "AS": "Samoa Amerikan",
    "AT": "Aostria",
    "AU": "Aostralia",
    "AW": "Aruba",
    "AX": "Åland",
    "AZ": "Azerbaidjan",
    "BA": "Bosnia ha Herzegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "Belgia",
    "BF": "Burkina Faso",
    "BG": "Bulgaria",
    "BH": "Bahrein",
    "BI": "Burundi",
    "BJ": "Benin",
    "BL": "Saint-Barthélemy",
    "BM": "Bermuda",
    "BN": "Brunei",
    "BO": "Bolivia",
    "BQ": "Bonaire, Sint Eustatius ha Saba",
    "BR": "Brazil",
    "BS": "Bahamas",
    "BT": "Bhoutan",
    "BV": "Enez Bouvet",
    "BW": "Botswana",
    "BY": "Belarus",
    "BZ": "Belize",
    "CA": "Kanada",
    "CC": "Inizi Cocos (Keeling)",
    "CD": "Kongo-Kinshasa",
    "CF": "Republik Kreizafrikan",
    "CG": "Kongo-Brazzaville",
    "CH": "Suis",
    "CI": "Aod an Olifant",
    "CK": "Inizi Cook",
    "CL": "Chile",
    "CM": "Kameroun",
    "CN": "Sina",
    "CO": "Kolombia",
    "CR": "Costa Rica",
    "CU": "Kuba",
    "CV": "Kab Glas",
    "CW": "Curaçao",
    "CX": "Enez Christmas",
    "CY": "Kiprenez",
    "CZ": "Tchekia",
    "DE": "Alamagn",
    "DJ": "Djibouti",
    "DK": "Danmark",
    "DM": "Dominica",
    "DO": "Republik Dominikan",
    "DZ": "Aljeria",
    "EC": "Ecuador",
    "EE": "Estonia",
    "EG": "Egipt",
    "EH": "Sahara ar C'hornôg",
    "ER": "Eritrea",
    "ES": "Spagn",
    "ET": "Etiopia",
    "FI": "Finland",
    "FJ": "Fidji",
    "FK": "Inizi Maloù",
    "FM": "Mikronezia",
    "FO": "Inizi Faero",
    "FR": "Frañs",
    "GA": "Gabon",
    "GB": "Rouantelezh-Unanet",
    "GD": "Grenada",
    "GE": "Jorjia",
    "GF": "Gwiana C'hall",
    "GG": "Gwernenez",
    "GH": "Ghana",
    "GI": "Jibraltar",
    "GL": "Greunland",
    "GM": "Gambia",
    "GN": "Ginea",
    "GP": "Gwadeloup",
    "GQ": "Ginea ar C'heheder",
    "GR": "Gres",
    "GS": "Georgia ar Su hag Inizi Sandwich ar Su",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Ginea-Bissau",
    "GY": "Guyana",
    "HK": "Hong Kong",
    "HM": "Inizi Heard ha McDonald",
    "HN": "Honduras",
    "HR": "Kroatia",
    "HT": "Haiti",
    "HU": "Hungaria",
    "ID": "Indonezia",
    "IE": "Iwerzhon",
    "IL": "Israel",
    "IM": "Enez Vanav",
    "IN": "India",
    "IO": "Tiriad breizhveurat Meurvor Indez",
    "IQ": "Irak",
    "IR": "Iran",
    "IS": "Island",
    "IT": "Italia",
    "JE": "Jerzenez",
    "JM": "Jamaika",
    "JO": "Jordania",
    "JP": "Japan",
    "KE": "Kenya",
    "KG": "Kirgizstan",
    "KH": "Kambodja",
    "KI": "Kiribati",
    "KM": "Komorez",
    "KN": "Saint Kitts ha Nevis",
    "KP": "Korea an Norzh",
    "KR": "Korea ar Su",
    "KW": "Koweit",
    "KY": "Inizi Cayman",
    "KZ": "Kazakstan",
    "LA": "Laos",
    "LB": "Liban",
    "LC": "Santez-Lusia",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Liberia",
    "LS": "Lesotho",
    "LT": "Lituania",
    "LU": "Luksembourg",
    "LV": "Latvia",
    "LY": "Libia",
    "MA": "Maroko",
    "MC": "Monaco",
    "MD": "Moldova",
    "ME": "Montenegro",
    "MF": "Saint-Martin",
    "MG": "Madagaskar",
    "MH": "Inizi Marshall",
    "MK": "Makedonia an Norzh",
    "ML": "Mali",
    "MM": "Myanmar",
    "MN": "Mongolia",
    "MO": "Makao",
    "MP": "Inizi Mariana an Norzh",
    "MQ": "Martinik",
    "MR": "Maouritania",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Moris",
    "MV": "Maldivez",
    "MW": "Malawi",
    "MX": "Mec'hiko",
    "MY": "Malaysia",
    "MZ": "Mozambik",
    "NA": "Namibia",
    "NC": "Kaledonia-Nevez",
    "NE": "Niger",
    "NF": "Enez Norfolk",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Izelvroioù",
    "NO": "Norvegia",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Zeland-Nevez",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Perou",
    "PF": "Polinezia C'hall",
    "PG": "Papoua-Ginea Nevez",
    "PH": "Filipinez",
    "PK": "Pakistan",
    "PL": "Polonia",
    "PM": "Sant-Pêr-ha-Mikelon",
    "PN": "Inizi Pitcairn",
    "PR": "Puerto Rico",
    "PS": "Palestina",
    "PT": "Portugal",
    "PW": "Palau",
    "PY": "Paraguay",
    "QA": "Katar",
    "RE": "Reünion",
    "RO": "Roumania",
    "RS": "Serbia",
    "RU": "Rusia",
    "RW": "Rwanda",
    "SA": "Arabia Saoudat",
    "SB": "Inizi Salomon",
    "SC": "Sechelez",
    "SD": "Soudan",
    "SE": "Sveden",
    "SG": "Singapour",
    "SH": "Saint Helena, Ascension, ha Tristan da Cunha",
    "SI": "Slovenia",
    "SJ": "Svalbard ha Jan Mayen",
    "SK": "Slovakia",
    "SL": "Sierra Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SO": "Somalia",
    "SR": "Surinam",
    "SS": "Soudan ar Su",
    "ST": "São Tomé ha Príncipe",
    "SV": "El Salvador",
    "SX": "Sint Maarten",
    "SY": "Siria",
    "SZ": "Eswatini",
    "TC": "Inizi Turks ha Caicos",
    "TD": "Tchad",
    "TF": "Douaroù Aostral hag Antarktikel Frañs",
    "TG": "Togo",
    "TH": "Thailand",
    "TJ": "Tadjikistan",
    "TK": "Tokelau",
    "TL": "Timor ar Reter",
    "TM": "Turkmenistan",
    "TN": "Tunizia",
    "TO": "Tonga",
    "TR": "Turkia",
    "TT": "Trinidad ha Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "TZ": "Tanzania",
    "UA": "Ukraina",
    "UG": "Ouganda",
    "UM": "Inizi Minor A-bell Stadoù-Unanet",
    "US": "Stadoù-Unanet",
    "UY": "Uruguay",
    "UZ": "Ouzbekistan",
    "VA": "Vatikan",
    "VC": "Sant Visant hag ar Grenadinez",
    "VE": "Venezuela",
    "VG": "Inizi Gwerc'h Breizhveurat",
    "VI": "Inizi Gwerc'h ar Stadoù Unanet",
    "VN": "Viêt Nam",
    "VU": "Vanuatu",
    "WF": "Wallis ha Futuna",
    "WS": "Samoa",
    "XK": "Kosovo",
    "YE": "Yemen",
    "YT": "Mayotte",
    "ZA": "Suafrika",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  }
}
