{
  "locale": "lt",
  "countries": {
    "AD": "Andora",
    "AE": "Jungtiniai Arabų Emyratai",
    "AF": "Afganistanas",
    "AG": "Antigva ir Barbuda",
    "AI": "Angilija",
    "AL": "Albanija",
    "AM": "Armėnija",
    "AO": "Angola",
    "AQ": "Antarktida",
    "AR": "Argentina",
    "AS": "Amerikos Samoa",
    "AT": "Austrija",
    "AU": "Australija",
    "AW": "Aruba",
    "AX": "Alandų Salos",
    "AZ": "Azerbaidžanas",
    "BA": "Bosnija ir Hercegovina",
    "BB": "Barbadosas",
    "BD": "Bangladešas",
    "BE": "Belgija",
    "BF": "Burkina Fasas",
    "BG": "Bulgarija",
    "BH": "Bahreinas",
    "BI": "Burundis",
    "BJ": "Beninas",
    "BL": "Sen Bartelemi",
    "BM": "Bermuda",
    "BN": "Brunėjus",
    "BO": "Bolivija",
    "BQ": "Karibų Nyderlandai",
    "BR": "Brazilija",
    "BS": "Bahamos",
    "BT": "Butanas",
    "BV": "Buvė Sala",
    "BW": "Botsvana",
    "BY": "Baltarusija",
    "BZ": "Belizas",
    "CA": "Kanada",
    "CC": "Kokosų (Kilingo) Salos",
    "CD": "Kongas-Kinšasa",
    "CF": "Centrinės Afrikos Respublika",
    "CG": "Kongas-Brazavilis",
    "CH": "Šveicarija",
    "CI": "Dramblio Kaulo Krantas",
    "CK": "Kuko Salos",
    "CL": "Čilė",
    "CM": "Kamerūnas",
    "CN": "Kinija",
    "CO": "Kolumbija",
    "CR": "Kosta Rika",
    "CU": "Kuba",
    "CV": "Žaliasis Kyšulys",
    "CW": "Kiurasao",
    "CX": "Kalėdų Sala",
    "CY": "Kipras",
    "CZ": "Čekija",
    "DE": "Vokietija",
    "DJ": "Džibutis",
    "DK": "Danija",
    "DM": "Dominika",
    "DO": "Dominikos Respublika",
    "DZ": "Alžyras",
    "EC": "Ekvadoras",
    "EE": "Estija",
    "EG": "Egiptas",
    "EH": "Vakarų Sachara",
    "ER": "Eritrėja",
    "ES": "Ispanija",
    "ET": "Etiopija",
    "FI": "Suomija",
    "FJ": "Fidžis",
    "FK": "Folklando Salos",
    "FM": "Mikronezija",
    "FO": "Farerų Salos",
    "FR": "Prancūzija",
    "GA": "Gabonas",
    "GB": "Jungtinė Karalystė",
    "GD": "Grenada",
    "GE":  ["Sakartvelas", "Gruzija"],
    "GF": "Prancūzijos Gviana",
    "GG": "Gernsis",
    "GH": "Gana",
    "GI": "Gibraltaras",
    "GL": "Grenlandija",
    "GM": "Gambija",
    "GN": "Gvinėja",
    "GP": "Gvadelupa",
    "GQ": "Pusiaujo Gvinėja",
    "GR": "Graikija",
    "GS": "Pietų Džordžija ir Pietų Sandvičo salos",
    "GT": "Gvatemala",
    "GU": "Guamas",
    "GW": "Bisau Gvinėja",
    "GY": "Gajana",
    "HK": "Honkongas",
    "HM": "Herdo ir Makdonaldo Salos",
    "HN": "Hondūras",
    "HR": "Kroatija",
    "HT": "Haitis",
    "HU": "Vengrija",
    "ID": "Indonezija",
    "IE": "Airija",
    "IL": "Izraelis",
    "IM": "Meno Sala",
    "IN": "Indija",
    "IO": "Indijos Vandenyno Britų Sritis",
    "IQ": "Irakas",
    "IR": "Iranas",
    "IS": "Islandija",
    "IT": "Italija",
    "JE": "Džersis",
    "JM": "Jamaika",
    "JO": "Jordanija",
    "JP": "Japonija",
    "KE": "Kenija",
    "KG": "Kirgizija",
    "KH": "Kambodža",
    "KI": "Kiribatis",
    "KM": "Komorai",
    "KN": "Sent Kitsas ir Nevis",
    "KP": "Šiaurės Korėja",
    "KR": "Pietų Korėja",
    "KW": "Kuveitas",
    "KY": "Kaimanų Salos",
    "KZ": "Kazachstanas",
    "LA": "Laosas",
    "LB": "Libanas",
    "LC": "Sent Lusija",
    "LI": "Lichtenšteinas",
    "LK": "Šri Lanka",
    "LR": "Liberija",
    "LS": "Lesotas",
    "LT": "Lietuva",
    "LU": "Liuksemburgas",
    "LV": "Latvija",
    "LY": "Libija",
    "MA": "Marokas",
    "MC": "Monakas",
    "MD": "Moldova",
    "ME": "Juodkalnija",
    "MF": "Sen Martenas",
    "MG": "Madagaskaras",
    "MH": "Maršalo Salos",
    "MK": "Šiaurės Makedonija",
    "ML": "Malis",
    "MM": "Mianmaras (Birma)",
    "MN": "Mongolija",
    "MO": "Makao",
    "MP": "Marianos Šiaurinės Salos",
    "MQ": "Martinika",
    "MR": "Mauritanija",
    "MS": "Montseratas",
    "MT": "Malta",
    "MU": "Mauricijus",
    "MV": "Maldyvai",
    "MW": "Malavis",
    "MX": "Meksika",
    "MY": "Malaizija",
    "MZ": "Mozambikas",
    "NA": "Namibija",
    "NC": "Naujoji Kaledonija",
    "NE": "Nigeris",
    "NF": "Norfolko sala",
    "NG": "Nigerija",
    "NI": "Nikaragva",
    "NL": "Nyderlandai",
    "NO": "Norvegija",
    "NP": "Nepalas",
    "NR": "Nauru",
    "NU": "Niujė",
    "NZ": "Naujoji Zelandija",
    "OM": "Omanas",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Prancūzijos Polinezija",
    "PG": "Papua Naujoji Gvinėja",
    "PH": "Filipinai",
    "PK": "Pakistanas",
    "PL": "Lenkija",
    "PM": "Sen Pjeras ir Mikelonas",
    "PN": "Pitkerno salos",
    "PR": "Puerto Rikas",
    "PS": "Palestinos teritorija",
    "PT": "Portugalija",
    "PW": "Palau",
    "PY": "Paragvajus",
    "QA": "Kataras",
    "RE": "Reunjonas",
    "RO": "Rumunija",
    "RS": "Serbija",
    "RU": "Rusija",
    "RW": "Ruanda",
    "SA": "Saudo Arabija",
    "SB": "Saliamono Salos",
    "SC": "Seišeliai",
    "SD": "Sudanas",
    "SE": "Švedija",
    "SG": "Singapūras",
    "SH": "Šv. Elenos Sala",
    "SI": "Slovėnija",
    "SJ": "Svalbardas ir Janas Majenas",
    "SK": "Slovakija",
    "SL": "Siera Leonė",
    "SM": "San Marinas",
    "SN": "Senegalas",
    "SO": "Somalis",
    "SR": "Surinamas",
    "SS": "Pietų Sudanas",
    "ST": "San Tomė ir Prinsipė",
    "SV": "Salvadoras",
    "SX": "Sint Martenas",
    "SY": "Sirija",
    "SZ": "Svazilandas",
    "TC": "Terkso ir Kaikoso Salos",
    "TD": "Čadas",
    "TF": "Prancūzijos Pietų sritys",
    "TG": "Togas",
    "TH": "Tailandas",
    "TJ": "Tadžikija",
    "TK": "Tokelau",
    "TL": "Rytų Timoras",
    "TM": "Turkmėnistanas",
    "TN": "Tunisas",
    "TO": "Tonga",
    "TR": "Turkija",
    "TT": "Trinidadas ir Tobagas",
    "TV": "Tuvalu",
    "TW": "Taivanas",
    "TZ": "Tanzanija",
    "UA": "Ukraina",
    "UG": "Uganda",
    "UM": "Jungtinių Valstijų Mažosios Tolimosios Salos",
    "US": "Jungtinės Valstijos",
    "UY": "Urugvajus",
    "UZ": "Uzbekistanas",
    "VA": "Vatikano Miesto Valstybė",
    "VC": "Šventasis Vincentas ir Grenadinai",
    "VE": "Venesuela",
    "VG": "Didžiosios Britanijos Mergelių Salos",
    "VI": "Jungtinių Valstijų Mergelių Salos",
    "VN": "Vietnamas",
    "VU": "Vanuatu",
    "WF": "Volisas ir Futūna",
    "WS": "Samoa",
    "XK": "Kosovas",
    "YE": "Jemenas",
    "YT": "Majotas",
    "ZA": "Pietų Afrika",
    "ZM": "Zambija",
    "ZW": "Zimbabvė"
  }
}
