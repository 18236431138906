{
  "locale": "et",
  "countries": {
    "AF": "Afganistan",
    "AX": "Ahvenamaa",
    "AL": "Albaania",
    "DZ": "Alžeeria",
    "AS": "Ameerika Samoa",
    "US": "Ameerika Ühendriigid",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarktis",
    "AG": "Antigua ja Barbuda",
    "MO": "Aomen - Hiina erihalduspiirkond",
    "AE": "Araabia Ühendemiraadid",
    "AR": "Argentina",
    "AM": "Armeenia",
    "AW": "Aruba",
    "AZ": "Aserbaidžaan",
    "AU": "Austraalia",
    "AT": "Austria",
    "BS": "Bahama",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "PW": "Belau",
    "BE": "Belgia",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Boliivia",
    "BA": "Bosnia ja Hertsegoviina",
    "BW": "Botswana",
    "BV": "Bouvet’i saar",
    "BR": "Brasiilia",
    "BQ": "Bonaire, Sint Eustatius ja Saba",
    "IO": "Briti India ookeani ala",
    "VG": "Briti Neitsisaared",
    "BN": "Brunei",
    "BG": "Bulgaaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CO": "Colombia",
    "CK": "Cooki saared",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "CW": "Curaçao",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominikaani Vabariik",
    "EC": "Ecuador",
    "EE": "Eesti",
    "EG": "Egiptus",
    "GQ": "Ekvatoriaal-Guinea",
    "SV": "El Salvador",
    "ER": "Eritrea",
    "ET": "Etioopia",
    "FK": "Falklandi saared",
    "FJ": "Fidži",
    "PH": "Filipiinid",
    "FO": "Fääri saared",
    "GA": "Gabon",
    "GM": "Gambia",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GD": "Grenada",
    "GE": "Gruusia",
    "GL": "Gröönimaa",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard ja McDonald saared",
    "CN": "Hiina",
    "ES": "Hispaania",
    "NL": "Holland",
    "HN": "Honduras",
    "HK": "Hongkong - Hiina erihalduspiirkond",
    "HR": "Horvaatia",
    "TL": "Ida-Timor",
    "IE": "Iirimaa",
    "IL": "Iisrael",
    "IN": "India",
    "ID": "Indoneesia",
    "IQ": "Iraak",
    "IR": "Iraan",
    "IS": "Island",
    "IT": "Itaalia",
    "JP": "Jaapan",
    "JM": "Jamaica",
    "YE": "Jeemen",
    "JE": "Jersey",
    "JO": "Jordaania",
    "CX": "Jõulusaar",
    "KY": "Kaimanisaared",
    "KH": "Kambodža",
    "CM": "Kamerun",
    "CA": "Kanada",
    "KZ": "Kasahstan",
    "QA": "Katar",
    "KE": "Kenya",
    "CF": "Kesk-Aafrika Vabariik",
    "KI": "Kiribati",
    "KM": "Komoorid",
    "CD": "Kongo DV",
    "CG": "Kongo-Brazzaville",
    "CC": "Kookossaared",
    "GR": "Kreeka",
    "CU": "Kuuba",
    "KW": "Kuveit",
    "KG": "Kõrgõzstan",
    "CY": "Küpros",
    "LA": "Laos",
    "LT": "Leedu",
    "LS": "Lesotho",
    "LR": "Libeeria",
    "LI": "Liechtenstein",
    "LB": "Liibanon",
    "LY": "Liibüa",
    "LU": "Luksemburg",
    "ZA": "Lõuna-Aafrika Vabariik",
    "GS": "Lõuna-Georgia ja Lõuna-Sandwichi saared",
    "KR": "Lõuna-Korea",
    "LV": "Läti",
    "EH": "Lääne-Sahara",
    "MG": "Madagaskar",
    "MY": "Malaisia",
    "MW": "Malawi",
    "MV": "Maldiivid",
    "ML": "Mali",
    "MT": "Malta",
    "IM": "Mani saar",
    "MA": "Maroko",
    "MH": "Marshalli saared",
    "MQ": "Martinique",
    "MR": "Mauritaania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mehhiko",
    "FM": "Mikroneesia Liiduriigid",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongoolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MZ": "Mosambiik",
    "MM": "Myanmar",
    "NA": "Namiibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NI": "Nicaragua",
    "NG": "Nigeeria",
    "NE": "Niger",
    "NU": "Niue",
    "NF": "Norfolk",
    "NO": "Norra",
    "OM": "Omaan",
    "PG": "Paapua Uus-Guinea",
    "PK": "Pakistan",
    "PS": "Palestiina ala",
    "PA": "Panama",
    "PY": "Paraguay",
    "PE": "Peruu",
    "PN": "Pitcairn",
    "PL": "Poola",
    "PT": "Portugal",
    "GF": "Prantsuse Guajaana",
    "TF": "Prantsuse Lõunaalad",
    "PF": "Prantsuse Polüneesia",
    "FR": "Prantsusmaa",
    "PR": "Puerto Rico",
    "KP": "Põhja-Korea",
    "MK": "Põhja-Makedoonia",
    "MP": "Põhja-Mariaanid",
    "RE": "Réunion",
    "CV": "Roheneemesaared",
    "SE": "Rootsi",
    "SX": "Sint Maarten",
    "RO": "Rumeenia",
    "RW": "Rwanda",
    "SB": "Saalomoni Saared",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena",
    "KN": "Saint Kitts ja Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin",
    "PM": "Saint Pierre ja Miquelon",
    "VC": "Saint Vincent ja Grenadiinid",
    "DE": "Saksamaa",
    "ZM": "Sambia",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé ja Príncipe",
    "SA": "Saudi Araabia",
    "SC": "Seišellid",
    "SN": "Senegal",
    "RS": "Serbia",
    "SL": "Sierra Leone",
    "SG": "Singapur",
    "SK": "Slovakkia",
    "SI": "Sloveenia",
    "SO": "Somaalia",
    "FI": "Soome",
    "LK": "Sri Lanka",
    "SD": "Sudaan",
    "SS": "Lõuna-Sudaan",
    "SR": "Suriname",
    "GB": "Suurbritannia",
    "SZ": "Svaasimaa",
    "SJ": "Svalbard ja Jan Mayen",
    "SY": "Süüria",
    "CH": "Šveits",
    "ZW": "Zimbabwe",
    "DK": "Taani",
    "TJ": "Tadžikistan",
    "TH": "Tai",
    "TW": "Taiwan",
    "TZ": "Tansaania",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad ja Tobago",
    "TD": "Tšaad",
    "CZ": "Tšehhi",
    "CL": "Tšiili",
    "TN": "Tuneesia",
    "TC": "Turks ja Caicos",
    "TV": "Tuvalu",
    "TR": "Türgi",
    "TM": "Türkmenistan",
    "UG": "Uganda",
    "UA": "Ukraina",
    "HU": "Ungari",
    "UY": "Uruguay",
    "VI": "USA Neitsisaared",
    "UZ": "Usbekistan",
    "NC": "Uus-Kaledoonia",
    "NZ": "Uus-Meremaa",
    "BY": "Valgevene",
    "WF": "Wallis ja Futuna",
    "VU": "Vanuatu",
    "VA": "Vatikan",
    "RU": "Venemaa",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "UM": "Ühendriikide hajasaared",
    "XK": "Kosovo"
  }
}
