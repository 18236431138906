{
  "locale": "dv",
  "countries": {
    "AF": "އަފްޣާނިސްތާން",
    "AL": "އަލްބޭނިއާ",
    "DZ": "އަލްޖީރިއާ",
    "AS": "އެމެރިކަން ސަމޯއާ",
    "AD": "އެންޑޯރާ",
    "AO": "އެންގޯލާ",
    "AI": "އެންގުއިލާ",
    "AQ": "އެންޓަރްޓިކަ",
    "AG": "އެންޓިގުއާ އެންޑް ބަރބުޑާ",
    "AR": "އާޖެންޓީނާ",
    "AM": "އަރްމީނިއާ",
    "AW": "އަރޫބާ (ހޮލެންޑު)",
    "AU": "އޮސްޓްރޭލިއާ",
    "AT": "އޮސްޓްރިއާ",
    "AZ": "އަޒަރުބައިޖާން",
    "BS": "ބަހާމަސް",
    "BH": "ބަޙްރައިން",
    "BD": "ބަންގާޅު",
    "BB": "ބާބަޑޮސް",
    "BY": "ބެލަރޫސް",
    "BE": "ބެލްޖިއަމް",
    "BZ": "ބެލީޒު",
    "BJ": "ބެނިން",
    "BM": "ބާމިއުޑާ (ޔުނައިޓެޑް ކިންގްޑަމް)",
    "BT": "ބޫޓާން",
    "BO": "ބޮލީވިއާ",
    "BA": "ބޮސްނިއާ އެންޑް ހާޒެގޮވީނާ",
    "BW": "ބޮސްވާނާ",
    "BV": "ބުވި ޖަޒީރާ",
    "BR": "ބްރެޒިލް",
    "IO": "ބްރިޓިޝް ހިންދު ކަނޑު ބިން",
    "BN": "ބުރުނައީ",
    "BG": "ބަލްގޭރިއާ",
    "BF": "ބުރުކީނާ ފާސޯ",
    "BI": "ބުރުންޑީ",
    "KH": "ކެމްބޯޑިއާ",
    "CM": "ކެމަރޫން",
    "CA": "ކެނެޑާ",
    "CV": "ކޭޕް ވާޑޭ",
    "KY": "ކޭމަން އައިލެންޑްސް",
    "CF": "ސެންޓްރަލް އެފްރިކަން ރިޕަބްލިކް",
    "TD": "ޗާޑް",
    "CL": "ޗިލީ",
    "CN": "ޗައިނާ",
    "CX": "ކްރިސްޓްމަސް ޖަޒީރާ",
    "CC": "ކުކް ޖަޒީރާ",
    "CO": "ކޮލަންބިއާ",
    "KM": "ކޮމޮރޯސް",
    "CG": "ކޮންގޯ (ޖުމްހޫރިއްޔާ)",
    "CD": "ކޮންގޯ (ދިމިޤްރާޠީ ޖުމްހޫރިއްޔާ)",
    "CK": "ކޫކް އައިލަންޑްސް",
    "CR": "ކޮސްޓަ ރީކާ",
    "CI": "ކޯޓް ޑްލްވޮއަރ",
    "HR": "ކްރޮއޭޝިއާ",
    "CU": "ކިޔުބާ",
    "CY": "ސައިޕްރަސް",
    "CZ": "ޗެޗް ރިޕަބްލިކް",
    "DK": "ޑެންމާކު",
    "DJ": "ޑްޖިބޯޓި",
    "DM": "ޑޮމިނިކާ",
    "DO": "ޑޮމިނިކަން ރިޕަބްލިކް",
    "EC": "އީކުއެޑޯ",
    "EG": "މިޞްރު",
    "SV": "އެލް ސަލްވަޑޯރ",
    "GQ": "އީކުއޭޓޯރިއަލް ގުއީނިއާ",
    "ER": "އެރިޓްރިއާ",
    "EE": "އެސްޓޯނިއާ",
    "ET": "އެތިއޯޕިއާ",
    "FK": "ފޯކްލޭންޑް އައިލޭންޑްސް",
    "FO": "ފަރޯ އައިލެންޑްސް",
    "FJ": "ފިޖީ",
    "FI": "ފިންލޭންޑް",
    "FR": "ފަރަނސޭސިވިލާތް، ފަރަންސާ",
    "GF": "ފަރަންސޭސީ ގިޔާނާ",
    "PF": "ފްރެންޗް ޕޮލިނީސިއާ",
    "TF": "ފްރެންތް ސަދާން ޓެރިޓޮރީސް",
    "GA": "ގެބޮން",
    "GM": "ގެމްބިއާ",
    "GE": "ޖޯޖިއާ",
    "DE": "ޖަރުމަނުވިލާތް",
    "GH": "ގާނާ",
    "GI": "ގިބްރަލްޓަރ",
    "GR": "ގްރީސް",
    "GL": "ގުރީންލޭންޑު (ޑެންމާކު)",
    "GD": "ގްރެނަޑާ",
    "GP": "ގުވަދެލޫޕު",
    "GU": "ގުއާމު",
    "GT": "ގުއަޓެމާލާ",
    "GN": "ގުއީނިއާ",
    "GW": "ގުއީނިއާ ބިއްސައު",
    "GY": "ގުޔާނާ",
    "HT": "ހެއިޓީ",
    "HM": "ާހޑް އައިލެންޑްސް މެކްޑޮނާލްޑް އައިފެންޑްސް",
    "VA": "ހޮލީ ސީ",
    "HN": "ހޮންޑިއުރާސް",
    "HK": "ހޮންކޮންގު",
    "HU": "ހަންގޭރީ",
    "IS": "އައިސްލޭންޑް",
    "IN": "ހިންދުސްތާން",
    "ID": "އިންޑޮނީޝިޔާ",
    "IR": "އީރާން",
    "IQ": "ޢިރާޤު",
    "IE": "އަޔަލޭންޑުގެ ޖުމްހޫރިއްޔާ",
    "IL": "އިސްރާއީލު",
    "IT": "އިޓަލީ",
    "JM": "ޖެމޭއިކާ",
    "JP": " ޖަޕާނު ",
    "JO": "ޖޯޑަން",
    "KZ": "ކަޒަކިސްތާން",
    "KE": "ކެންޔާ",
    "KI": "ކިރިބަޓި",
    "KP": "ޑިމޮކްރޭޓތިކް ޕީޕަލްސް ރިޕަބްލިކް އޮފް ކޮރެއާ",
    "KR": "ރިޕަބްލިކް އޮފް ކޮރެއާ",
    "KW": "ކުވެއިތު",
    "KG": "ކިރިގިސްތާން",
    "LA": "ޕީޕަލްސް ޑިމޮކްރޭޓިކް ރިޕަބްލިކް އޮފް ލާއޯ",
    "LV": "ލަޓްވިއާ",
    "LB": "ލުބުނާން",
    "LS": "ލެސޯތޯ",
    "LR": "ލައިބީރިއާ",
    "LY": "ލީބިޔަން އަރަބް ޖަމާހިރިއްޔާ",
    "LI": "ލިއެޗެންސްޓެއިން",
    "LT": "ލިތުއޭނިއާ",
    "LU": "ލަގްޒެމްބާގް",
    "MO": "މަކާއޯ",
    "MK": "ޔޫގޮސްލާވިއާ",
    "MG": "މަޑަގަސްކަރ",
    "MW": "މެލޭޝިޔާ",
    "MY": "މެލޭޝިޔާ",
    "MV": "މާލީ",
    "ML": "މޯލްޓާ",
    "MT": "މަލްޓާ",
    "MH": "މާޝަލް އައިލެންޑްސް",
    "MQ": "މަރުތިނީކު",
    "MR": "މައުރިޓޭނިއާ",
    "MU": "މައުރިޓިއަސް",
    "YT": "މެކްސިކޯ",
    "MX": "މައިކްރޮނޭޝިއާ",
    "FM": "މޮލްޑޯވާ",
    "MD": "މޮނާކޯ",
    "MC": "މޮންގޯލިއާ",
    "MN": "މޮންގޯލިއާ",
    "MS": "މޮރޮކޯ",
    "MA": "މޮރޮކޯ",
    "MZ": "މޮޒަންބީކް",
    "MM": "މިޔަންމާ",
    "NA": "ނައުރޫ",
    "NR": "ނޭޕާލް",
    "NP": "ނެދަލޭންޑު",
    "NL": "ނެދަލޭންޑްސް",
    "NC": "ނިއު ކެލިޑޯނިއާ",
    "NZ": "ނިކަރާގުއާ",
    "NI": "ނިޖަރު",
    "NE": "ނައިޖީރިއާ",
    "NG": "ނީއު",
    "NU": "ނިއޫ",
    "NF": "ނޯފޯކް އައިލެންޑް",
    "MP": "ނޮދާން މަރިއާނާ އައިލަންޑްސް",
    "NO": "ނޫރުވޭޖިއާ",
    "OM": "އޯމާން",
    "PK": "ޕާކިސްތާން",
    "PW": "ޕަލާއޫ",
    "PS": "ފަލަސްޠީނުގެ ދައުލަތް",
    "PA": "ޕެނަމާ",
    "PG": "ޕައުޕާ ނިއުގީނިއާ",
    "PY": "ޕަރަގުއޭއީ",
    "PE": "ޕެރޫ",
    "PH": "ފިލިޕީންސް",
    "PN": "ޕިޓްކެއާން",
    "PL": "ޕޮލޭންޑް",
    "PT": "ޕޯޗުގަލް",
    "PR": "ޕުއަރޓޯ ރީކޯ",
    "QA": "ޤަޠަރު",
    "RE": "ރިޔޫނިއަން (ފަރަންސޭސިވިލާތް)",
    "RO": "ރޮމޭނިއާ",
    "RU": "ރަޝިއަން ފެޑޭރޭޝަން",
    "RW": "ރުވާންޑާ",
    "SH": "ސަންތި ހެލީނާ (ޔުނައިޓެޑް ކިންގްޑަމް)",
    "KN": "ސައިންޓް ކިޓްސް އެންޑް ނެވީސް",
    "LC": "ސައިންޓް ލޫސިއާ",
    "PM": "ސަންތި ޕިއޭރޭ އާއި މިކުއެލޯން (ފަރަންސޭސިވިލާތް)",
    "VC": "ސައިންޓް ވިންސެންޓް އެންޑް ދަ ގޮރެނޭޑިންސް",
    "WS": "ސަމޯއާ",
    "SM": "ސަން މެރީނޯ",
    "ST": "ސައޯ ޓޯމީ އެންޑް ޕްރިންސިޕީ",
    "SA": "ސައުދީ އެރޭބިއާ",
    "SN": "ސެނެގާލް",
    "SC": "ސީޝެލްސް",
    "SL": "ސެރެލިއޯން",
    "SG": "ސިންގަޕޯރ",
    "SK": "ސްލޮވާކިއާ",
    "SI": "ސްލޮވީނިއާ",
    "SB": "ސޮލޮމޮން އައިލަންޑްސް",
    "SO": "ސޯމާލިއާ",
    "ZA": "ސައުތު އެފްރިކާ",
    "GS": "ސައުތު ޖޯޖިއާ އަންޑް ދަ ސައުތު ސޭންޑްވިޗް އައިލެންޑްސް",
    "ES": "ސްޕެއިން",
    "LK": "އޮޅުދޫކަރަ",
    "SD": "ސޫދާން",
    "SR": "ސުރިނާމް",
    "SJ": "ސްވަ ލްބަރްޑް އެންޑް ޖަން މަޔިން",
    "SZ": "ސުވާޒިލޭންޑު",
    "SE": "ސްވީޑަން",
    "CH": "ސްވިޒަރލޭންޑް",
    "SY": "ސީރިއަން އަރަބް ރިޕަބްލިކް",
    "TW": "ޓައިވާން",
    "TJ": "ޓަޖިކިސްތާން",
    "TZ": "ޓާންޒޭނިއާ",
    "TH": "ތައިލެންޑް",
    "TL": "ޓީމޯ ލެސްޓޭ",
    "TG": "ޓޯގޯ",
    "TK": "ތޮކެލާއު",
    "TO": "ޓޮންގާ",
    "TT": "ޓްރިނިޑެޑް އެންޑް ޓޮބޭގޯ",
    "TN": "ޓިއުނީޝިއާ",
    "TR": "ތުރުކީވިލާތް",
    "TM": "ތުރުކުމެނިސްތާން",
    "TC": "ޓާކްސް އެންޑް ކެއިކޯސް އައިލެންޑްސް",
    "TV": "ތުވާލޫ",
    "UG": "ޔުގެންޑާ",
    "UA": "ޔޫކްރޭން",
    "AE": "އެކުވެރި ޢަރަބި އިމާރާތ",
    "GB": "ޔުނައިޓަޑް ކިންގްޑޮމް",
    "US": "ޔުނައިޓަޑް ސްޓޭޓްސް",
    "UM": "ޔުނައިޓަޑް ސްޓޭޓްސް ކުޑާ ދެރެން އައިލޭންޑްސް",
    "UY": "އުރުގުއޭއީ",
    "UZ": "އުޒްބެކިސްތާން",
    "VU": "ވަނުއާޓޫ",
    "VE": "ވެނެޒުއޭލާ",
    "VN": "ވިއެޓުނާމު",
    "VG": "ވާރޖިން އައިލޭންޑްސް ބްރިޓިޝް",
    "VI": "ވާރޖިން އައިލޭންޑްސް ޔޫއެސް",
    "WF": "ވޯލިސް އެންޑް ފުޓުނަ",
    "EH": "ހުޅަނގު ސަހަރާ",
    "YE": "ޔަމަން",
    "ZM": "ޒެމްބިއާ",
    "ZW": "ޒިމްބާބުވޭ",
    "AX": "އަލޭންޑް އައިލެންޑްސް",
    "BQ": "ބުނިރް، ސިންޓް ޔުސްޓަޒިއުސ އެންޑް ސަބަ",
    "CW": "ކުރަކާއޯ",
    "GG": "ގުއާންސޭ (ބިރިޓިޝް ތާޖުގެ ހިޔާވަހިކަމުގައި)",
    "IM": "އައިޒަލް އޮފް މޭން (ބިރިޓިޝް ތާޖުގެ ހިޔާވަހިކަމުގައި)",
    "JE": "ޖާސޭ (ބިރިޓިޝް ތާޖުގެ ހިޔާވަހިކަމުގައި)",
    "ME": "ކަޅުފަރުބަދަ",
    "BL": "ސެއިންޓް ބާތެލެމީ",
    "MF": "ސެއިންޓް މާޓީން",
    "RS": "ސިރިބިއާ، ސިރިބިސްތާން، ސިރިބިވިލާތް",
    "SX": "ސިންޓް މާޓީން",
    "SS": "ސައުތު ސޫދާން",
    "XK": "ކޮސޮވާ"
  }
}
