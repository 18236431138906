import { inject, Injectable } from '@angular/core';

import * as countries from 'i18n-iso-countries';

import { LanguageService } from './language.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const require: any;

@Injectable({ providedIn: 'root' })
export class CountriesService {
  private _languageService = inject(LanguageService);

  registerLocale(language: string): void {
    countries.registerLocale(
      require(
        `../../../../../node_modules/i18n-iso-countries/langs/${language.toLocaleLowerCase()}.json`,
      ),
    );
  }

  getName(
    countryCode: string | number | countries.Alpha2Code | countries.Alpha3Code,
    language = this._languageService.getCurrentLanguage().toLocaleLowerCase(),
  ): string | undefined {
    return countries.getName(countryCode, language);
  }
}
