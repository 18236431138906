import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[rpIntegerOnly]',
  standalone: true,
})
export class IntegerOnlyDirective {
  @HostListener('beforeinput', ['$event'])
  onInputChange(event: InputEvent): void {
    const value = event.data;
    const integerRegExp = /^\d+$/;

    if (value !== null && !integerRegExp.test(value)) {
      event.preventDefault();
    }
  }
}
