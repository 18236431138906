{
  "locale": "ta",
  "countries": {
    "AF": "ஆப்கானிஸ்தான்",
    "AL": "அல்பேனியா",
    "DZ": "அல்ஜீரியா",
    "AS": "அமெரிக்க சமோவா",
    "AD": "அன்டோரா",
    "AO": "அங்கோலா",
    "AI": "அங்குய்லா",
    "AQ": "அண்டார்டிகா",
    "AG": "ஆண்டிகுவா மற்றும் பார்புடா",
    "AR": "அர்ஜென்டினா",
    "AM": "ஆர்மேனியா",
    "AW": "அரூபா",
    "AU": "ஆஸ்திரேலியா",
    "AT": "ஆஸ்திரியா",
    "AZ": "அசர்பைஜான்",
    "BS": "பஹாமாஸ்",
    "BH": "பஹ்ரைன்",
    "BD": "பங்களாதேஷ்",
    "BB": "பார்படோஸ்",
    "BY": "பெலாரூஸ்",
    "BE": "பெல்ஜியம்",
    "BZ": "பெலிஸ்",
    "BJ": "பெனின்",
    "BM": "பெர்முடா",
    "BT": "பூடான்",
    "BO": "பொலிவியா",
    "BA": "போஸ்னியா மற்றும் ஹெர்ஸிகோவினா",
    "BW": "போட்ஸ்வானா",
    "BV": "பொவேட் தீவுகள்",
    "BR": "பிரேசில்",
    "IO": "பிரிட்டிஷ் இந்தியப் பெருங்கடல் பிரதேசம்",
    "BN": "புரூனேய்",
    "BG": "பல்கேரியா",
    "BF": "புர்கினா ஃபாஸோ",
    "BI": "புருண்டி",
    "KH": "கம்போடியா",
    "CM": "கேமரூன்",
    "CA": "கனடா",
    "CV": "கேப் வெர்டே",
    "KY": "கேமென் தீவுகள்",
    "CF": "மத்திய ஆப்ரிக்கக் குடியரசு",
    "TD": "சாட்",
    "CL": "சிலி",
    "CN": "சீனா",
    "CX": "கிறிஸ்துமஸ் தீவு",
    "CC": "கோகோஸ் (கீலிங்) தீவுகள்",
    "CO": "கொலம்பியா",
    "KM": "கோமரோஸ்",
    "CG": "காங்கோ - ப்ராஸாவில்லே",
    "CD": "காங்கோ - கின்ஷாசா",
    "CK": "குக் தீவுகள்",
    "CR": "கோஸ்டாரிகா",
    "CI": "கோட் தி’வாயர்",
    "HR": "குரோசியா",
    "CU": "கியூபா",
    "CY": "சைப்ரஸ்",
    "CZ": "செக் குடியரசு",
    "DK": "டென்மார்க்",
    "DJ": "ஜிபௌட்டி",
    "DM": "டொமினிகா",
    "DO": "டொமினிகன் குடியரசு",
    "EC": "ஈக்வடார்",
    "EG": "எகிப்து",
    "SV": "எல் சால்வடார்",
    "GQ": "ஈக்குவாடோரியல் கினியா",
    "ER": "எரிட்ரியா",
    "EE": "எஸ்டோனியா",
    "ET": "எதியோப்பியா",
    "FK": "ஃபாக்லாந்து தீவுகள்",
    "FO": "ஃபாரோ தீவுகள்",
    "FJ": "ஃபிஜி",
    "FI": "பின்லாந்து",
    "FR": "பிரான்ஸ்",
    "GF": "பிரெஞ்சு கயானா",
    "PF": "பிரெஞ்சு பாலினேஷியா",
    "TF": "பிரெஞ்சு தெற்கு பிரதேசங்கள்",
    "GA": "கேபான்",
    "GM": "காம்பியா",
    "GE": "ஜார்ஜியா",
    "DE": "ஜெர்மனி",
    "GH": "கானா",
    "GI": "ஜிப்ரால்டர்",
    "GR": "கிரீஸ்",
    "GL": "கிரீன்லாந்து",
    "GD": "கிரனெடா",
    "GP": "க்வாதேலோப்",
    "GU": "குவாம்",
    "GT": "கவுதமாலா",
    "GN": "கினியா",
    "GW": "கினி-பிஸ்ஸாவ்",
    "GY": "கயானா",
    "HT": "ஹெய்தி",
    "HM": "ஹேர்ட் மற்றும் மெக்டொனால்டு தீவுகள்",
    "VA": "வாடிகன் நகரம்",
    "HN": "ஹோண்டூராஸ்",
    "HK": "ஹாங்காங் எஸ்ஏஆர் சீனா",
    "HU": "ஹங்கேரி",
    "IS": "ஐஸ்லாந்து",
    "IN": "இந்தியா",
    "ID": "இந்தோனேஷியா",
    "IR": "ஈரான்",
    "IQ": "ஈராக்",
    "IE": "அயர்லாந்து",
    "IL": "இஸ்ரேல்",
    "IT": "இத்தாலி",
    "JM": "ஜமைகா",
    "JP": "ஜப்பான்",
    "JO": "ஜோர்டான்",
    "KZ": "கஸகஸ்தான்",
    "KE": "கென்யா",
    "KI": "கிரிபடி",
    "KP": "வட கொரியா",
    "KR": "தென் கொரியா",
    "KW": "குவைத்",
    "KG": "கிர்கிஸ்தான்",
    "LA": "லாவோஸ்",
    "LV": "லாட்வியா",
    "LB": "லெபனான்",
    "LS": "லெசோதோ",
    "LR": "லைபீரியா",
    "LY": "லிபியா",
    "LI": "லிச்செண்ஸ்டெய்ன்",
    "LT": "லிதுவேனியா",
    "LU": "லக்ஸ்சம்பர்க்",
    "MO": "மகாவோ எஸ்ஏஆர் சீனா",
    "MG": "மடகாஸ்கர்",
    "MW": "மாலவி",
    "MY": "மலேஷியா",
    "MV": "மாலத்தீவு",
    "ML": "மாலி",
    "MT": "மால்டா",
    "MH": "மார்ஷல் தீவுகள்",
    "MQ": "மார்டினிக்",
    "MR": "மௌரிடானியா",
    "MU": "மொரிசியஸ்",
    "YT": "மயோத்",
    "MX": "மெக்சிகோ",
    "FM": "மைக்ரோனேஷியா",
    "MD": "மால்டோவா",
    "MC": "மொனாக்கோ",
    "MN": "மங்கோலியா",
    "MS": "மௌன்ட்செராட்",
    "MA": "மொராக்கோ",
    "MZ": "மொசாம்பிக்",
    "MM": "மியான்மார் (பர்மா)",
    "NA": "நமீபியா",
    "NR": "நௌரு",
    "NP": "நேபாளம்",
    "NL": "நெதர்லாந்து",
    "NC": "நியூ கேலிடோனியா",
    "NZ": "நியூசிலாந்து",
    "NI": "நிகரகுவா",
    "NE": "நைஜர்",
    "NG": "நைஜீரியா",
    "NU": "நியூ",
    "NF": "நார்ஃபாக் தீவுகள்",
    "MK": "மாசிடோனியா",
    "MP": "வடக்கு மரியானா தீவுகள்",
    "NO": "நார்வே",
    "OM": "ஓமன்",
    "PK": "பாகிஸ்தான்",
    "PW": "பாலோ",
    "PS": "பாலஸ்தீனிய பிரதேசங்கள்",
    "PA": "பனாமா",
    "PG": "பாப்புவா நியூ கினி",
    "PY": "பராகுவே",
    "PE": "பெரு",
    "PH": "பிலிப்பைன்ஸ்",
    "PN": "பிட்கெய்ர்ன் தீவுகள்",
    "PL": "போலந்து",
    "PT": "போர்ச்சுக்கல்",
    "PR": "பியூர்டோ ரிகோ",
    "QA": "கத்தார்",
    "RE": "ரீயூனியன்",
    "RO": "ருமேனியா",
    "RU": "ரஷ்யா",
    "RW": "ருவான்டா",
    "SH": "செயின்ட் ஹெலெனா",
    "KN": "செயின்ட் கிட்ஸ் மற்றும் நெவிஸ்",
    "LC": "செயின்ட் லூசியா",
    "PM": "செயின்ட் பியர் மற்றும் மிக்வேலான்",
    "VC": "செயின்ட் வின்சென்ட் மற்றும் கிரெனடைன்ஸ்",
    "WS": "சமோவா",
    "SM": "சான் மெரினோ",
    "ST": "சாவ் தோம் மற்றும் ப்ரின்சிபி",
    "SA": "சவூதி அரேபியா",
    "SN": "செனெகல்",
    "SC": "ஸேசேல்ஸ்",
    "SL": "சியர்ரா லியோன்",
    "SG": "சிங்கப்பூர்",
    "SK": "ஸ்லோவாகியா",
    "SI": "ஸ்லோவேனியா",
    "SB": "சாலமன் தீவுகள்",
    "SO": "சோமாலியா",
    "ZA": "தென் ஆப்பிரிக்கா",
    "GS": "தென் ஜியார்ஜியா மற்றும் தென் சான்ட்விச் தீவுகள்",
    "ES": "ஸ்பெயின்",
    "LK": "இலங்கை",
    "SD": "சூடான்",
    "SR": "சுரினாம்",
    "SJ": "ஸ்வல்பார்டு மற்றும் ஜான் மேயன்",
    "SZ": "ஸ்வாஸிலாந்து",
    "SE": "ஸ்வீடன்",
    "CH": "ஸ்விட்சர்லாந்து",
    "SY": "சிரியா",
    "TW": "தைவான்",
    "TJ": "தாஜிகிஸ்தான்",
    "TZ": "தான்சானியா",
    "TH": "தாய்லாந்து",
    "TL": "தைமூர்-லெஸ்தே",
    "TG": "டோகோ",
    "TK": "டோகேலோ",
    "TO": "டோங்கா",
    "TT": "ட்ரினிடாட் மற்றும் டுபாகோ",
    "TN": "டுனிசியா",
    "TR": "துருக்கி",
    "TM": "துர்க்மெனிஸ்தான்",
    "TC": "டர்க்ஸ் மற்றும் கைகோஸ் தீவுகள்",
    "TV": "துவாலூ",
    "UG": "உகாண்டா",
    "UA": "உக்ரைன்",
    "AE": "ஐக்கிய அரபு எமிரேட்ஸ்",
    "GB": "ஐக்கிய பேரரசு",
    "US": "அமெரிக்கா",
    "UM": "யூஎஸ் அவுட்லேயிங் தீவுகள்",
    "UY": "உருகுவே",
    "UZ": "உஸ்பெகிஸ்தான்",
    "VU": "வனுவாட்டு",
    "VE": "வெனிசுலா",
    "VN": "வியட்நாம்",
    "VG": "பிரிட்டீஷ் கன்னித் தீவுகள்",
    "VI": "யூ.எஸ். கன்னித் தீவுகள்",
    "WF": "வாலிஸ் மற்றும் ஃபுடுனா",
    "EH": "மேற்கு சஹாரா",
    "YE": "ஏமன்",
    "ZM": "ஜாம்பியா",
    "ZW": "ஜிம்பாப்வே",
    "AX": "ஆலந்து தீவுகள்",
    "BQ": "கரீபியன் நெதர்லாந்து",
    "CW": "குராகவ்",
    "GG": "கெர்ன்சி",
    "IM": "ஐல் ஆஃப் மேன்",
    "JE": "ஜெர்சி",
    "ME": "மான்டேனெக்ரோ",
    "BL": "செயின்ட் பார்தேலெமி",
    "MF": "செயின்ட் மார்ட்டீன்",
    "RS": "செர்பியா",
    "SX": "சின்ட் மார்டென்",
    "SS": "தெற்கு சூடான்",
    "XK": "கொசோவோ"
  }
}
