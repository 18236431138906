{
  "locale": "ps",
  "countries": {
    "AF": "افغانستان",
    "AL": "البانیه",
    "DZ": "الجزایر",
    "AS": "امریکایی ساماوا",
    "AD": "اندورا",
    "AO": "انګولا",
    "AI": "انګیلا",
    "AQ": "انتارکتیکا",
    "AG": "انټيګوا او باربودا",
    "AR": "ارجنټاين",
    "AM": "ارمنستان",
    "AW": "آروبا",
    "AU": "آسټرالیا",
    "AT": "اتریش",
    "AZ": "اذربايجان",
    "BS": "باهماس",
    "BH": "بحرين",
    "BD": "بنگله دېش",
    "BB": "باربادوس",
    "BY": "بیلاروس",
    "BE": "بیلجیم",
    "BZ": "بلیز",
    "BJ": "بینن",
    "BM": "برمودا",
    "BT": "بهوټان",
    "BO": "بولیویا",
    "BA": "بوسنيا او هېرزګوينا",
    "BW": "بوتسوانه",
    "BV": "بوویټ ټاپو",
    "BR": "برازیل",
    "IO": "د برتانوي هند سمندري سيمه",
    "BN": "برونائي",
    "BG": "بلغاریه",
    "BF": "بورکینا فاسو",
    "BI": "بروندي",
    "KH": "کمبودیا",
    "CM": "کامرون",
    "CA": "کاناډا",
    "CV": "کیپ ورد",
    "KY": "کیمان ټاپوګان",
    "CF": "وسطي افريقا جمهور",
    "TD": "چاډ",
    "CL": "چیلي",
    "CN": "چین",
    "CX": "د کريسمس ټاپو",
    "CC": "کوکوز (کيلنګ) ټاپوګان",
    "CO": "کولمبیا",
    "KM": "کوموروس",
    "CG": "کانګو - بروزوییل",
    "CD": "کانګو - کینشاسا",
    "CK": "کوک ټاپوګان",
    "CR": "کوستاریکا",
    "CI": "د عاج ساحل",
    "HR": "کرواشيا",
    "CU": "کیوبا",
    "CY": "قبرس",
    "CZ": "چکیا",
    "DK": "ډنمارک",
    "DJ": "جبوتي",
    "DM": "دومینیکا",
    "DO": "جمهوريه ډومينيکن",
    "EC": "اکوادور",
    "EG": "مصر",
    "SV": "سالوېډور",
    "GQ": "استوایی ګیني",
    "ER": "اریتره",
    "EE": "استونیا",
    "ET": "حبشه",
    "FK": "فاکلينډ ټاپوګان",
    "FO": "فارو ټاپو",
    "FJ": "فجي",
    "FI": "فنلینډ",
    "FR": "فرانسه",
    "GF": "فرانسوي ګانا",
    "PF": "فرانسوي پولينيسيا",
    "TF": "د فرانسې جنوبي سیمې",
    "GA": "ګابن",
    "GM": "ګامبیا",
    "GE": "گورجستان",
    "DE": "المان",
    "GH": "ګانا",
    "GI": "جبل الطارق",
    "GR": "یونان",
    "GL": "ګرینلینډ",
    "GD": "ګرنادا",
    "GP": "ګوادلوپ",
    "GU": "ګوام",
    "GT": "ګواتیمالا",
    "GN": "ګینه",
    "GW": "ګینه بیسو",
    "GY": "ګیانا",
    "HT": "هایټي",
    "HM": "هارډ او ميکډانلډ ټاپوګان",
    "VA": "واتیکان ښار",
    "HN": "هانډوراس",
    "HK": "هانګ کانګ SAR چین",
    "HU": "مجارستان",
    "IS": "آیسلینډ",
    "IN": "هند",
    "ID": "اندونیزیا",
    "IR": "ايران",
    "IQ": "عراق",
    "IE": "آيرلېنډ",
    "IL": "اسراييل",
    "IT": "ایټالیه",
    "JM": "جمیکا",
    "JP": "جاپان",
    "JO": "اردن",
    "KZ": "قزاقستان",
    "KE": "کینیا",
    "KI": "کیري باتي",
    "KP": "شمالی کوریا",
    "KR": "سویلي کوریا",
    "KW": "کويت",
    "KG": "قرغزستان",
    "LA": "لاوس",
    "LV": "ليتهويا",
    "LB": "لبنان",
    "LS": "لسوتو",
    "LR": "لايبيريا",
    "LY": "لیبیا",
    "LI": "لیختن اشتاین",
    "LT": "لیتوانیا",
    "LU": "لوګزامبورګ",
    "MO": "مکاو SAR چین",
    "MG": "مدغاسکر",
    "MW": "مالاوي",
    "MY": "مالیزیا",
    "MV": "مالديپ",
    "ML": "مالي",
    "MT": "مالټا",
    "MH": "مارشل ټاپوګان",
    "MQ": "مارټینیک",
    "MR": "موریتانیا",
    "MU": "موریشیس",
    "YT": "مايوټ",
    "MX": "میکسیکو",
    "FM": "میکرونیزیا",
    "MD": "مولدوا",
    "MC": "موناکو",
    "MN": "منګوليا",
    "MS": "مانټیسیرت",
    "MA": "مراکش",
    "MZ": "موزمبيق",
    "MM": "ميانمار (برما)",
    "NA": "نیمبیا",
    "NR": "نایرو",
    "NP": "نیپال",
    "NL": "هالېنډ",
    "NC": "نوی کالیډونیا",
    "NZ": "نیوزیلنډ",
    "NI": "نکاراګوا",
    "NE": "نايجير",
    "NG": "نایجیریا",
    "NU": "نیوو",
    "NF": "نارفولک ټاپوګان",
    "MK": "شمالي مقدونيه",
    "MP": "شمالي ماريانا ټاپوګان",
    "NO": "ناروۍ",
    "OM": "عمان",
    "PK": "پاکستان",
    "PW": "پلاؤ",
    "PS": "فلسطیني سيمې",
    "PA": "پاناما",
    "PG": "پاپوا نيو ګيني",
    "PY": "پاراګوی",
    "PE": "پیرو",
    "PH": "فلپين",
    "PN": "پيټکيرن ټاپوګان",
    "PL": "پولنډ",
    "PT": "پورتګال",
    "PR": "پورتو ریکو",
    "QA": "قطر",
    "RE": "ریونین",
    "RO": "رومانیا",
    "RU": "روسیه",
    "RW": "روندا",
    "SH": "سینټ هیلینا",
    "KN": "سینټ کټس او نیویس",
    "LC": "سینټ لوسیا",
    "PM": "سینټ پییر او میکولون",
    "VC": "سینټ ویسنټینټ او ګرینډینز",
    "WS": "ساماوا",
    "SM": "سان مارینو",
    "ST": "ساو ټیم او پرنسیپ",
    "SA": "سعودي عربستان",
    "SN": "سينيګال",
    "SC": "سیچیلیس",
    "SL": "سییرا لیون",
    "SG": "سينگاپور",
    "SK": "سلواکیا",
    "SI": "سلوانیا",
    "SB": "سليمان ټاپوګان",
    "SO": "سومالیا",
    "ZA": "سویلي افریقا",
    "GS": "سويلي جارجيا او سويلي سېنډوچ ټاپوګان",
    "ES": "هسپانیه",
    "LK": "سريلنکا",
    "SD": "سوډان",
    "SR": "سورینام",
    "SJ": "سوالبارد او جان ميين",
    "SZ": "اسواټيني",
    "SE": "سویډن",
    "CH": "سویس",
    "SY": "سوریه",
    "TW": "تائيوان",
    "TJ": "تاجکستان",
    "TZ": "تنزانیا",
    "TH": "تهايلنډ",
    "TL": "تيمور-ليسټ",
    "TG": "ټوګو",
    "TK": "توکیلو",
    "TO": "تونګا",
    "TT": "ټرينيډاډ او ټوباګو",
    "TN": "تونس",
    "TR": "ترکي",
    "TM": "تورکمنستان",
    "TC": "د ترکیې او کیکاسو ټاپو",
    "TV": "توالیو",
    "UG": "یوګانډا",
    "UA": "اوکراین",
    "AE": "متحده عرب امارات",
    "GB": "برتانیه",
    "US": "متحده آيالات",
    "UM": "د متحده ایالاتو ټاپوګان",
    "UY": "یوروګوی",
    "UZ": "اوزبکستان",
    "VU": "واناتو",
    "VE": "وینزویلا",
    "VN": "وېتنام",
    "VG": "بریتانوی ویګور ټاپوګان",
    "VI": "د متحده آيالاتو ورجن ټاپوګان",
    "WF": "والیس او فوتونا",
    "EH": "لويديځ صحارا",
    "YE": "یمن",
    "ZM": "زیمبیا",
    "ZW": "زیمبابوی",
    "AX": "الاند ټاپوان",
    "BQ": "کیریبین هالینډ",
    "CW": "کوراکاو",
    "GG": "ګرنسي",
    "IM": "د آئل آف مین",
    "JE": "جرسی",
    "ME": "مونټینیګرو",
    "BL": "سينټ بارتيلمي",
    "MF": "سینټ مارټن",
    "RS": "سربيا",
    "SX": "سینټ مارټین",
    "SS": "سويلي سوډان",
    "XK": "کوسوو"
  }
}
