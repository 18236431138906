export enum StaticContentPage {
  privacyPolicy,
  webmasterTermsAndConditions,
  advertiserTermsAndConditions,
}

export const staticContentPageRouting: Record<StaticContentPage, string> = {
  [StaticContentPage.privacyPolicy]: 'privacy-policy',
  [StaticContentPage.webmasterTermsAndConditions]: 'webmaster/terms-and-conditions',
  [StaticContentPage.advertiserTermsAndConditions]: 'advertiser/terms-and-conditions',
};

export const staticContentPath: Record<StaticContentPage, string> = {
  [StaticContentPage.privacyPolicy]:
    '/assets/static-pages/privacy-policy/{lang}-privacy-policy.html',
  [StaticContentPage.webmasterTermsAndConditions]:
    '/assets/static-pages/terms-and-conditions/webmaster-{lang}-terms-and-conditions.html',
  [StaticContentPage.advertiserTermsAndConditions]:
    '/assets/static-pages/terms-and-conditions/advertiser-{lang}-terms-and-conditions.html',
};
