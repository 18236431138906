{
  "locale": "tg",
  "countries": {
    "AF": "Афғонистон",
    "AL": "Албания",
    "DZ": "Алҷазоир",
    "AS": "Самоаи Америка",
    "AD": "Андорра",
    "AO": "Ангола",
    "AI": "Ангилия",
    "AQ": "Антарктида",
    "AG": "Антигуа ва Барбуда",
    "AR": "Аргентина",
    "AM": "Арманистон",
    "AW": "Аруба",
    "AU": "Австралия",
    "AT": "Австрия",
    "AZ": "Озарбойҷон",
    "BS": "Багам",
    "BH": "Баҳрайн",
    "BD": "Бангладеш",
    "BB": "Барбадос",
    "BY": "Белорус",
    "BE": "Белгия",
    "BZ": "Белиз",
    "BJ": "Бенин",
    "BM": "Бермуда",
    "BT": "Бутон",
    "BO": "Боливия",
    "BA": "Босния ва Ҳерсеговина",
    "BW": "Ботсвана",
    "BV": "Ҷазираи Буве",
    "BR": "Бразилия",
    "IO": "Қаламрави Британия дар уқёнуси Ҳинд",
    "BN": "Бруней",
    "BG": "Булғория",
    "BF": "Буркина-Фасо",
    "BI": "Бурунди",
    "KH": "Камбоҷа",
    "CM": "Камерун",
    "CA": "Канада",
    "CV": "Кабо-Верде",
    "KY": "Ҷазираҳои Кайман",
    "CF": "Ҷумҳурии Африқои Марказӣ",
    "TD": "Чад",
    "CL": "Чили",
    "CN": "Хитой",
    "CX": "Ҷазираи Крисмас",
    "CC": "Ҷазираҳои Кокос (Килинг)",
    "CO": "Колумбия",
    "KM": "Комор",
    "CG": "Конго",
    "CD": "Конго, Ҷумҳурии Демократии",
    "CK": "Ҷазираҳои Кук",
    "CR": "Коста-Рика",
    "CI": "Кот-д’Ивуар",
    "HR": "Хорватия",
    "CU": "Куба",
    "CY": "Кипр",
    "CZ": "Ҷумҳурии Чех",
    "DK": "Дания",
    "DJ": "Ҷибути",
    "DM": "Доминика",
    "DO": "Ҷумҳурии Доминикан",
    "EC": "Эквадор",
    "EG": "Миср",
    "SV": "Эл-Салвадор",
    "GQ": "Гвинеяи Экваторӣ",
    "ER": "Эритрея",
    "EE": "Эстония",
    "ET": "Эфиопия",
    "FK": "Ҷазираҳои Фолкленд",
    "FO": "Ҷазираҳои Фарер",
    "FJ": "Фиҷи",
    "FI": "Финляндия",
    "FR": "Фаронса",
    "GF": "Гвианаи Фаронса",
    "PF": "Полинезияи Фаронса",
    "TF": "Минтақаҳои Ҷанубии Фаронса",
    "GA": "Габон",
    "GM": "Гамбия",
    "GE": "Гурҷистон",
    "DE": "Германия",
    "GH": "Гана",
    "GI": "Гибралтар",
    "GR": "Юнон",
    "GL": "Гренландия",
    "GD": "Гренада",
    "GP": "Гваделупа",
    "GU": "Гуам",
    "GT": "Гватемала",
    "GN": "Гвинея",
    "GW": "Гвинея-Бисау",
    "GY": "Гайана",
    "HT": "Гаити",
    "HM": "Ҷазираи Ҳерд ва Ҷазираҳои Макдоналд",
    "VA": "Шаҳри Вотикон",
    "HN": "Гондурас",
    "HK": "Ҳонконг (МММ)",
    "HU": "Маҷористон",
    "IS": "Исландия",
    "IN": "Ҳиндустон",
    "ID": "Индонезия",
    "IR": "Эрон",
    "IQ": "Ироқ",
    "IE": "Ирландия",
    "IL": "Исроил",
    "IT": "Италия",
    "JM": "Ямайка",
    "JP": "Япония",
    "JO": "Урдун",
    "KZ": "Қазоқистон",
    "KE": "Кения",
    "KI": "Кирибати",
    "KP": "Кореяи Шимолӣ",
    "KR": "Кореяи ҷанубӣ",
    "KW": "Қувайт",
    "KG": "Қирғизистон",
    "LA": "Лаос",
    "LV": "Латвия",
    "LB": "Лубнон",
    "LS": "Лесото",
    "LR": "Либерия",
    "LY": "Либия",
    "LI": "Лихтенштейн",
    "LT": "Литва",
    "LU": "Люксембург",
    "MO": "Макао (МММ)",
    "MG": "Мадагаскар",
    "MW": "Малави",
    "MY": "Малайзия",
    "MV": "Малдив",
    "ML": "Мали",
    "MT": "Малта",
    "MH": "Ҷазираҳои Маршалл",
    "MQ": "Мартиника",
    "MR": "Мавритания",
    "MU": "Маврикий",
    "YT": "Майотта",
    "MX": "Мексика",
    "FM": "Штатҳои Федеративии Микронезия",
    "MD": "Молдова",
    "MC": "Монако",
    "MN": "Муғулистон",
    "MS": "Монтсеррат",
    "MA": "Марокаш",
    "MZ": "Мозамбик",
    "MM": "Мянма",
    "NA": "Намибия",
    "NR": "Науру",
    "NP": "Непал",
    "NL": "Нидерландия",
    "NC": "Каледонияи Нав",
    "NZ": "Зеландияи Нав",
    "NI": "Никарагуа",
    "NE": "Нигер",
    "NG": "Нигерия",
    "NU": "Ниуэ",
    "NF": "Ҷазираи Норфолк",
    "MK": "Македонияи Шимолӣ",
    "MP": "Ҷазираҳои Марианаи Шимолӣ",
    "NO": "Норвегия",
    "OM": "Умон",
    "PK": "Покистон",
    "PW": "Палау",
    "PS": "Фаластин",
    "PA": "Панама",
    "PG": "Папуа Гвинеяи Нав",
    "PY": "Парагвай",
    "PE": "Перу",
    "PH": "Филиппин",
    "PN": "Ҷазираҳои Питкейрн",
    "PL": "Лаҳистон",
    "PT": "Португалия",
    "PR": "Пуэрто-Рико",
    "QA": "Қатар",
    "RE": "Реюнион",
    "RO": "Руминия",
    "RU": "Русия",
    "RW": "Руанда",
    "SH": "Сент Елена",
    "KN": "Сент-Китс ва Невис",
    "LC": "Сент-Люсия",
    "PM": "Сент-Пер ва Микелон",
    "VC": "Сент-Винсент ва Гренадина",
    "WS": "Самоа",
    "SM": "Сан-Марино",
    "ST": "Сан Томе ва Принсипи",
    "SA": "Арабистони Саудӣ",
    "SN": "Сенегал",
    "SC": "Сейшел",
    "SL": "Сиерра-Леоне",
    "SG": "Сингапур",
    "SK": "Словакия",
    "SI": "Словения",
    "SB": "Ҷазираҳои Соломон",
    "SO": "Сомалӣ",
    "ZA": "Африкаи Ҷанубӣ",
    "GS": "Ҷорҷияи Ҷанубӣ ва Ҷазираҳои Сандвич",
    "ES": "Испания",
    "LK": "Шри-Ланка",
    "SD": "Судон",
    "SR": "Суринам",
    "SJ": "Шпитсберген ва Ян Майен",
    "SZ": "Свазиленд",
    "SE": "Шветсия",
    "CH": "Швейтсария",
    "SY": "Сурия",
    "TW": "Тайван",
    "TJ": "Тоҷикистон",
    "TZ": "Танзания",
    "TH": "Таиланд",
    "TL": "Тимор-Лесте",
    "TG": "Того",
    "TK": "Токелау",
    "TO": "Тонга",
    "TT": "Тринидад ва Тобаго",
    "TN": "Тунис",
    "TR": "Туркия",
    "TM": "Туркманистон",
    "TC": "Ҷазираҳои Теркс ва Кайкос",
    "TV": "Тувалу",
    "UG": "Уганда",
    "UA": "Украина",
    "AE": "Аморатҳои Муттаҳидаи Араб",
    "GB": "Шоҳигарии Муттаҳида",
    "US": "Иёлоти Муттаҳида",
    "UM": "Ҷазираҳои Хурди Дурдасти ИМА",
    "UY": "Уругвай",
    "UZ": "Ӯзбекистон",
    "VU": "Вануату",
    "VE": "Венесуэла",
    "VN": "Ветнам",
    "VG": "Ҷазираҳои Виргини Британия",
    "VI": "Ҷазираҳои Виргини ИМА",
    "WF": "Уоллис ва Футуна",
    "EH": "Саҳрои Ғарбӣ",
    "YE": "Яман",
    "ZM": "Замбия",
    "ZW": "Зимбабве",
    "AX": "Ҷазираҳои Аланд",
    "BQ": "Бонайре, Синт Эстатиус ва Саба",
    "CW": "Кюрасао",
    "GG": "Гернси",
    "IM": "Ҷазираи Мэн",
    "JE": "Ҷерси",
    "ME": "Черногория",
    "BL": "Сент-Бартелми",
    "MF": "Ҷазираи Сент-Мартин",
    "RS": "Сербия",
    "SX": "Синт-Маартен",
    "SS": "Судони Ҷанубӣ",
    "XK": "Косово"
  }
}
