import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments';
import { Observable, of } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';

@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  private readonly _apiHost = environment.apiHost;

  private _http = inject(HttpClient);
  private _recaptchaV3Service = inject(ReCaptchaV3Service);

  getToken(): Observable<string> {
    return environment.useRecaptcha ? this._recaptchaV3Service.execute('importantAction') : of('');
  }

  verifyRecaptcha(token: string): Observable<void> {
    return environment.useRecaptcha
      ? this._http.post<void>(`${this._apiHost}/recaptcha/verify-recaptcha/`, { token })
      : of(null);
  }
}
