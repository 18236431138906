import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { VALIDATION_MESSAGES } from '@shared/models';

@Component({
  selector: 'ui-validation-message',
  standalone: true,
  templateUrl: './validation-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class ValidationMessageComponent {
  errors = input.required<ValidationErrors | null | undefined>();

  errorMessage = computed(() => this._getErrorMessage);

  private _translateService = inject(TranslateService);

  private get _getErrorMessage(): string | null {
    const errorsList = this.errors();
    if (errorsList) {
      const errors = Object.keys(errorsList) as Array<keyof typeof VALIDATION_MESSAGES>;
      const errorMessageKey = errors.find(error => VALIDATION_MESSAGES[error]);
      const interpolateParams = this._getInterpolateParams(errorsList);

      if (errorMessageKey) {
        return interpolateParams
          ? this._translateService.instant(VALIDATION_MESSAGES[errorMessageKey], interpolateParams)
          : this._translateService.instant(VALIDATION_MESSAGES[errorMessageKey]);
      }

      return interpolateParams
        ? this._translateService.instant(`validationMessages.${errors[0]}`, interpolateParams)
        : this._translateService.instant(`validationMessages.${errors[0]}`);
    }

    return null;
  }

  private _getInterpolateParams(
    errorsList: ValidationErrors,
  ): Record<string, string | number> | null {
    if (errorsList['maxlength']?.requiredLength) {
      return { value: errorsList['maxlength']?.requiredLength };
    }

    if (errorsList['minMax']) {
      return { min: errorsList['minMax']?.min, max: errorsList['minMax']?.max };
    }

    return null;
  }
}
