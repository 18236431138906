{
  "locale": "mr",
  "countries": {
    "AD": "आंदोरा",
    "AE": "संयुक्त अरब अमिराती",
    "AF": "अफगाणिस्तान",
    "AG": "अँटिगा आणि बार्बुडा",
    "AI": "अँग्विला",
    "AL": "अल्बानिया",
    "AM": "अर्मेनिया",
    "AO": "अँगोला",
    "AQ": "अंटार्क्टिका",
    "AR": "अर्जेंटीना",
    "AS": "अमेरिकन सामोआ",
    "AT": "ऑस्ट्रिया",
    "AU": "ऑस्ट्रेलिया",
    "AW": "अरूबा",
    "AX": "ऑलंड द्वीपसमूह",
    "AZ": "अझरबैजान",
    "BA": "बॉस्निया आणि हर्झगोव्हिना",
    "BB": "बार्बाडोस",
    "BD": "बांग्लादेश",
    "BE": "बेल्जियम",
    "BF": "बुर्किना फासो",
    "BG": "बल्गेरिया",
    "BH": "बहारीन",
    "BI": "बुरुंडी",
    "BJ": "बेनिन",
    "BL": "सेंट बार्थेलेमी",
    "BM": "बर्म्युडा",
    "BN": "ब्रुनेई दारुसलाम",
    "BO": "बोलिव्हिया",
    "BQ": "कैरिबियन नेदरलँड्स",
    "BR": "ब्राझील",
    "BS": "बहामास",
    "BT": "भूतान",
    "BV": "बुवेट बेट",
    "BW": "बोत्स्वाना",
    "BY": "बेलारूस",
    "BZ": "बेलीज",
    "CA": "कॅनडा",
    "CC": "कोकोस द्वीपसमूह",
    "CD": "काँगो, लोकशाही प्रजासत्ताक",
    "CF": "मध्य आफ्रिकेचे प्रजासत्ताक",
    "CG": "काँगो",
    "CH": "स्वित्झर्लंड",
    "CI": "आयव्हरी कोस्ट",
    "CK": "कुक द्वीप",
    "CL": "चिली",
    "CM": "कैमरून",
    "CN": "चीन",
    "CO": "कोलंबिया",
    "CR": "कॉस्टा रिका",
    "CU": "क्यूबा",
    "CV": "केप वर्दे",
    "CW": "कुराकाओ",
    "CX": "क्रिसमस द्वीप",
    "CY": "सायप्रस",
    "CZ": "झेक प्रजासत्ताक",
    "DE": "जर्मनी",
    "DJ": "जिबूती",
    "DK": "डेन्मार्क",
    "DM": "डोमिनिका",
    "DO": "डॉमिनिकन प्रजासत्ताक",
    "DZ": "अल्जेरिया",
    "EC": "इक्वेडोर",
    "EE": "एस्टोनिया",
    "EG": "इजिप्त",
    "EH": "पश्चिम सहारा",
    "ER": "इरिट्रिया",
    "ES": "स्पेन",
    "ET": "इथिओपिया",
    "FI": "फिनलंड",
    "FJ": "फिजी",
    "FK": "फॉकलंड द्वीपसमूह (माल्विनास)",
    "FM": "मायक्रोनेशिया (संघीय राज्ये)",
    "FO": "फॅरो द्वीपसमूह",
    "FR": "फ्रान्स",
    "GA": "गॅबॉन",
    "GB": "ग्रेट ब्रिटन व उत्तर आयर्लंडचे संयुक्त राजतंत्र",
    "GD": "ग्रेनेडा",
    "GE": "जॉर्जिया",
    "GF": "फ्रेंच गयाना",
    "GG": "ग्वेर्नसे",
    "GH": "घाना",
    "GI": "जिब्राल्टर",
    "GL": "ग्रीनलँड",
    "GM": "गांबिया",
    "GN": "गिनी",
    "GP": "ग्वाडेलूप",
    "GQ": "इक्वेटोरियल गिनी",
    "GR": "ग्रीस",
    "GS": "दक्षिण जॉर्जिया आणि दक्षिण सँडविच द्वीपसमूह",
    "GT": "ग्वाटेमाला",
    "GU": "ग्वाम",
    "GW": "गिनी-बिसाऊ",
    "GY": "गयाना",
    "HK": "हाँग काँग",
    "HM": "हर्ड द्वीप व मॅकडॉनल्ड द्वीपसमूह",
    "HN": "होंडुरास",
    "HR": "क्रोएशिया",
    "HT": "हैती",
    "HU": "हंगेरी",
    "ID": "इंडोनेशिया",
    "IE": "आयर्लंड",
    "IL": "इस्रायल",
    "IM": "आइल ऑफ मॅन",
    "IN": "भारत",
    "IO": "ब्रिटीश हिंदी महासागर क्षेत्र",
    "IQ": "इराक",
    "IR": "इराण",
    "IS": "आइसलँड",
    "IT": "इटली",
    "JE": "जर्सी",
    "JM": "जमैका",
    "JO": "जॉर्डन",
    "JP": "जापान",
    "KE": "केनिया",
    "KG": "किर्गिझस्तान",
    "KH": "कंबोडिया",
    "KI": "किरिबाती",
    "KM": "कोमोरोस",
    "KN": "सेंट किट्स आणि नेव्हिस",
    "KP": "उत्तर कोरिया",
    "KR": "दक्षिण कोरिया",
    "KW": "कुवेत",
    "KY": "केमन द्वीपसमूह",
    "KZ": "कझाकस्तान",
    "LA": "लाओस",
    "LB": "लेबनॉन",
    "LC": "सेंट लुसिया",
    "LI": "लिश्टनस्टाइन",
    "LK": "श्रीलंका",
    "LR": "लायबेरिया",
    "LS": "लेसोथो",
    "LT": "लिथुआनिया",
    "LU": "लक्झेंबर्ग",
    "LV": "लात्व्हिया",
    "LY": "लिबिया",
    "MA": "मोरोक्को",
    "MC": "मोनॅको",
    "MD": "मोल्दोव्हा प्रजासत्ताक",
    "ME": "माँटेनिग्रो",
    "MF": "सेंट मार्टिन",
    "MG": "मादागास्कर",
    "MH": "मार्शल द्वीपसमूह",
    "MK": "उत्तर मॅसेडोनिया",
    "ML": "माली",
    "MM": "म्यानमार",
    "MN": "मंगोलिया",
    "MO": "मकाओ",
    "MP": "उत्तर मारियाना द्वीपसमूह",
    "MQ": "मार्टिनिक",
    "MR": "मॉरिटानिया",
    "MS": "माँटसेराट",
    "MT": "माल्टा",
    "MU": "मॉरिशस",
    "MV": "मालदीव",
    "MW": "मलावी",
    "MX": "मेक्सिको",
    "MY": "मलेशिया",
    "MZ": "मोझांबिक",
    "NA": "नामिबिया",
    "NC": "न्यू कॅलिडोनिया",
    "NE": "नायजर",
    "NF": "नॉरफोक द्वीप",
    "NG": "नायजेरिया",
    "NI": "निकाराग्वा",
    "NL": "नेदरलँड्स",
    "NO": "नॉर्वे",
    "NP": "नेपाळ",
    "NR": "नौरू",
    "NU": "नियू",
    "NZ": "न्यूझीलंड",
    "OM": "ओमान",
    "PA": "पनामा",
    "PE": "पेरू",
    "PF": "फ्रेंच पॉलिनेशिया",
    "PG": "पापुआ न्यू गिनी",
    "PH": "फिलिपाईन्स",
    "PK": "पाकिस्तान",
    "PL": "पोलंड",
    "PM": "सेंट पियरे आणि मिकेलॉन",
    "PN": "पिटकेर्न द्वीपसमूह",
    "PR": "पोर्तु रिको",
    "PS": "पॅलेस्टाईन",
    "PT": "पोर्तुगाल",
    "PW": "पलाऊ",
    "PY": "पॅराग्वे",
    "QA": "कतार",
    "RE": "रीयूनियन",
    "RO": "रोमानिया",
    "RS": "सर्बिया",
    "RU": "रशिया",
    "RW": "रवांडा",
    "SA": "सौदी अरेबिया",
    "SB": "सोलोमन द्वीपसमूह",
    "SC": "सेशेल्स",
    "SD": "सुदान",
    "SE": "स्वीडन",
    "SG": "सिंगापूर",
    "SH": "सेंट हेलेना, असेन्शन आणि ट्रिस्टन दा कुन्हा",
    "SI": "स्लोव्हेनिया",
    "SJ": "स्वालबार्ड व यान मायेन",
    "SK": "स्लोव्हाकिया",
    "SL": "सिएरा लिओन",
    "SM": "सॅन मारिनो",
    "SN": "सेनेगल",
    "SO": "सोमालिया",
    "SR": "सुरिनाम",
    "SS": "दक्षिण सुदान",
    "ST": "साओ टोम आणि प्रिंसिपे",
    "SV": "एल साल्वाडोर",
    "SX": "सिंट मार्टेन",
    "SY": "सीरिया",
    "SZ": "इस्वातीनी",
    "TC": "तुर्क आणि कैकोस द्वीपसमूह",
    "TD": "चाड",
    "TF": "फ्रान्सचे दक्षिणी व अंटार्क्टिक भूभाग",
    "TG": "टोगो",
    "TH": "थायलंड",
    "TJ": "ताजिकिस्तान",
    "TK": "टोकेलाऊ",
    "TL": "पूर्व तिमोर",
    "TM": "तुर्कमेनिस्तान",
    "TN": "ट्युनिशिया",
    "TO": "टोंगा",
    "TR": "तुर्कस्तान",
    "TT": "त्रिनिदाद आणि टोबॅगो",
    "TV": "तुवालू",
    "TW": "तैवान",
    "TZ": "टांझानिया",
    "UA": "युक्रेन",
    "UG": "युगांडा",
    "UM": "संयुक्त राज्य अमेरिकेचे छोटे दूरस्थ द्वीपसमूह",
    "US": "अमेरिका",
    "UY": "उरुग्वे",
    "UZ": "उझबेकिस्तान",
    "VA": "व्हॅटिकन सिटी",
    "VC": "सेंट व्हिन्सेंट आणि ग्रेनेडीन्स",
    "VE": "व्हेनेझुएला",
    "VG": "ब्रिटीश व्हर्जिन द्वीपसमूह",
    "VI": "अमेरिकी व्हर्जिन द्वीपसमूह",
    "VN": "व्हिएतनाम",
    "VU": "व्हानुआतू",
    "WF": "वॉलिस आणि फ्युटुना",
    "WS": "सामोआ",
    "XK": "कोसोवो",
    "YE": "यमनचे प्रजासत्ताक",
    "YT": "मेयोट",
    "ZA": "दक्षिण आफ्रिका",
    "ZM": "झांबिया",
    "ZW": "झिम्बाब्वे"
  }
}
