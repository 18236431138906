import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly _platformId = inject(PLATFORM_ID);

  setValue<T>(key: string, value: T): void {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }

  getValue<T>(key: string): T | null {
    if (!isPlatformBrowser(this._platformId)) {
      return null;
    }

    const value = localStorage.getItem(key);

    return value ? JSON.parse(value) : null;
  }
}
