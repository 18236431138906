import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('slideIn', [
  state(
    '*',
    style({
      transform: 'translateX(0)',
    }),
  ),
  state(
    'void',
    style({
      transform: 'translateX(110%)',
    }),
  ),
  transition('void => *', animate('.3s ease-in-out')),
]);
