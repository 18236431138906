<div @slideOut @slideIn class="toast" [ngClass]="toast().type">
  <div class="toast__wrapper">
    <div class="toast__header">
      <div class="toast__title">
        @if (toast().icon) {
          <ui-icon [iconName]="toast().icon || ''" />
        }

        <p>{{ toast().title }}</p>
      </div>

      <div class="toast__close">
        <ui-icon (click)="close()" iconName="close" size="xs2" />
      </div>
    </div>

    <div class="toast__text">{{ toast().text }}</div>
  </div>
</div>
