{
  "locale": "ko",
  "countries": {
    "AF": "아프가니스탄",
    "AL": "알바니아",
    "DZ": "알제리",
    "AS": "아메리칸 사모아",
    "AD": "안도라",
    "AO": "앙골라",
    "AI": "앙길라",
    "AQ": "안타티카",
    "AG": "안티구아 바부다",
    "AR": "아르헨티나",
    "AM": "아르메니아",
    "AW": "아루바",
    "AU": "호주",
    "AT": "오스트리아",
    "AZ": "아제르바이잔",
    "BS": "바하마",
    "BH": "바레인",
    "BD": "방글라데시",
    "BB": "바베이도스",
    "BY": "벨라루스",
    "BE": "벨기에",
    "BZ": "벨리즈",
    "BJ": "베냉",
    "BM": "버뮤다",
    "BT": "부탄",
    "BO": "볼리비아",
    "BA": "보스니아 헤르체고비나",
    "BW": "보츠와나",
    "BV": "부베섬",
    "BR": "브라질",
    "IO": "영인도 제도",
    "BN": "브루나이",
    "BG": "불가리아",
    "BF": "부르키나파소",
    "BI": "부룬디",
    "KH": "캄보디아",
    "CM": "카메룬",
    "CA": "캐나다",
    "CV": "카보 베르데",
    "KY": "케이맨섬",
    "CF": "중앙 아프리카",
    "TD": "차드",
    "CL": "칠레",
    "CN": "중국",
    "CX": "크리스마스섬",
    "CC": "코코스 제도",
    "CO": "콜롬비아",
    "KM": "코모로",
    "CG": "콩고",
    "CD": "콩고 민주 공화국",
    "CK": "쿡 제도",
    "CR": "코스타리카",
    "CI": "코트디부아르",
    "HR": "크로아티아",
    "CU": "쿠바",
    "CY": "사이프러스",
    "CZ": "체코공화국",
    "DK": "덴마크",
    "DJ": "지부티",
    "DM": "도미니카 연방",
    "DO": "도미니카 공화국",
    "EC": "에콰도르",
    "EG": "이집트",
    "SV": "엘살바도르",
    "GQ": "적도 기니",
    "ER": "에리트레아",
    "EE": "에스토니아",
    "ET": "이디오피아",
    "FK": "포클랜드섬",
    "FO": "페로 군도",
    "FJ": "피지",
    "FI": "핀란드",
    "FR": "프랑스",
    "GF": "프랑스령 기아나",
    "PF": "프랑스령 폴리네시아",
    "TF": "프랑스 남부영토",
    "GA": "가봉",
    "GM": "감비아",
    "GE": "그루지아",
    "DE": "독일",
    "GH": "가나",
    "GI": "지브랄타",
    "GR": "그리스",
    "GL": "그린랜드",
    "GD": "그레나다",
    "GP": "과들루프",
    "GU": "괌",
    "GT": "과테말라",
    "GN": "기니",
    "GW": "기네비쏘",
    "GY": "가이아나",
    "HT": "아이티",
    "HM": "허드 맥도날드 군도",
    "VA": "바티칸",
    "HN": "온두라스",
    "HK": "홍콩",
    "HU": "헝가리",
    "IS": "아이슬란드",
    "IN": "인도",
    "ID": "인도네시아",
    "IR": "이란",
    "IQ": "이라크",
    "IE": "아일랜드",
    "IL": "이스라엘",
    "IT": "이탈리아",
    "JM": "자메이카",
    "JP": "일본",
    "JO": "요르단",
    "KZ": "카자흐스탄",
    "KE": "케냐",
    "KI": "키르바시",
    "KP": "북한",
    "KR": "대한민국",
    "KW": "쿠웨이트",
    "KG": "키르키즈스탄",
    "LA": "라오스",
    "LV": "라트비아",
    "LB": "레바논",
    "LS": "레소토",
    "LR": "라이베리아",
    "LY": "리비아",
    "LI": "리히텐슈타인",
    "LT": "리투아니아",
    "LU": "룩셈부르크",
    "MO": "마카오",
    "MG": "마다가스카르",
    "MW": "말라위",
    "MY": "말레이시아",
    "MV": "몰디브",
    "ML": "말리",
    "MT": "몰타",
    "MH": "마샬군도",
    "MQ": "마르티니크",
    "MR": "모리타니",
    "MU": "모리셔스",
    "YT": "마요트",
    "MX": "멕시코",
    "FM": "미크로네시아",
    "MD": "몰도바",
    "MC": "모나코",
    "MN": "몽골",
    "MS": "몬트세라트",
    "MA": "모로코",
    "MZ": "모잠비크",
    "MM": "미얀마",
    "NA": "나미비아",
    "NR": "나우루",
    "NP": "네팔",
    "NL": "네덜란드",
    "NC": "뉴칼레도니아",
    "NZ": "뉴질랜드",
    "NI": "니카라과",
    "NE": "니제르",
    "NG": "나이지리아",
    "NU": "니우에",
    "NF": "노퍽섬",
    "MK": "마케도니아",
    "MP": "북마리아나 군도",
    "NO": "노르웨이",
    "OM": "오만",
    "PK": "파키스탄",
    "PW": "팔라우",
    "PS": "팔레스타인",
    "PA": "파나마",
    "PG": "파푸아 뉴기니",
    "PY": "파라과이",
    "PE": "페루",
    "PH": "필리핀",
    "PN": "핏케언 군도",
    "PL": "폴랜드",
    "PT": "포르투칼",
    "PR": "푸에르토리코",
    "QA": "카타르",
    "RE": "리유니온",
    "RO": "루마니아",
    "RU": "러시아연방",
    "RW": "르완다",
    "SH": "세인트 헬레나",
    "KN": "세인트 키츠 네비스",
    "LC": "세인트 루시아",
    "PM": "세인트리에르도,미괘론도",
    "VC": "세인트 빈센트 그레나딘",
    "WS": "사모아",
    "SM": "산 마리노",
    "ST": "상토메프린시페",
    "SA": "사우디아라비아",
    "SN": "세네갈",
    "SC": "세이셸",
    "SL": "시에라 리온",
    "SG": "싱가포르",
    "SK": "슬로바키아",
    "SI": "슬로베니아",
    "SB": "솔로몬 아일랜드",
    "SO": "소말리아",
    "ZA": "남아프리카",
    "GS": "남조지아 군도",
    "ES": "스페인",
    "LK": "스리랑카",
    "SD": "수단",
    "SR": "수리남",
    "SJ": "스발바드, 잠마엔도",
    "SZ": "스와질란드",
    "SE": "스웨덴",
    "CH": "스위스",
    "SY": "시리아",
    "TW": "중화민국",
    "TJ": "타지키스탄",
    "TZ": "탄자니아",
    "TH": "태국",
    "TL": "동티모르",
    "TG": "토고",
    "TK": "토켈로",
    "TO": "통가",
    "TT": "트리니다드 토바고",
    "TN": "튀니지아",
    "TR": "터키",
    "TM": "트르크메니스탄",
    "TC": "터크스",
    "TV": "트발루",
    "UG": "우간다",
    "UA": "우크라이나",
    "AE": "아랍에미리트연합",
    "GB": "영국",
    "US": "미국",
    "UM": "미국령 소군도",
    "UY": "우루과이",
    "UZ": "우즈베키스탄",
    "VU": "바누아투",
    "VE": "베네수엘라",
    "VN": "베트남",
    "VG": "영국령 버진아일랜드",
    "VI": "미국령 버진아일랜드",
    "WF": "월리스 후트나",
    "EH": "서사하라",
    "YE": "예멘",
    "ZM": "잠비아",
    "ZW": "짐바브웨 공화국",
    "AX": "올란드 제도",
    "BQ": "보네르,신트유스타티우스,사바",
    "CW": "큐라소",
    "GG": "건지",
    "IM": "맨섬",
    "JE": "저지",
    "ME": "몬테네그로",
    "BL": "생 바르 텔레 미",
    "MF": "세인트 마틴 (프랑스어 부분)",
    "RS": "세르비아",
    "SX": "신트마르텐",
    "SS": "남수단",
    "XK": "코소보"
  }
}
