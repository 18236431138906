{
  "locale": "id",
  "countries": {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "Samoa Amerika",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua dan Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahama",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarusia",
    "BE": "Belgia",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia dan Herzegovina",
    "BW": "Botswana",
    "BV": "Kepulauan Bouvet",
    "BR": "Brasil",
    "IO": "Teritori Samudra Hindia Britania",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Kamboja",
    "CM": "Kamerun",
    "CA": "Kanada",
    "CV": "Tanjung Verde",
    "KY": "Kepulauan Cayman",
    "CF": "Afrika Tengah",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Pulau Natal",
    "CC": "Kepulauan Cocos (Keeling)",
    "CO": "Kolombia",
    "KM": "Komoro",
    "CG": "Kongo",
    "CD": "Republik Demokratik Kongo",
    "CK": "Kepulauan Cook",
    "CR": "Kosta Rika",
    "CI": "Pantai Gading",
    "HR": "Kroasia",
    "CU": "Kuba",
    "CY": "Siprus",
    "CZ": "Republik Ceko",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominika",
    "DO": "Republik Dominika",
    "EC": "Ekuador",
    "EG": "Mesir",
    "SV": "El Salvador",
    "GQ": "Guinea Khatulistiwa",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Kepulauan Falkland(Malvinas)",
    "FO": "Kepulauan Faroe",
    "FJ": "Fiji",
    "FI": "Finlandia",
    "FR": "Perancis",
    "GF": "Guyana Perancis",
    "PF": "Polinesia Perancis",
    "TF": "Antartika Perancis",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Jerman",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Yunani",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatamala",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Pulau Heard dan Kepulauan McDonald",
    "VA": "Vatikan",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungaria",
    "IS": "Islandia",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Irlandia",
    "IL": "Israel",
    "IT": "Italia",
    "JM": "Jamaika",
    "JP": "Jepang",
    "JO": "Yordania",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea Utara",
    "KR": "Korea Selatan",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Laos",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxemburg",
    "MO": "Makau",
    "MK": "Makedonia Utara",
    "MG": "Madagaskar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Kepulauan Marshall",
    "MQ": "Martinik",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Meksiko",
    "FM": "Federasi Mikronesia",
    "MD": "Moldova",
    "MC": "Monako",
    "MN": "Mongolia",
    "MS": "Montserrat",
    "MA": "Moroko",
    "MZ": "Mozambik",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Belanda",
    "NC": "Kaledonia Baru",
    "NZ": "Selandia Baru",
    "NI": "Nikaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Kepulauan Norfolk",
    "MP": "Kepulauan Mariana Utara",
    "NO": "Norwegia",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestina",
    "PA": "Panama",
    "PG": "Papua Nugini",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Filipina",
    "PN": "Pitcairn",
    "PL": "Polandia",
    "PT": "Portugal",
    "PR": "Puerto Riko",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Rumania",
    "RU": "Rusia",
    "RW": "Rwanda",
    "SH": "Saint Helena",
    "KN": "Saint Kitts dan Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre dan Miquelon",
    "VC": "Saint Vincent dan the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome dan Principe",
    "SA": "Arab Saudi",
    "SN": "Senegal",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapura",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Kepulauan Solomon",
    "SO": "Somalia",
    "ZA": "Afrika Selatan",
    "GS": "Georgia Selatan dan Kepulauan Sandwich Selatan",
    "ES": "Spanyol",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard dan Jan Mayen",
    "SZ": "Eswatini",
    "SE": "Sweden",
    "CH": "Swiss",
    "SY": "Suriah",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad dan Tobago",
    "TN": "Tunisia",
    "TR": "Turki",
    "TM": "Turkmenistan",
    "TC": "Turks dan Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraina",
    "AE": "Uni Emirat Arab",
    "GB": "Britania Raya",
    "US": "Amerika Serikat",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viet Nam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis and Futuna",
    "EH": "Sahara Barat",
    "YE": "Yaman",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland Islands",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Isle of Man",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Saint Barthélemy",
    "MF": "Saint Martin (French part)",
    "RS": "Serbia",
    "SX": "Sint Maarten (Dutch part)",
    "SS": "Sudan Selatan",
    "XK": "Kosovo"
  }
}
