{
  "locale": "af",
  "countries": {
    "AF": "Afganistan",
    "AL": "Albanië",
    "DZ": "Algerië",
    "AS": "Amerikaans-Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarktika",
    "AG": "Antigua en Barbuda",
    "AR": "Argentinië",
    "AM": "Armenië",
    "AW": "Aruba",
    "AU": "Australië",
    "AT": "Oostenryk",
    "AZ": "Azerbeidjan",
    "BS": "Bahamas",
    "BH": "Bahrein",
    "BD": "Bangladesj",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "België",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhoetan",
    "BO": "Bolivië",
    "BA": "Bosnië en Herzegowina",
    "BW": "Botswana",
    "BV": "Bouveteiland",
    "BR": "Brasilië",
    "IO": "Britse Indiese Oseaangebied",
    "BN": "Broenei",
    "BG": "Bulgarye",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Kambodja",
    "CM": "Kameroen",
    "CA": "Kanada",
    "CV": "Kaap Verde",
    "KY": "Kaaimanseilande",
    "CF": "Sentraal-Afrikaanse Republiek",
    "TD": "Tsjad",
    "CL": "Chili",
    "CN": "Sjina",
    "CX": "Kerseiland",
    "CC": "Cocos- (Keeling) eilande",
    "CO": "Colombië",
    "KM": "Comore",
    "CG": "Republiek van die Kongo",
    "CD": "Demokratiese Republiek van die Kongo",
    "CK": "Cookeilande",
    "CR": "Costa Rica",
    "CI": "Ivoorkus",
    "HR": "Kroasië",
    "CU": "Kuba",
    "CY": "Siprus",
    "CZ": "Tjeggiese Republiek",
    "DK": "Denemarke",
    "DJ": "Djiboeti",
    "DM": "Dominica",
    "DO": "Dominikaanse Republiek",
    "EC": "Ecuador",
    "EG": "Egipte",
    "SV": "El Salvador",
    "GQ": "Ekwatoriaal-Guinee",
    "ER": "Eritrea",
    "EE": "Estland",
    "ET": "Ethiopië",
    "FK": "Falklandeilande",
    "FO": "Faroëreilande",
    "FJ": "Fidji",
    "FI": "Finland",
    "FR": "Frankryk",
    "GF": "Frans-Guyana",
    "PF": "Frans-Polinesië",
    "TF": "Franse Suidelike Gebiede",
    "GA": "Gaboen",
    "GM": "Gambië",
    "GE": "Georgië",
    "DE": "Duitsland",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Griekeland",
    "GL": "Groenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Guinee",
    "GW": "Guinee-Bissau",
    "GY": "Guyana",
    "HT": "Haïti",
    "HM": "Heard en McDonaldeilande",
    "VA": "Vatikaanstad",
    "HN": "Honduras",
    "HK": "Hongkong SAS Sjina",
    "HU": "Hongarye",
    "IS": "Ysland",
    "IN": "Indië",
    "ID": "Indonesië",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Ierland",
    "IL": "Israel",
    "IT": "Italië",
    "JM": "Jamaika",
    "JP": "Japan",
    "JO": "Jordanië",
    "KZ": "Kazakstan",
    "KE": "Kenia",
    "KI": "Kiribati",
    "KP": "Noord-Korea",
    "KR": "Suid-Korea",
    "KW": "Koeweit",
    "KG": "Kirgisië",
    "LA": "Laos",
    "LV": "Letland",
    "LB": "Libanon",
    "LS": "Lesotho",
    "LR": "Liberië",
    "LY": "Libië",
    "LI": "Liechtenstein",
    "LT": "Litaue",
    "LU": "Luxemburg",
    "MO": "Macau SAS Sjina",
    "MG": "Madagaskar",
    "MW": "Malawi",
    "MY": "Maleisië",
    "MV": "Maledive",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshalleilande",
    "MQ": "Martinique",
    "MR": "Mauritanië",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Meksiko",
    "FM": "Mikronesië",
    "MD": "Moldowa",
    "MC": "Monaco",
    "MN": "Mongolië",
    "MS": "Montserrat",
    "MA": "Marokko",
    "MZ": "Mosambiek",
    "MM": "Mianmar (Birma)",
    "NA": "Namibië",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Nederland",
    "NC": "Nieu-Kaledonië",
    "NZ": "Nieu-Seeland",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigerië",
    "NU": "Niue",
    "NF": "Norfolkeiland",
    "MK": "Macedonië",
    "MP": "Noordelike Mariana-eilande",
    "NO": "Noorweë",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestynse gebiede",
    "PA": "Panama",
    "PG": "Papoea-Nieu-Guinee",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Filippyne",
    "PN": "Pitcairneilande",
    "PL": "Pole",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Katar",
    "RE": "Réunion",
    "RO": "Roemenië",
    "RU": "Rusland",
    "RW": "Rwanda",
    "SH": "Sint Helena",
    "KN": "Sint Kitts en Nevis",
    "LC": "Sint Lucia",
    "PM": "Sint Pierre en Miquelon",
    "VC": "Sint Vincent en die Grenadine",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome en Principe",
    "SA": "Saoedi-Arabië",
    "SN": "Senegal",
    "SC": "Seychelle",
    "SL": "Sierra Leone",
    "SG": "Singapoer",
    "SK": "Slowakye",
    "SI": "Slowenië",
    "SB": "Solomoneilande",
    "SO": "Somalië",
    "ZA": "Suid-Afrika",
    "GS": "Suid-Georgië en die Suidelike Sandwicheilande",
    "ES": "Spanje",
    "LK": "Sri Lanka",
    "SD": "Soedan",
    "SR": "Suriname",
    "SJ": "Svalbard en Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Swede",
    "CH": "Switserland",
    "SY": "Sirië",
    "TW": "Taiwan",
    "TJ": "Tadjikistan",
    "TZ": "Tanzanië",
    "TH": "Thailand",
    "TL": "Oos-Timor",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad en Tobago",
    "TN": "Tunisië",
    "TR": "Turkye",
    "TM": "Turkmenië",
    "TC": "Turks- en Caicoseilande",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Oekraïne",
    "AE": "Verenigde Arabiese Emirate",
    "GB": "Verenigde Koninkryk",
    "US": "Verenigde State van Amerika",
    "UM": "VS klein omliggende eilande",
    "UY": "Uruguay",
    "UZ": "Oesbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viëtnam",
    "VG": "Britse Maagde-eilande",
    "VI": "Amerikaanse Maagde-eilande",
    "WF": "Wallis en Futuna",
    "EH": "Wes-Sahara",
    "YE": "Jemen",
    "ZM": "Zambië",
    "ZW": "Zimbabwe",
    "AX": "Ålandeilande",
    "BQ": "Karibiese Nederland",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Eiland Man",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Sint Barthélemy",
    "MF": "Sint Martin",
    "RS": "Serwië",
    "SX": "Sint Maarten",
    "SS": "Suid-Soedan",
    "XK": "Kosovo"
  }
}
