{
  "locale": "ga",
  "countries": {
    "AF": "An Afganastáin",
    "AL": "An Albáin",
    "DZ": "An Ailgéir",
    "AS": "Samó Meiriceánach",
    "AD": "Andóra",
    "AO": "Angóla",
    "AI": "Anguilla",
    "AQ": "An Antartaice",
    "AG": "Antigua agus Barbúda",
    "AR": "An Airgintín",
    "AM": "An Airméin",
    "AW": "Arúba",
    "AU": "An Astráil",
    "AT": "An Ostair",
    "AZ": "An Asarbaiseáin",
    "BS": "Na Bahámaí",
    "BH": "Bairéin",
    "BD": "An Bhanglaidéis",
    "BB": "Barbadós",
    "BY": "An Bhealarúis",
    "BE": "An Bheilg",
    "BZ": "An Bheilís",
    "BJ": "Beinin",
    "BM": "Beirmiúda",
    "BT": "An Bhútáin",
    "BO": "An Bholaiv",
    "BA": "An Bhoisnia agus An Heirseagaivéin",
    "BW": "An Bhotsuáin",
    "BV": "Oileán Bouvet",
    "BR": "An Bhrasaíl",
    "IO": "Críoch Aigéan Indiach na Breataine",
    "BN": "Brúiné",
    "BG": "An Bhulgáir",
    "BF": "Buircíne Fasó",
    "BI": "An Bhurúin",
    "KH": "An Chambóid",
    "CM": "Camarún",
    "CA": "Ceanada",
    "CV": "Rinn Verde",
    "KY": "Oileáin Cayman",
    "CF": "Poblacht na hAfraice Láir",
    "TD": "Sead",
    "CL": "An tSile",
    "CN": ["Poblacht na Síne", "An tSín"],
    "CX": "Oileán na Nollag",
    "CC": "Oileáin Cocos (Keeling)",
    "CO": "An Cholóim",
    "KM": "Oileáin Chomóra",
    "CG": ["Poblacht an Chongó", "An Congó"],
    "CD": ["Poblacht Dhaonlathach an Chongó", "An Congó"],
    "CK": "Oileáin Cook",
    "CR": "Cósta Ríce",
    "CI": ["Cósta Eabhair", "An Cósta Eabhair", "Côte d'Ivoire"],
    "HR": "An Chróit",
    "CU": "Cúba",
    "CY": "An Chipir",
    "CZ": ["Poblacht na Seice", "An tSeicia"],
    "DK": "An Danmhairg",
    "DJ": "Djibouti",
    "DM": "Doiminice",
    "DO": "Poblacht Dhoiminiceach",
    "EC": "Eacuadór",
    "EG": "An Éigipt",
    "SV": "An tSalvadóir",
    "GQ": "An Ghuine Mheánchiorclach",
    "ER": "An Eiritré",
    "EE": "An Eastóin",
    "ET": "An Aetóip",
    "FK": "Oileáin Fháclainne (Malvinas)",
    "FO": "Oileáin Fharó",
    "FJ": "Fidsí",
    "FI": "An Fhionlainn",
    "FR": "An Fhrainc",
    "GF": "Guáin na Fraince",
    "PF": "Polainéis na Fraince",
    "TF": "Críocha Deisceartacha na Fraince",
    "GA": "An Ghabúin",
    "GM": ["Poblacht na Gaimbia", "An Ghaimbia"],
    "GE": "An tSeoirsia",
    "DE": "An Ghearmáin",
    "GH": "Gána",
    "GI": "Giobráltar",
    "GR": "An Ghréig",
    "GL": "An Ghraonlainn",
    "GD": "Greanáda",
    "GP": "Guadalúip",
    "GU": "Guam",
    "GT": "Guatamala",
    "GN": "An Ghuine",
    "GW": "Guine Bissau",
    "GY": "An Ghuáin",
    "HT": "Háití",
    "HM": "Oileán Heard agus Oileáin McDonald",
    "VA": "Cathair na Vatacáine",
    "HN": "Hondúras",
    "HK": "Hong Cong",
    "HU": "An Ungáir",
    "IS": "An Íoslainn",
    "IN": "An India",
    "ID": "An Indinéis",
    "IR": ["Poblacht Ioslamach na hIaráine", "An Iaráin"],
    "IQ": "An Iaráic",
    "IE": "Éire",
    "IL": "Iosrael",
    "IT": "An Iodáil",
    "JM": "Iamáice",
    "JP": "An tSeapáin",
    "JO": "An Iordáin",
    "KZ": "An Chasacstáin",
    "KE": "An Chéinia",
    "KI": "Cireabaití",
    "KP": "An Chóiré Thuaidh",
    "KR": ["An Chóiré Theas", "Poblacht na Cóiré", "An Chóiré"],
    "KW": "Cuáit",
    "KG": "An Chirgeastáin",
    "LA": "Poblacht Dhaonlathach na Laoise",
    "LV": "An Laitvia",
    "LB": "An Liobáin",
    "LS": "Leosóta",
    "LR": "An Libéir",
    "LY": "An Libia",
    "LI": "Lichtinstéin",
    "LT": "An Liotuáin",
    "LU": "Lucsamburg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "An Mhaláiv",
    "MY": "An Mhalaeisia",
    "MV": "Oileáin Mhaildíve",
    "ML": "Mailí",
    "MT": "Málta",
    "MH": "Oileáin Marshall",
    "MQ": "Martinique",
    "MR": "An Mháratáin",
    "MU": "Oileán Mhuirís",
    "YT": "Mayotte",
    "MX": "Meicsiceo",
    "FM": "Micrinéis, Stáit Chónaidhme",
    "MD": "An Mholdóiv",
    "MC": "Monacó",
    "MN": "An Mhongóil",
    "MS": "Montsarat",
    "MA": "Maracó",
    "MZ": "Mósaimbíc",
    "MM": "Maenmar (Burma)",
    "NA": "An Namaib",
    "NR": "Nárú",
    "NP": "Neipeal",
    "NL": "An Ísiltír",
    "NC": "An Nua-Chaladóin",
    "NZ": "An Nua-Shéalainn",
    "NI": "Nicearagua",
    "NE": "An Nígir",
    "NG": "An Nigéir",
    "NU": "Niue",
    "NF": "Oileán Norfolk",
    "MK": ["Poblacht Iarthar na Macadóine", "An Mhacadóin Thuaidh"],
    "MP": "Oileáin Mariana Thuaidh",
    "NO": "An Iorua",
    "OM": "Óman",
    "PK": "An Phacastáin",
    "PW": "Palau",
    "PS": ["Stát na Palaistíne", "An Phalaistín"],
    "PA": "Panama",
    "PG": "Nua-Ghuine Phapua",
    "PY": "Paragua",
    "PE": "Peiriú",
    "PH": "Na hOileáin Fhilipíneacha",
    "PN": ["Oileáin Pitcairn", "Pitcairn"],
    "PL": "An Pholainn",
    "PT": "An Phortaingéil",
    "PR": "Pórtó Ríce",
    "QA": "Catar",
    "RE": "Réunion",
    "RO": "An Rómáin",
    "RU": ["An Rúis", "Cónaidhm na Rúise"],
    "RW": "Ruanda",
    "SH": "Saint Helena",
    "KN": "Saint Kitts agus Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre agus Miquelon",
    "VC": "Saint Vincent agus na Greanáidíní",
    "WS": "Samó",
    "SM": "San Mairíne",
    "ST": "São Tomé agus Príncipe",
    "SA": "An Araib Shádach",
    "SN": "An tSeineagáil",
    "SC": "Na Séiséil",
    "SL": "Siarra Leon",
    "SG": "Singeapór",
    "SK": "An tSlóvaic",
    "SI": "An tSlóivéin",
    "SB": "Oileáin Sholamón",
    "SO": "An tSomáil",
    "ZA": "An Afraic Theas",
    "GS": "An tSeoirsia Theas agus Oileáin Sandwich Theas",
    "ES": "An Spáinn",
    "LK": "Srí Lanca",
    "SD": "An tSúdáin",
    "SR": "Suranam",
    "SJ": "Svalbard agus Jan Mayen",
    "SZ": "An tSuasalainn",
    "SE": "An tSualainn",
    "CH": "An Eilvéis",
    "SY": "Poblacht Arabach na Siria",
    "TW": ["Taiwan, Cúige na Síne", "Taiwan"],
    "TJ": "An Táidsíceastáin",
    "TZ": ["Poblacht Aontaithe na Tansáine", "An Tansáin"],
    "TH": "An Téalainn",
    "TL": "Tíomór-Leste",
    "TG": "Tóga",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Oileáin na Tríonóide agus Tobága",
    "TN": "An Túinéis",
    "TR": ["An Tuirc", "Tuirc"],
    "TM": "An Tuircméanastáin",
    "TC": "Oileáin na dTurcach agus Caicos",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "An Úcráin",
    "AE": "Aontas na nÉimíríochtaí Arabacha",
    "GB": ["An Ríocht Aontaithe", "An Bhreatain Mhór", "An Ríocht Aontaithe"],
    "US": ["Stáit Aontaithe Mheiriceá", "Stáit Aontaithe", "SAM", "S.A.M.", "US", "S.A."],
    "UM": "Oileáin Imeallacha Stáit Aontaithe",
    "UY": "Uragua",
    "UZ": "An Úisbéiceastáin",
    "VU": "Vanuatú",
    "VE": "Veiniséala",
    "VN": "Vítneam",
    "VG": "Oileáin Bhriotanacha na Maighdean",
    "VI": "Oileáin Mheiriceánacha na Maighdean",
    "WF": "Wallis agus Futuna",
    "EH": "An Sahára Thiar",
    "YE": "Éimin",
    "ZM": "An tSaimbia",
    "ZW": "An tSiombáib",
    "AX": ["Oileáin Åland", "Oileáin Aland"],
    "BQ": "Bonaire, Sint Eustatius agus Saba",
    "CW": "Curaçao",
    "GG": "Geansaí",
    "IM": "Oileán Mhanann",
    "JE": "Geirsí",
    "ME": "Montainéagró",
    "BL": "Saint Barthélemy",
    "MF": "Saint Martin (cuid na Fraince)",
    "RS": "An tSeirbia",
    "SX": "Sint Maarten (cuid na hÍsiltíre)",
    "SS": "An tSúdáin Theas",
    "XK": "An Chosaiv"
  }
}
