import { Injectable, inject } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { OVERLAY_DISPOSE_TIMEOUT } from '@shared/models';

import { DEFAULT_TOAST_DURATION, DEFAULT_TOAST_POSITION } from '../models/default-toast-config';
import { Toast } from '../models/toast.interface';
import { toastTitleRecord, toastIconRecord } from '../models/toast.type';
import { ToasterComponent } from '../toaster.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _toastsSubject: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);
  private _overlayRef: OverlayRef;
  private _timeOut: ReturnType<typeof setTimeout>;

  private _overlay = inject(Overlay);
  private _translateService = inject(TranslateService);

  get toasts$(): Observable<Toast[]> {
    return this._toastsSubject.asObservable();
  }

  showToast(toast: Toast): void {
    const savedToasts = this._toastsSubject.getValue();
    const currentToast = {
      title: toast.title ?? this._translateService.instant(toastTitleRecord[toast.type]),
      type: toast.type,
      text: toast.text,
      icon: toast.icon ?? toastIconRecord[toast.type] ?? '',
      duration: toast.duration ?? DEFAULT_TOAST_DURATION,
      position: toast.position ?? DEFAULT_TOAST_POSITION,
    };
    const updatedToasts = [...savedToasts, currentToast];

    this._toastsSubject.next(updatedToasts);

    if (!this._overlayRef) {
      this._prepareOverlay(currentToast);

      return;
    }

    if (this._timeOut) {
      clearTimeout(this._timeOut);
    }
  }

  hideToast(index: number): void {
    const currentArrValue = [...this._toastsSubject.getValue()];

    currentArrValue.splice(index, 1);
    this._toastsSubject.next(currentArrValue);

    if (!currentArrValue.length) {
      this._timeOut = setTimeout(() => {
        this._toastsSubject.next([]);
        this._overlayRef.dispose();
        this._overlayRef = null;
      }, OVERLAY_DISPOSE_TIMEOUT);
    }
  }

  private _prepareOverlay(toast: Toast): void {
    const positionStrategy = this._overlay
      .position()
      .global()
      .right(toast.position.right + 'px')
      .top(toast.position.top + 'px');

    this._overlayRef = this._overlay.create({ positionStrategy });
    this._overlayRef.attach(this._getToastPortal());
  }

  /**
   * Creates a new toastPortal
   */
  private _getToastPortal(): ComponentPortal<ToasterComponent> {
    return new ComponentPortal(ToasterComponent, null, null);
  }
}
