{
  "locale": "fa",
  "countries": {
    "AD": "آندورا",
    "AE": "امارات متحدهٔ عربی",
    "AF": "افغانستان",
    "AG": "آنتیگوا و باربودا",
    "AI": "آنگویلا",
    "AL": "آلبانی",
    "AM": "ارمنستان",
    "AO": "آنگولا",
    "AQ": "جنوبگان",
    "AR": "آرژانتین",
    "AS": "ساموآی امریکا",
    "AT": "اتریش",
    "AU": "استرالیا",
    "AW": "آروبا",
    "AX": "جزایر آلاند",
    "AZ": "جمهوری آذربایجان",
    "BA": "بوسنی و هرزگوین",
    "BB": "باربادوس",
    "BD": "بنگلادش",
    "BE": "بلژیک",
    "BF": "بورکینافاسو",
    "BG": "بلغارستان",
    "BH": "بحرین",
    "BI": "بوروندی",
    "BJ": "بنین",
    "BL": "سن بارتلمی",
    "BM": "برمودا",
    "BN": "برونئی",
    "BO": "بولیوی",
    "BQ": "جزایر کارائیب هلند",
    "BR": "برزیل",
    "BS": "باهاما",
    "BT": "بوتان",
    "BV": "جزیرهٔ بووه",
    "BW": "بوتسوانا",
    "BY": "بلاروس",
    "BZ": "بلیز",
    "CA": "کانادا",
    "CC": "جزایر کوکوس",
    "CD": "کنگو - کینشاسا",
    "CF": "جمهوری افریقای مرکزی",
    "CG": "کنگو - برازویل",
    "CH": "سوئیس",
    "CI": "ساحل عاج",
    "CK": "جزایر کوک",
    "CL": "شیلی",
    "CM": "کامرون",
    "CN": "چین",
    "CO": "کلمبیا",
    "CR": "کاستاریکا",
    "CU": "کوبا",
    "CV": "کیپ‌ورد",
    "CW": "کوراسائو",
    "CX": "جزیرهٔ کریسمس",
    "CY": "قبرس",
    "CZ": "جمهوری چک",
    "DE": "آلمان",
    "DJ": "جیبوتی",
    "DK": "دانمارک",
    "DM": "دومینیکا",
    "DO": "جمهوری دومینیکن",
    "DZ": "الجزایر",
    "EC": "اکوادور",
    "EE": "استونی",
    "EG": "مصر",
    "EH": "صحرای غربی",
    "ER": "اریتره",
    "ES": "اسپانیا",
    "ET": "اتیوپی",
    "FI": "فنلاند",
    "FJ": "فیجی",
    "FK": "جزایر فالکلند",
    "FM": "میکرونزی",
    "FO": "جزایر فارو",
    "FR": "فرانسه",
    "GA": "گابن",
    "GB": "بریتانیا",
    "GD": "گرنادا",
    "GE": "گرجستان",
    "GF": "گویان فرانسه",
    "GG": "گرنزی",
    "GH": "غنا",
    "GI": "جبل‌الطارق",
    "GL": "گرینلند",
    "GM": "گامبیا",
    "GN": "گینه",
    "GP": "گوادلوپ",
    "GQ": "گینهٔ استوایی",
    "GR": "یونان",
    "GS": "جزایر جورجیای جنوبی و ساندویچ جنوبی",
    "GT": "گواتمالا",
    "GU": "گوام",
    "GW": "گینهٔ بیسائو",
    "GY": "گویان",
    "HK": "هنگ‌کنگ",
    "HM": "جزیرهٔ هرد و جزایر مک‌دونالد",
    "HN": "هندوراس",
    "HR": "کرواسی",
    "HT": "هائیتی",
    "HU": "مجارستان",
    "ID": "اندونزی",
    "IE": "ایرلند",
    "IL": "اسرائیل",
    "IM": "جزیرهٔ من",
    "IN": "هند",
    "IO": "قلمرو بریتانیا در اقیانوس هند",
    "IQ": "عراق",
    "IR": "ایران",
    "IS": "ایسلند",
    "IT": "ایتالیا",
    "JE": "جرزی",
    "JM": "جامائیکا",
    "JO": "اردن",
    "JP": "ژاپن",
    "KE": "کنیا",
    "KG": "قرقیزستان",
    "KH": "کامبوج",
    "KI": "کیریباتی",
    "KM": "کومورو",
    "KN": "سنت کیتس و نویس",
    "KP": "کرهٔ شمالی",
    "KR": "کرهٔ جنوبی",
    "KW": "کویت",
    "KY": "جزایر کِیمن",
    "KZ": "قزاقستان",
    "LA": "لائوس",
    "LB": "لبنان",
    "LC": "سنت لوسیا",
    "LI": "لیختن‌اشتاین",
    "LK": "سری‌لانکا",
    "LR": "لیبریا",
    "LS": "لسوتو",
    "LT": "لیتوانی",
    "LU": "لوکزامبورگ",
    "LV": "لتونی",
    "LY": "لیبی",
    "MA": "مراکش",
    "MC": "موناکو",
    "MD": "مولداوی",
    "ME": "مونته‌نگرو",
    "MF": "سنت مارتین",
    "MG": "ماداگاسکار",
    "MH": "جزایر مارشال",
    "MK": "مقدونیه شمالی",
    "ML": "مالی",
    "MM": "میانمار (برمه)",
    "MN": "مغولستان",
    "MO": "ماکائو",
    "MP": "جزایر ماریانای شمالی",
    "MQ": "مارتینیک",
    "MR": "موریتانی",
    "MS": "مونت‌سرات",
    "MT": "مالت",
    "MU": "موریس",
    "MV": "مالدیو",
    "MW": "مالاوی",
    "MX": "مکزیک",
    "MY": "مالزی",
    "MZ": "موزامبیک",
    "NA": "نامیبیا",
    "NC": "کالدونیای جدید",
    "NE": "نیجر",
    "NF": "جزیرهٔ نورفولک",
    "NG": "نیجریه",
    "NI": "نیکاراگوئه",
    "NL": "هلند",
    "NO": "نروژ",
    "NP": "نپال",
    "NR": "نائورو",
    "NU": "نیوئه",
    "NZ": "نیوزیلند",
    "OM": "عمان",
    "PA": "پاناما",
    "PE": "پرو",
    "PF": "پلی‌نزی فرانسه",
    "PG": "پاپوا گینهٔ نو",
    "PH": "فیلیپین",
    "PK": "پاکستان",
    "PL": "لهستان",
    "PM": "سن پیر و میکلن",
    "PN": "جزایر پیت‌کرن",
    "PR": "پورتوریکو",
    "PS": "سرزمین‌های فلسطینی",
    "PT": "پرتغال",
    "PW": "پالائو",
    "PY": "پاراگوئه",
    "QA": "قطر",
    "RE": "رئونیون",
    "RO": "رومانی",
    "RS": "صربستان",
    "RU": "روسیه",
    "RW": "رواندا",
    "SA": "عربستان سعودی",
    "SB": "جزایر سلیمان",
    "SC": "سیشل",
    "SD": "سودان",
    "SE": "سوئد",
    "SG": "سنگاپور",
    "SH": "سنت هلن",
    "SI": "اسلوونی",
    "SJ": "اسوالبارد و جان‌ماین",
    "SK": "اسلواکی",
    "SL": "سیرالئون",
    "SM": "سان‌مارینو",
    "SN": "سنگال",
    "SO": "سومالی",
    "SR": "سورینام",
    "SS": "سودان جنوبی",
    "ST": "سائوتومه و پرینسیپ",
    "SV": "السالوادور",
    "SX": "سنت مارتن",
    "SY": "سوریه",
    "SZ": "سوازیلند",
    "TC": "جزایر تورکس و کایکوس",
    "TD": "چاد",
    "TF": "قلمروهای جنوبی فرانسه",
    "TG": "توگو",
    "TH": "تایلند",
    "TJ": "تاجیکستان",
    "TK": "توکلائو",
    "TL": "تیمور-لسته",
    "TM": "ترکمنستان",
    "TN": "تونس",
    "TO": "تونگا",
    "TR": "ترکیه",
    "TT": "ترینیداد و توباگو",
    "TV": "تووالو",
    "TW": "تایوان",
    "TZ": "تانزانیا",
    "UA": "اوکراین",
    "UG": "اوگاندا",
    "UM": "جزایر دورافتادهٔ ایالات متحده",
    "US": "ایالات متحده",
    "UY": "اروگوئه",
    "UZ": "ازبکستان",
    "VA": "واتیکان",
    "VC": "سنت وینسنت و گرنادین",
    "VE": "ونزوئلا",
    "VG": "جزایر ویرجین بریتانیا",
    "VI": "جزایر ویرجین ایالات متحده",
    "VN": "ویتنام",
    "VU": "وانواتو",
    "WF": "والیس و فوتونا",
    "WS": "ساموآ",
    "XK": "کوزوو",
    "YE": "یمن",
    "YT": "مایوت",
    "ZA": "افریقای جنوبی",
    "ZM": "زامبیا",
    "ZW": "زیمبابوه"
  }
}
