@let control = ngControl.control;

<div class="ui-input_content" id="control_content">
  <div class="ui-input_wrapper">
    <input
      class="ui-input control w-100"
      [formControl]="control"
      [placeholder]="placeholder() | translate"
      [readonly]="isReadonly()"
      [type]="controlType()"
      [autocomplete]="autocomplete()"
      [step]="step()"
      [min]="min()"
      [max]="max()"
      [ngClass]="{
        control_invalid: control?.invalid && control?.dirty,
        'control_with-icon': !!iconTemplate(),
      }"
    />
    @if (iconTemplate()) {
      <div class="ui-input_icon control-icon">
        <ng-container [ngTemplateOutlet]="iconTemplate()" />
      </div>
    }
  </div>

  @if (control?.invalid && control?.dirty) {
    <ui-validation-message [errors]="control?.errors" />
  }
</div>
