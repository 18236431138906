{
  "locale": "th",
  "countries": {
    "BD": "บังกลาเทศ",
    "BE": "เบลเยียม",
    "BF": "บูร์กินาฟาโซ",
    "BG": "บัลแกเรีย",
    "BA": "บอสเนียและเฮอร์เซโกวีนา",
    "BB": "บาร์เบโดส",
    "WF": "วาลลิสและฟุตูนา",
    "BL": "เซนต์บาร์เธเลมี",
    "BM": "เบอร์มิวดา",
    "BN": "บรูไน",
    "BO": "โบลิเวีย",
    "BH": "บาห์เรน",
    "BI": "บุรุนดี",
    "BJ": "เบนิน",
    "BT": "ภูฏาน",
    "JM": "จาเมกา",
    "BV": "เกาะบูเวต",
    "BW": "บอตสวานา",
    "WS": "ซามัว",
    "BR": "บราซิล",
    "BS": "บาฮามาส",
    "JE": "เจอร์ซีย์",
    "BY": "เบลารุส",
    "BZ": "เบลีซ",
    "RU": "รัสเซีย",
    "RW": "รวันดา",
    "RS": "เซอร์เบีย",
    "TL": "ติมอร์ตะวันออก",
    "RE": "เรอูนียง",
    "TM": "เติร์กเมนิสถาน",
    "TJ": "ทาจิกิสถาน",
    "RO": "โรมาเนีย",
    "TK": "โตเกเลา",
    "GW": "กินี-บิสเซา",
    "GU": "กวม",
    "GT": "กัวเตมาลา",
    "GS": "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช",
    "GR": "กรีซ",
    "GQ": "อิเควทอเรียลกินี",
    "GP": "กวาเดอลูป",
    "JP": "ญี่ปุ่น",
    "GY": "กายอานา",
    "GG": "เกิร์นซีย์",
    "GF": "เฟรนช์เกียนา",
    "GE": "จอร์เจีย",
    "GD": "เกรเนดา",
    "GB": "สหราชอาณาจักร",
    "GA": "กาบอง",
    "SV": "เอลซัลวาดอร์",
    "GN": "กินี",
    "GM": "แกมเบีย",
    "GL": "กรีนแลนด์",
    "GI": "ยิบรอลตาร์",
    "GH": "กานา",
    "OM": "โอมาน",
    "TN": "ตูนิเซีย",
    "JO": "จอร์แดน",
    "HR": "โครเอเชีย",
    "HT": "เฮติ",
    "HU": "ฮังการี",
    "HK": "ฮ่องกง เขตปกครองพิเศษประเทศจีน",
    "HN": "ฮอนดูรัส",
    "HM": "เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์",
    "VE": "เวเนซุเอลา",
    "PR": "เปอร์โตริโก",
    "PS": "ปาเลสไตน์",
    "PW": "ปาเลา",
    "PT": "โปรตุเกส",
    "SJ": "สฟาลบาร์และยานไมเอน",
    "PY": "ปารากวัย",
    "IQ": "อิรัก",
    "PA": "ปานามา",
    "PF": "เฟรนช์โปลินีเซีย",
    "PG": "ปาปัวนิวกินี",
    "PE": "เปรู",
    "PK": "ปากีสถาน",
    "PH": "ฟิลิปปินส์",
    "PN": "พิตแคร์น",
    "PL": "โปแลนด์",
    "PM": "แซงปีแยร์และมีเกอลง",
    "ZM": "แซมเบีย",
    "EH": "ซาฮาราตะวันตก",
    "EE": "เอสโตเนีย",
    "EG": "อียิปต์",
    "ZA": "แอฟริกาใต้",
    "EC": "เอกวาดอร์",
    "IT": "อิตาลี",
    "VN": "เวียดนาม",
    "SB": "หมู่เกาะโซโลมอน",
    "ET": "เอธิโอเปีย",
    "SO": "โซมาเลีย",
    "ZW": "ซิมบับเว",
    "SA": "ซาอุดีอาระเบีย",
    "ES": "สเปน",
    "ER": "เอริเทรีย",
    "ME": "มอนเตเนโกร",
    "MD": "มอลโดวา",
    "MG": "มาดากัสการ์",
    "MF": "เซนต์มาติน",
    "MA": "โมร็อกโก",
    "MC": "โมนาโก",
    "UZ": "อุซเบกิสถาน",
    "MM": "เมียนม่าร์ [พม่า]",
    "ML": "มาลี",
    "MO": "มาเก๊า เขตปกครองพิเศษประเทศจีน",
    "MN": "มองโกเลีย",
    "MH": "หมู่เกาะมาร์แชลล์",
    "MK": "มาซิโดเนีย",
    "MU": "มอริเชียส",
    "MT": "มอลตา",
    "MW": "มาลาวี",
    "MV": "มัลดีฟส์",
    "MQ": "มาร์ตินีก",
    "MP": "หมู่เกาะนอร์เทิร์นมาเรียนา",
    "MS": "มอนต์เซอร์รัต",
    "MR": "มอริเตเนีย",
    "IM": "เกาะแมน",
    "UG": "ยูกันดา",
    "TZ": "แทนซาเนีย",
    "MY": "มาเลเซีย",
    "MX": "เม็กซิโก",
    "IL": "อิสราเอล",
    "FR": "ฝรั่งเศส",
    "IO": "บริติชอินเดียนโอเชียนเทร์ริทอรี",
    "SH": "เซนต์เฮเลนา",
    "FI": "ฟินแลนด์",
    "FJ": "ฟิจิ",
    "FK": "หมู่เกาะฟอล์กแลนด์",
    "FM": "ไมโครนีเซีย",
    "FO": "หมู่เกาะแฟโร",
    "NI": "นิการากัว",
    "NL": "เนเธอร์แลนด์",
    "NO": "นอร์เวย์",
    "NA": "นามิเบีย",
    "VU": "วานูอาตู",
    "NC": "นิวแคลิโดเนีย",
    "NE": "ไนเจอร์",
    "NF": "เกาะนอร์ฟอล์ก",
    "NG": "ไนจีเรีย",
    "NZ": "นิวซีแลนด์",
    "NP": "เนปาล",
    "NR": "นาอูรู",
    "NU": "นีอูเอ",
    "CK": "หมู่เกาะคุก",
    "CI": "ไอวอรี่โคสต์",
    "CH": "สวิตเซอร์แลนด์",
    "CO": "โคลอมเบีย",
    "CN": "จีน",
    "CM": "แคเมอรูน",
    "CL": "ชิลี",
    "CC": "หมู่เกาะโคโคส",
    "CA": "แคนาดา",
    "CG": "คองโก-บราซซาวิล",
    "CF": "สาธารณรัฐแอฟริกากลาง",
    "CD": "คองโก-กินชาซา",
    "CZ": "สาธารณรัฐเช็ก",
    "CY": "ไซปรัส",
    "CX": "เกาะคริสต์มาส",
    "CR": "คอสตาริกา",
    "CV": "เคปเวิร์ด",
    "CU": "คิวบา",
    "SZ": "สวาซิแลนด์",
    "SY": "ซีเรีย",
    "KG": "คีร์กีซสถาน",
    "KE": "เคนยา",
    "SR": "ซูรินาเม",
    "KI": "คิริบาส",
    "KH": "กัมพูชา",
    "KN": "เซนต์คิตส์และเนวิส",
    "KM": "คอโมโรส",
    "ST": "เซาตูเมและปรินซิปี",
    "SK": "สโลวะเกีย",
    "KR": "เกาหลีใต้",
    "SI": "สโลวีเนีย",
    "KP": "เกาหลีเหนือ",
    "KW": "คูเวต",
    "SN": "เซเนกัล",
    "SM": "ซานมารีโน",
    "SL": "เซียร์ราลีโอน",
    "SC": "เซเชลส์",
    "KZ": "คาซัคสถาน",
    "KY": "หมู่เกาะเคย์แมน",
    "SG": "สิงคโปร์",
    "SE": "สวีเดน",
    "SD": "ซูดาน",
    "DO": "สาธารณรัฐโดมินิกัน",
    "DM": "โดมินิกา",
    "DJ": "จิบูตี",
    "DK": "เดนมาร์ก",
    "VG": "หมู่เกาะบริติชเวอร์จิน",
    "DE": "เยอรมนี",
    "YE": "เยเมน",
    "DZ": "แอลจีเรีย",
    "US": "สหรัฐอเมริกา",
    "UY": "อุรุกวัย",
    "YT": "มายอต",
    "UM": "หมู่เกาะสหรัฐไมเนอร์เอาต์ไลอิง",
    "LB": "เลบานอน",
    "LC": "เซนต์ลูเซีย",
    "LA": "ลาว",
    "TV": "ตูวาลู",
    "TW": "ไต้หวัน",
    "TT": "ตรินิแดดและโตเบโก",
    "TR": "ตุรกี",
    "LK": "ศรีลังกา",
    "LI": "ลิกเตนสไตน์",
    "LV": "ลัตเวีย",
    "TO": "ตองกา",
    "LT": "ลิทัวเนีย",
    "LU": "ลักเซมเบิร์ก",
    "LR": "ไลบีเรีย",
    "LS": "เลโซโท",
    "TH": "ไทย",
    "TF": "เฟรนช์เซาเทิร์นเทร์ริทอรีส์",
    "TG": "โตโก",
    "TD": "ชาด",
    "TC": "หมู่เกาะเติกส์และหมู่เกาะเคคอส",
    "LY": "ลิเบีย",
    "VA": "วาติกัน",
    "VC": "เซนต์วินเซนต์และเกรนาดีนส์",
    "AE": "สหรัฐอาหรับเอมิเรตส์",
    "AD": "อันดอร์รา",
    "AG": "แอนติกาและบาร์บูดา",
    "AF": "อัฟกานิสถาน",
    "AI": "แองกวิลลา",
    "VI": "หมู่เกาะยูเอสเวอร์จิน",
    "IS": "ไอซ์แลนด์",
    "IR": "อิหร่าน",
    "AM": "อาร์เมเนีย",
    "AL": "แอลเบเนีย",
    "AO": "แองโกลา",
    "AQ": "แอนตาร์กติกา",
    "AS": "อเมริกันซามัว",
    "AR": "อาร์เจนตินา",
    "AU": "ออสเตรเลีย",
    "AT": "ออสเตรีย",
    "AW": "อารูบา",
    "IN": "อินเดีย",
    "AX": "หมู่เกาะโอลันด์",
    "AZ": "อาเซอร์ไบจาน",
    "IE": "ไอร์แลนด์",
    "ID": "อินโดนีเซีย",
    "UA": "ยูเครน",
    "QA": "กาตาร์",
    "MZ": "โมซัมบิก",
    "BQ": "โบแนร์, ซินท์เอิสทาทิอุส, ซาบา",
    "CW": "คูราเซา",
    "SX": "Sint Maarten (ส่วนดัตช์)",
    "SS": "ซูดานใต้",
    "XK": "โคโซโว"
  }
}
