{
  "locale": "el",
  "countries": {
    "AF": "Αφγανιστάν",
    "AL": "Αλβανία",
    "DZ": "Αλγερία",
    "AS": "Αμερικανική Σαμόα",
    "AD": "Ανδόρρα",
    "AO": "Ανγκόλα",
    "AI": "Ανγκουίλα",
    "AQ": "Ανταρκτική",
    "AG": "Αντίγκουα και Μπαρμπούντα",
    "AR": "Αργεντινή",
    "AM": "Αρμενία",
    "AW": "Αρούμπα",
    "AU": "Αυστραλία",
    "AT": "Αυστρία",
    "AZ": "Αζερμπαϊτζάν",
    "BS": "Μπαχάμες",
    "BH": "Μπαχρέιν",
    "BD": "Μπανγκλαντές",
    "BB": "Μπαρμπάντος",
    "BY": "Λευκορωσία",
    "BE": "Βέλγιο",
    "BZ": "Μπελίζ",
    "BJ": "Μπενίν",
    "BM": "Βερμούδες",
    "BT": "Μπουτάν",
    "BO": "Βολιβία",
    "BA": "Βοσνία και Ερζεγοβίνη",
    "BW": "Μποτσουάνα",
    "BV": "Μπουβέ",
    "BR": "Βραζιλία",
    "IO": "Βρετανικό Έδαφος Ινδικού Ωκεανού",
    "BN": "Σουλτανάτο του Μπρουνέι",
    "BG": "Βουλγαρία",
    "BF": "Μπουρκίνα Φάσο",
    "BI": "Μπουρουντί",
    "KH": "Καμπότζη",
    "CM": "Καμερούν",
    "CA": "Καναδάς",
    "CV": [
      "Δημοκρατία του Πράσινου Ακρωτηρίου",
      "Πράσινο Ακρωτήριο"
    ],
    "KY": "Κέιμαν Νήσοι",
    "CF": "Κεντροαφρικανική Δημοκρατία",
    "TD": "Τσάντ",
    "CL": "Χιλή",
    "CN": "Κίνα",
    "CX": "Νήσος των Χριστουγέννων",
    "CC": "Νησιά Κόκος",
    "CO": "Κολομβία",
    "KM": "Ένωση των Κομορών",
    "CG": [
      "Δημοκρατία του Κονγκό",
      "Κονγκό"
    ],
    "CD": "Λαϊκή Δημοκρατία του Κονγκό",
    "CK": "Νήσοι Κουκ",
    "CR": "Κόστα Ρίκα",
    "CI": "Ακτή Ελεφαντοστού",
    "HR": "Κροατία",
    "CU": "Κούβα",
    "CY": "Κύπρος",
    "CZ": [
      "Τσεχική Δημοκρατία",
      "Τσεχία"
    ],
    "DK": "Δανία",
    "DJ": "Τζιμπουτί",
    "DM": [
      "Κοινοπολιτεία της Δομινίκας",
      "Ντομίνικα"
    ],
    "DO": "Δομινικανή Δημοκρατία",
    "EC": "Εκουαδόρ",
    "EG": "Αίγυπτος",
    "SV": "Ελ Σαλβαδόρ",
    "GQ": "Ισημερινή-Γουινέα",
    "ER": [
      "Κράτος της Ερυθραίας",
      "Ερυθραία"
    ],
    "EE": "Εσθονία",
    "ET": "Αιθιοπία",
    "FK": "Νήσοι Φώκλαντ (Μαλβίνας)",
    "FO": "Νήσοι Φερόες",
    "FJ": "Δημοκρατία των Φίτζι",
    "FI": "Φινλανδία",
    "FR": "Γαλλία",
    "GF": "Γαλλική Γουιάνα",
    "PF": "Γαλλική Πολυνησία",
    "TF": "Γαλλικά Νότια και Ανταρκτικά Εδάφη",
    "GA": "Γκαμπόν",
    "GM": "Γκάμπια",
    "GE": "Γεωργία",
    "DE": "Γερμανία",
    "GH": "Γκάνα",
    "GI": "Γιβραλτάρ",
    "GR": "Ελλάδα",
    "GL": "Γροιλανδία",
    "GD": "Γρενάδα",
    "GP": "Γουαδελούπη",
    "GU": "Γκουάμ",
    "GT": "Γουατεμάλα",
    "GN": "Γουινέα",
    "GW": "Γουινέα-Μπισσάου",
    "GY": "Γουιάνα",
    "HT": "Αϊτη",
    "HM": "Νήσοι Χερντ και Μακντόναλντ",
    "VA": [
      "Κράτος της Πόλης του Βατικανού",
      "Βατικανό"
    ],
    "HN": "Ονδούρα",
    "HK": "Χονγκ Κόνγκ",
    "HU": "Ουγγαρία",
    "IS": "Ισλανδία",
    "IN": "Ινδία",
    "ID": "Ινδονησία",
    "IR": [
      "Ισλαμική Δημοκρατία του Ιράν",
      "Ιράν"
    ],
    "IQ": "Ιράκ",
    "IE": "Ιρλανδία",
    "IL": "Ισραήλ",
    "IT": "Ιταλία",
    "JM": "Τζαμάικα",
    "JP": "Ιαπωνία",
    "JO": "Ιορδανία",
    "KZ": "Καζακστάν",
    "KE": "Κένυα",
    "KI": "Κιριμπάτι",
    "KP": [
      "Λαοκρατική Δημοκρατία της Κορέας",
      "Βόρεια Κορέα"
    ],
    "KR": [
      "Δημοκρατία της Κορέας",
      "Νότια Κορέα"
    ],
    "KW": "Κουβέιτ",
    "KG": "Κιργιζία",
    "LA": [
      "Λαϊκή Δημοκρατία του Λάος",
      "Λάος"
    ],
    "LV": "Λετονία",
    "LB": "Λίβανο",
    "LS": [
      "Βασίλειο του Λεσότο",
      "Λεσότο"
    ],
    "LR": "Λιβερία",
    "LY": [
      "Κράτος της Λιβύης",
      "Λιβύη"
    ],
    "LI": [
      "Πριγκιπάτο του Λίχτενσταϊν",
      "Λίχτενσταϊν"
    ],
    "LT": "Λιθουανία",
    "LU": "Λουξεμβούργο",
    "MO": "Μακάου",
    "MK": [
      "Δημοκρατία της Βόρειας Μακεδονίας",
      "Βόρεια Μακεδονία"
    ],
    "MG": "Μαδαγασκάρη",
    "MW": "Μαλάουι",
    "MY": "Μαλαισία",
    "MV": "Μαλβίδες",
    "ML": "Μαλί",
    "MT": "Μάλτα",
    "MH": "Νήσοι Μάρσαλ",
    "MQ": "Μαρτινίκα",
    "MR": "Μαυριτανία",
    "MU": "Μαυρίκιος",
    "YT": "Μαγιότ",
    "MX": "Μεξικό",
    "FM": [
      "Ομόσπονδες Πολιτείες της Μικρονησίας",
      "Μικρονησία"
    ],
    "MD": [
      "Δημοκρατία της Μολδαβίας",
      "Μολδαβία"
    ],
    "MC": [
      "Πριγκιπάτο του Μονακό",
      "Μονακό"
    ],
    "MN": "Μογγολία",
    "MS": "Μοντσερράτ",
    "MA": "Μαρόκο",
    "MZ": "Μοζαμβίκη",
    "MM": "Μιανμάρ",
    "NA": "Ναμίμπια",
    "NR": "Ναουρού",
    "NP": "Νεπάλ",
    "NL": "Ολλανδία",
    "NC": "Νέα Καληδονία",
    "NZ": "Νέα Ζηλανδία",
    "NI": "Νικαράγουα",
    "NE": "Νίγηρας",
    "NG": "Νιγηρία",
    "NU": "Νιούε",
    "NF": "Νησί Νόρφολκ",
    "MP": "Βόρειες Μαριάνες Νήσοι",
    "NO": "Νορβηγία",
    "OM": "Ομάν",
    "PK": "Πακιστάν",
    "PW": "Παλάου",
    "PS": [
      "Κράτος της Παλαιστίνης",
      "Παλαιστίνη"
    ],
    "PA": "Παναμάς",
    "PG": "Παπούα Νέα Γουινέα",
    "PY": "Παραγουάη",
    "PE": "Περού",
    "PH": "Φιλιππίνες",
    "PN": "Νήσοι Πίτκαιρν",
    "PL": "Πολωνία",
    "PT": "Πορτογαλία",
    "PR": "Πουέρτο Ρίκο",
    "QA": "Κατάρ",
    "RE": "Ρεϋνιόν",
    "RO": "Ρουμανία",
    "RU": [
      "Ρωσική Ομοσπονδία",
      "Ρωσία"
    ],
    "RW": "Ρουάντα",
    "SH": "Νήσος Αγίας Ελένης",
    "KN": "Ομοσπονδία Αγίου Χριστόφορου και Νέβις",
    "LC": "Αγία Λουκία",
    "PM": "Σαιν Πιερ και Μικελόν",
    "VC": "Άγιος Βικέντιος και Γρεναδίνες",
    "WS": "Σαμόα",
    "SM": "Άγιος Μαρίνος",
    "ST": "Σάο Τομέ και Πρίνσιπε",
    "SA": "Σαουδική Αραβία",
    "SN": "Σενεγάλη",
    "SC": "Σεϋχέλλες",
    "SL": "Σιέρα Λεόνε",
    "SG": "Σιγκαπούρη",
    "SK": "Σλοβακία",
    "SI": "Σλοβενία",
    "SB": "Νήσοι Σολομώντα",
    "SO": "Σομαλία",
    "ZA": "Νότια Αφρική",
    "GS": "Νότιος Γεωργία και Νότιοι Σάντουιτς Νήσοι",
    "ES": "Ισπανία",
    "LK": "Σρι Λάνκα",
    "SD": "Σουδάν",
    "SR": "Σουρινάμ",
    "SJ": "Σβάλμπαρντ και Γιαν Μαγιέν",
    "SZ": "Σουαζιλάνδη",
    "SE": "Σουηδία",
    "CH": "Ελβετία",
    "SY": [
      "Αραβική Δημοκρατία της Συρίας",
      "Συρία"
    ],
    "TW": "Δημοκρατία της Κίνας",
    "TJ": "Τατζικιστάν",
    "TZ": [
      "Ενωμένη Δημοκρατία της Τανζανίας",
      "Τανζανία"
    ],
    "TH": "Ταϊλάνδη",
    "TL": "Ανατολικό Τιμόρ",
    "TG": "Τογκό",
    "TK": "Τοκελάου",
    "TO": "Τόνγκα",
    "TT": "Τρινιντάντ και Τομπάγκο",
    "TN": "Τυνησία",
    "TR": "Τουρκία",
    "TM": "Τουρκμενιστάν",
    "TC": "Τερκς και Κέικος",
    "TV": "Τουβαλού",
    "UG": "Ουγκάντα",
    "UA": "Ουκρανια",
    "AE": "Ηνωμένα Αραβικά Εμιράτα",
    "GB": "Ηνωμένο Βασίλειο",
    "US": [
      "Ηνωμένες Πολιτείες Αμερικής",
      "Αμερική",
      "ΗΠΑ"
    ],
    "UM": "Απομακρυσμένες Νησίδες των Ηνωμένων Πολιτειών",
    "UY": "Ουρουγουάη",
    "UZ": "Ουζμπεκιστάν",
    "VU": "Βανουάτου",
    "VE": "Βενεζουέλα",
    "VN": "Βιετνάμ",
    "VG": "Βρετανικές Παρθένοι Νήσοι",
    "VI": "Αμερικανικές Παρθένοι Νήσοι",
    "WF": "Ουαλίς και Φουτουνά",
    "EH": "Δυτική Σαχάρα",
    "YE": "Υεμένη",
    "ZM": "Ζάμπια",
    "ZW": "Ζιμπάμπουε",
    "AX": "Νήσοι Ώλαντ",
    "BQ": "Μποναίρ, Άγιος Ευστάθιος και Σάμπα",
    "CW": "Κουρασάο",
    "GG": "Γκουέρνσεϊ",
    "IM": "Νήσος του Μαν",
    "JE": "Βαϊλάτο του Τζέρσεϊ",
    "ME": "Μαυροβούνιο",
    "BL": "Άγιος Βαρθολομαίος",
    "MF": "Άγιος Μαρτίνος (Γαλλία)",
    "RS": "Σερβία",
    "SX": "Άγιος Μαρτίνος (Ολλανδία)",
    "SS": "Νότιο Σουδάν",
    "XK": "Κόσοβο"
  }
}