export type ToastType = 'success' | 'error' | 'info';

export const toastTitleRecord: Record<ToastType, string> = {
  success: 'toastComponent.success',
  error: 'toastComponent.error',
  info: 'toastComponent.info',
};

export const toastIconRecord: Partial<Record<ToastType, string>> = {
  success: 'toast-success',
  error: 'toast-error',
};
