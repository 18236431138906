export const ROUTES = {
  signIn: 'sign-in',
  signUp: 'sign-up',
  signUpWebmaster: 'sign-up/webmaster',
  signUpAdvertiser: 'sign-up/advertiser',
  auth: 'auth',
  forgotPassword: 'forgot-password',
  createPassword: 'create-password',
  verifyEmail: 'verify-email',
};
