import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { map, Observable } from 'rxjs';

import { CommonMediaQueries } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class MediaScreenService {
  constructor(private _breakpointObserver: BreakpointObserver) {}

  mediaMatcher(breakpoint: CommonMediaQueries | number): Observable<boolean> {
    return this._breakpointObserver
      .observe(`(min-width: ${breakpoint}px)`)
      .pipe(map(({ matches }: BreakpointState) => matches));
  }

  isMatched(breakpoint: CommonMediaQueries | number, max?: boolean): boolean {
    return this._breakpointObserver.isMatched(
      max ? `(max-width: ${breakpoint}px)` : `(min-width: ${breakpoint}px)`,
    );
  }
}
