{
  "locale": "ky",
  "countries": {
    "AD": "Андорра",
    "AE": "Бириккен Араб Эмираттары",
    "AF": "Афганистан",
    "AG": "Антигуа жана Барбуда",
    "AI": "Ангуила",
    "AL": "Албания",
    "AM": "Армения",
    "AO": "Ангола",
    "AQ": "Антарктика",
    "AR": "Аргентина",
    "AS": "Америка Самоасы",
    "AT": "Австрия",
    "AU": "Австралия",
    "AW": "Аруба",
    "AX": "Аланд аралдары",
    "AZ": "Азербайжан",
    "BA": "Босния жана Герцеговина",
    "BB": "Барбадос",
    "BD": "Бангладеш",
    "BE": "Бельгия",
    "BF": "Буркина-Фасо",
    "BG": "Болгария",
    "BH": "Бахрейн",
    "BI": "Бурунди",
    "BJ": "Бенин",
    "BL": "Сент Бартелеми",
    "BM": "Бермуд аралдары",
    "BN": "Бруней",
    "BO": "Боливия",
    "BQ": "Кариб Нидерланддары",
    "BR": "Бразилия",
    "BS": "Багам аралдары",
    "BT": "Бутан",
    "BV": "Буве аралдары",
    "BW": "Ботсвана",
    "BY": "Беларусь",
    "BZ": "Белиз",
    "CA": "Канада",
    "CC": "Кокос (Килиӊ) аралдары",
    "CD": "Конго-Киншаса",
    "CF": "Борбордук Африка Республикасы",
    "CG": "Конго-Браззавил",
    "CH": "Швейцария",
    "CI": "Кот-д’Ивуар",
    "CK": "Кук аралдары",
    "CL": "Чили",
    "CM": "Камерун",
    "CN": "Кытай",
    "CO": "Колумбия",
    "CR": "Коста-Рика",
    "CU": "Куба",
    "CV": "Капе Верде",
    "CW": "Кюрасао",
    "CX": "Крисмас аралы",
    "CY": "Кипр",
    "CZ": "Чехия",
    "DE": "Германия",
    "DJ": "Джибути",
    "DK": "Дания",
    "DM": "Доминика",
    "DO": "Доминика Республикасы",
    "DZ": "Алжир",
    "EC": "Эквадор",
    "EE": "Эстония",
    "EG": "Египет",
    "EH": "Батыш Сахара",
    "ER": "Эритрея",
    "ES": "Испания",
    "ET": "Эфиопия",
    "FI": "Финляндия",
    "FJ": "Фиджи",
    "FK": "Фолклэнд аралдары",
    "FM": "Микронезия",
    "FO": "Фарер аралдары",
    "FR": "Франция",
    "GA": "Габон",
    "GB": "Улуу Британия",
    "GD": "Гренада",
    "GE": "Грузия",
    "GF": "Гвиана (Франция)",
    "GG": "Гернси",
    "GH": "Гана",
    "GI": "Гибралтар",
    "GL": "Гренландия",
    "GM": "Гамбия",
    "GN": "Гвинея",
    "GP": "Гваделупа",
    "GQ": "Экваториалдык Гвинея",
    "GR": "Греция",
    "GS": "Түштүк Жоржия жана Түштүк Сэндвич аралдары",
    "GT": "Гватемала",
    "GU": "Гуам",
    "GW": "Гвинея-Бисау",
    "GY": "Гайана",
    "HK": "Гонконг Кытай ААА",
    "HM": "Херд жана Макдоналд аралдары",
    "HN": "Гондурас",
    "HR": "Хорватия",
    "HT": "Гаити",
    "HU": "Венгрия",
    "ID": "Индонезия",
    "IE": "Ирландия",
    "IL": "Израиль",
    "IM": "Мэн аралы",
    "IN": "Индия",
    "IO": "Британиянын Индия океанындагы аймагы",
    "IQ": "Ирак",
    "IR": "Иран",
    "IS": "Исландия",
    "IT": "Италия",
    "JE": "Жерси",
    "JM": "Ямайка",
    "JO": "Иордания",
    "JP": "Япония",
    "KE": "Кения",
    "KG": "Кыргызстан",
    "KH": "Камбоджа",
    "KI": "Кирибати",
    "KM": "Коморос",
    "KN": "Сент-Китс жана Невис",
    "KP": "Түндүк Корея",
    "KR": "Түштүк Корея",
    "KW": "Кувейт",
    "KY": "Кайман Аралдары",
    "KZ": "Казакстан",
    "LA": "Лаос",
    "LB": "Ливан",
    "LC": "Сент-Люсия",
    "LI": "Лихтенштейн",
    "LK": "Шри-Ланка",
    "LR": "Либерия",
    "LS": "Лесото",
    "LT": "Литва",
    "LU": "Люксембург",
    "LV": "Латвия",
    "LY": "Ливия",
    "MA": "Марокко",
    "MC": "Монако",
    "MD": "Молдова",
    "ME": "Черногория",
    "MF": "Сент-Мартин",
    "MG": "Мадагаскар",
    "MH": "Маршалл аралдары",
    "MK": "Түндүк Македония",
    "ML": "Мали",
    "MM": "Мьянма (Бирма)",
    "MN": "Монголия",
    "MO": "Макау Кытай ААА",
    "MP": "Түндүк Мариана аралдары",
    "MQ": "Мартиника",
    "MR": "Мавритания",
    "MS": "Монсеррат",
    "MT": "Мальта",
    "MU": "Маврикий",
    "MV": "Малдив аралдары",
    "MW": "Малави",
    "MX": "Мексика",
    "MY": "Малайзия",
    "MZ": "Мозамбик",
    "NA": "Намибия",
    "NC": "Жаӊы Каледония",
    "NE": "Нигер",
    "NF": "Норфолк аралы",
    "NG": "Нигерия",
    "NI": "Никарагуа",
    "NL": "Нидерланддар",
    "NO": "Норвегия",
    "NP": "Непал",
    "NR": "Науру",
    "NU": "Ниуэ",
    "NZ": "Жаӊы Зеландия",
    "OM": "Оман",
    "PA": "Панама",
    "PE": "Перу",
    "PF": "Француз Полинезиясы",
    "PG": "Папуа Жаңы-Гвинея",
    "PH": "Филлипин",
    "PK": "Пакистан",
    "PL": "Польша",
    "PM": "Сен-Пьер жана Микелон",
    "PN": "Питкэрн аралдары",
    "PR": "Пуэрто-Рико",
    "PS": "Палестина аймактары",
    "PT": "Португалия",
    "PW": "Палау",
    "PY": "Парагвай",
    "QA": "Катар",
    "RE": "Реюнион",
    "RO": "Румыния",
    "RS": "Сербия",
    "RU": "Россия",
    "RW": "Руанда",
    "SA": "Сауд Арабиясы",
    "SB": "Соломон аралдары",
    "SC": "Сейшелдер",
    "SD": "Судан",
    "SE": "Швеция",
    "SG": "Сингапур",
    "SH": "Ыйык Елена",
    "SI": "Словения",
    "SJ": "Свалбард жана Жан Майен",
    "SK": "Словакия",
    "SL": "Сьерра-Леоне",
    "SM": "Сан Марино",
    "SN": "Сенегал",
    "SO": "Сомали",
    "SR": "Суринаме",
    "SS": "Түштүк Судан",
    "ST": "Сан-Томе жана Принсипи",
    "SV": "Эл Салвадор",
    "SX": "Синт Маартен",
    "SY": "Сирия",
    "SZ": "Свазиленд",
    "TC": "Түркс жана Кайкос аралдары",
    "TD": "Чад",
    "TF": "Франциянын Түштүктөгү аймактары",
    "TG": "Того",
    "TH": "Таиланд",
    "TJ": "Тажикстан",
    "TK": "Токелау",
    "TL": "Тимор-Лесте",
    "TM": "Түркмөнстан",
    "TN": "Тунис",
    "TO": "Тонга",
    "TR": "Түркия",
    "TT": "Тринидад жана Тобаго",
    "TV": "Тувалу",
    "TW": "Тайвань",
    "TZ": "Танзания",
    "UA": "Украина",
    "UG": "Уганда",
    "UM": "АКШнын сырткы аралдары",
    "US": "Америка Кошмо Штаттары",
    "UY": "Уругвай",
    "UZ": "Өзбекстан",
    "VA": "Ватикан",
    "VC": "Сент-Винсент жана Гренадиналар",
    "VE": "Венесуэла",
    "VG": "Виргин аралдары (Британия)",
    "VI": "Виргин аралдары (АКШ)",
    "VN": "Вьетнам",
    "VU": "Вануату",
    "WF": "Уоллис жана Футуна",
    "WS": "Самоа",
    "XK": "Косово",
    "YE": "Йемен",
    "YT": "Майотта",
    "ZA": "Түштүк Африка Республикасы",
    "ZM": "Замбия",
    "ZW": "Зимбабве"
  }
}
