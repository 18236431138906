{
  "locale": "bs",
  "countries": {
    "AD": "Andora",
    "AE": "Ujedinjeni Arapski Emirati",
    "AF": "Afganistan",
    "AG": "Antigva i Barbuda",
    "AI": "Angvila",
    "AL": "Albanija",
    "AM": "Armenija",
    "AO": "Angola",
    "AQ": "Antarktika",
    "AR": "Argentina",
    "AS": "Američka Samoa",
    "AT": "Austrija",
    "AU": "Australija",
    "AW": "Aruba",
    "AX": "Olandska Ostrva",
    "AZ": "Azerbejdžan",
    "BA": "Bosna i Hercegovina",
    "BB": "Barbados",
    "BD": "Bangladeš",
    "BE": "Belgija",
    "BF": "Burkina Faso",
    "BG": "Bugarska",
    "BH": "Bahrein",
    "BI": "Burundi",
    "BJ": "Benin",
    "BL": "Sveti Bartolomej",
    "BM": "Bermuda",
    "BN": "Brunej",
    "BO": "Bolivija",
    "BQ": "Karipska Holandija",
    "BR": "Brazil",
    "BS": "Bahami",
    "BT": "Butan",
    "BV": "Ostrvo Buve",
    "BW": "Bocvana",
    "BY": "Bjelorusija",
    "BZ": "Belize",
    "CA": "Kanada",
    "CC": "Kokosova (Kilingova) Ostrva",
    "CD": "Demokratska Republika Kongo",
    "CF": "Centralnoafrička Republika",
    "CG": "Kongo",
    "CH": "Švicarska",
    "CI": "Obala Slonovače",
    "CK": "Kukova Ostrva",
    "CL": "Čile",
    "CM": "Kamerun",
    "CN": "Kina",
    "CO": "Kolumbija",
    "CR": "Kostarika",
    "CU": "Kuba",
    "CV": "Kape Verde",
    "CW": "Kurasao",
    "CX": "Božićna Ostrva",
    "CY": "Kipar",
    "CZ": "Češka",
    "DE": "Njemačka",
    "DJ": "Džibuti",
    "DK": "Danska",
    "DM": "Dominika",
    "DO": "Dominikanska Republika",
    "DZ": "Alžir",
    "EC": "Ekvador",
    "EE": "Estonija",
    "EG": "Egipat",
    "EH": "Zapadna Sahara",
    "ER": "Eritreja",
    "ES": "Španija",
    "ET": "Etiopija",
    "FI": "Finska",
    "FJ": "Fidži",
    "FK": "Folklandska Ostrva",
    "FM": "Mikronezija",
    "FO": "Farska Ostrva",
    "FR": "Francuska",
    "GA": "Gabon",
    "GB": "Velika Britanija",
    "GD": "Grenada",
    "GE": "Gruzija",
    "GF": "Francuska Gvajana",
    "GG": "Gernzi",
    "GH": "Gana",
    "GI": "Gibraltar",
    "GL": "Grenland",
    "GM": "Gambija",
    "GN": "Gvineja",
    "GP": "Gvadalupe",
    "GQ": "Ekvatorijalna Gvineja",
    "GR": "Grčka",
    "GS": "Južna Džordžija i Južna Sendvička Ostrva",
    "GT": "Gvatemala",
    "GU": "Guam",
    "GW": "Gvineja-Bisao",
    "GY": "Gvajana",
    "HK": "Hong Kong (SAR Kina)",
    "HM": "Herd i arhipelag MekDonald",
    "HN": "Honduras",
    "HR": "Hrvatska",
    "HT": "Haiti",
    "HU": "Mađarska",
    "ID": "Indonezija",
    "IE": "Irska",
    "IL": "Izrael",
    "IM": "Ostrvo Man",
    "IN": "Indija",
    "IO": "Britanska Teritorija u Indijskom Okeanu",
    "IQ": "Irak",
    "IR": "Iran",
    "IS": "Island",
    "IT": "Italija",
    "JE": "Džerzi",
    "JM": "Jamajka",
    "JO": "Jordan",
    "JP": "Japan",
    "KE": "Kenija",
    "KG": "Kirgistan",
    "KH": "Kambodža",
    "KI": "Kiribati",
    "KM": "Komorska Ostrva",
    "KN": "Sveti Kits i Nevis",
    "KP": "Sjeverna Koreja",
    "KR": "Južna Koreja",
    "KW": "Kuvajt",
    "KY": "Kajmanska Ostrva",
    "KZ": "Kazahstan",
    "LA": "Laos",
    "LB": "Liban",
    "LC": "Sveta Lucija",
    "LI": "Lihtenštajn",
    "LK": "Šri Lanka",
    "LR": "Liberija",
    "LS": "Lesoto",
    "LT": "Litvanija",
    "LU": "Luksemburg",
    "LV": "Latvija",
    "LY": "Libija",
    "MA": "Maroko",
    "MC": "Monako",
    "MD": "Moldavija",
    "ME": "Crna Gora",
    "MF": "Sv. Martin",
    "MG": "Madagaskar",
    "MH": "Maršalova Ostrva",
    "MK": "Sjeverna Makedonija",
    "ML": "Mali",
    "MM": "Mijanmar",
    "MN": "Mongolija",
    "MO": "Makao (SAR Kina)",
    "MP": "Sjeverna Marijanska Ostrva",
    "MQ": "Martinik",
    "MR": "Mauritanija",
    "MS": "Monserat",
    "MT": "Malta",
    "MU": "Mauricijus",
    "MV": "Maldivi",
    "MW": "Malavi",
    "MX": "Meksiko",
    "MY": "Malezija",
    "MZ": "Mozambik",
    "NA": "Namibija",
    "NC": "Nova Kaledonija",
    "NE": "Niger",
    "NF": "Ostrvo Norfolk",
    "NG": "Nigerija",
    "NI": "Nikaragva",
    "NL": "Holandija",
    "NO": "Norveška",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Novi Zeland",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Francuska Polinezija",
    "PG": "Papua Nova Gvineja",
    "PH": "Filipini",
    "PK": "Pakistan",
    "PL": "Poljska",
    "PM": "Sveti Petar i Mikelon",
    "PN": "Pitkernska Ostrva",
    "PR": "Porto Riko",
    "PS": "Palestinska Teritorija",
    "PT": "Portugal",
    "PW": "Palau",
    "PY": "Paragvaj",
    "QA": "Katar",
    "RE": "Reunion",
    "RO": "Rumunija",
    "RS": "Srbija",
    "RU": "Rusija",
    "RW": "Ruanda",
    "SA": "Saudijska Arabija",
    "SB": "Solomonska Ostrva",
    "SC": "Sejšeli",
    "SD": "Sudan",
    "SE": "Švedska",
    "SG": "Singapur",
    "SH": "Sveta Helena",
    "SI": "Slovenija",
    "SJ": "Svalbard i Jan Majen",
    "SK": "Slovačka",
    "SL": "Sijera Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SO": "Somalija",
    "SR": "Surinam",
    "SS": "Južni Sudan",
    "ST": "Sao Tome i Principe",
    "SV": "Salvador",
    "SX": "Sint Marten",
    "SY": "Sirija",
    "SZ": "Svazilend",
    "TC": "Ostrva Turks i Kaikos",
    "TD": "Čad",
    "TF": "Francuske Južne Teritorije",
    "TG": "Togo",
    "TH": "Tajland",
    "TJ": "Tadžikistan",
    "TK": "Tokelau",
    "TL": "Istočni Timor",
    "TM": "Turkmenistan",
    "TN": "Tunis",
    "TO": "Tonga",
    "TR": "Turska",
    "TT": "Trinidad i Tobago",
    "TV": "Tuvalu",
    "TW": "Tajvan",
    "TZ": "Tanzanija",
    "UA": "Ukrajina",
    "UG": "Uganda",
    "UM": "Američka Vanjska Ostrva",
    "US": "Sjedinjene Američke Države",
    "UY": "Urugvaj",
    "UZ": "Uzbekistan",
    "VA": "Vatikan",
    "VC": "Sveti Vinsent i Grenadin",
    "VE": "Venecuela",
    "VG": "Britanska Djevičanska Ostrva",
    "VI": "Američka Djevičanska Ostrva",
    "VN": "Vijetnam",
    "VU": "Vanuatu",
    "WF": "Ostrva Valis i Futuna",
    "WS": "Samoa",
    "XK": "Kosovo",
    "YE": "Jemen",
    "YT": "Majote",
    "ZA": "Južnoafrička Republika",
    "ZM": "Zambija",
    "ZW": "Zimbabve"
  }
}
