export const EmailPattern =
  /^([-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*|"(?:[!#-[\]-~]|\\[!-~])*")@((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z0-9-]{2,63}(?<!-)))$/i;
export const LinkPattern =
  /^(https?:\/\/)([a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)\.(([a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)\.)?([a-zA-Z]{2,})$/;
export const MaxLength = 128;
export const MinNumberLength = 0;
export const MaxNumberLength = 100;
export const passwordMinLength = 10;
export const loweCasePattern = /[a-z]/;
export const upperCasePattern = /[A-Z]/;
export const numbersPattern = /\d/;
export const specialCharactersPattern = /[!@#$%^&_-]/;
