{
  "locale": "am",
  "countries": {
    "AF": "አፍጋኒስታን",
    "AL": "አልባኒያ",
    "DZ": "አልጄሪያ",
    "AS": "የአሜሪካ ሳሞአ",
    "AD": "አንዶራ",
    "AO": "አንጐላ",
    "AI": "አንጉኢላ",
    "AQ": "አንታርክቲካ",
    "AG": "አንቲጓ እና ባሩዳ",
    "AR": "አርጀንቲና",
    "AM": "አርሜኒያ",
    "AW": "አሩባ",
    "AU": "አውስትራልያ",
    "AT": "ኦስትሪያ",
    "AZ": "አዘርባጃን",
    "BS": "ባሃማስ",
    "BH": "ባህሬን",
    "BD": "ባንግላዲሽ",
    "BB": "ባርቤዶስ",
    "BY": "ቤላሩስ",
    "BE": "ቤልጄም",
    "BZ": "ቤሊዘ",
    "BJ": "ቤኒን",
    "BM": "ቤርሙዳ",
    "BT": "ቡህታን",
    "BO": "ቦሊቪያ",
    "BA": "ቦስኒያ እና ሄርዞጎቪኒያ",
    "BW": "ቦትስዋና",
    "BV": "ቡቬት ደሴት",
    "BR": "ብራዚል",
    "IO": "የብሪታኒያ ህንድ ውቂያኖስ ግዛት",
    "BN": "ብሩኒ",
    "BG": "ቡልጌሪያ",
    "BF": "ቡርኪና ፋሶ",
    "BI": "ብሩንዲ",
    "KH": "ካምቦዲያ",
    "CM": "ካሜሩን",
    "CA": "ካናዳ",
    "CV": "ኬፕ ቬርዴ",
    "KY": "ካይማን ደሴቶች",
    "CF": "የመካከለኛው አፍሪካ ሪፐብሊክ",
    "TD": "ቻድ",
    "CL": "ቺሊ",
    "CN": "ቻይና",
    "CX": "የገና ደሴት",
    "CC": "ኮኮስ(ኬሊንግ) ደሴቶች",
    "CO": "ኮሎምቢያ",
    "KM": "ኮሞሮስ",
    "CG": "ኮንጎ ብራዛቪል",
    "CD": "ኮንጎ-ኪንሻሳ",
    "CK": "ኩክ ደሴቶች",
    "CR": "ኮስታ ሪካ",
    "CI": "ኮት ዲቯር",
    "HR": "ክሮኤሽያ",
    "CU": "ኩባ",
    "CY": "ሳይፕረስ",
    "CZ": "ቼክ ሪፑብሊክ",
    "DK": "ዴንማርክ",
    "DJ": "ጂቡቲ",
    "DM": "ዶሚኒካ",
    "DO": "ዶሚኒክ ሪፑብሊክ",
    "EC": "ኢኳዶር",
    "EG": "ግብጽ",
    "SV": "ኤል ሳልቫዶር",
    "GQ": "ኢኳቶሪያል ጊኒ",
    "ER": "ኤርትራ",
    "EE": "ኤስቶኒያ",
    "ET": "ኢትዮጵያ",
    "FK": "የፎክላንድ ደሴቶች",
    "FO": "የፋሮ ደሴቶች",
    "FJ": "ፊጂ",
    "FI": "ፊንላንድ",
    "FR": "ፈረንሳይ",
    "GF": "የፈረንሳይ ጉዊአና",
    "PF": "የፈረንሳይ ፖሊኔዢያ",
    "TF": "የፈረንሳይ ደቡባዊ ግዛቶች",
    "GA": "ጋቦን",
    "GM": "ጋምቢያ",
    "GE": "ጆርጂያ",
    "DE": "ጀርመን",
    "GH": "ጋና",
    "GI": "ጂብራልተር",
    "GR": "ግሪክ",
    "GL": "ግሪንላንድ",
    "GD": "ግሬናዳ",
    "GP": "ጉዋደሉፕ",
    "GU": "ጉዋም",
    "GT": "ጉዋቲማላ",
    "GN": "ጊኒ",
    "GW": "ጊኒ ቢሳኦ",
    "GY": "ጉያና",
    "HT": "ሀይቲ",
    "HM": "ኽርድ ደሴቶችና ማክዶናልድ ደሴቶች",
    "VA": "ቫቲካን ከተማ",
    "HN": "ሆንዱራስ",
    "HK": "ሆንግ ኮንግ SAR ቻይና",
    "HU": "ሀንጋሪ",
    "IS": "አይስላንድ",
    "IN": "ህንድ",
    "ID": "ኢንዶኔዢያ",
    "IR": "ኢራን",
    "IQ": "ኢራቅ",
    "IE": "አየርላንድ",
    "IL": "እስራኤል",
    "IT": "ጣሊያን",
    "JM": "ጃማይካ",
    "JP": "ጃፓን",
    "JO": "ጆርዳን",
    "KZ": "ካዛኪስታን",
    "KE": "ኬንያ",
    "KI": "ኪሪባቲ",
    "KP": "ሰሜን ኮሪያ",
    "KR": "ደቡብ ኮሪያ",
    "KW": "ክዌት",
    "KG": "ኪርጊስታን",
    "LA": "ላኦስ",
    "LV": "ላትቪያ",
    "LB": "ሊባኖስ",
    "LS": "ሌሶቶ",
    "LR": "ላይቤሪያ",
    "LY": "ሊቢያ",
    "LI": "ሊችተንስታይን",
    "LT": "ሊቱዌኒያ",
    "LU": "ሉክሰምበርግ",
    "MO": "ማካኡ ልዩ የአስተዳደር ክልል ቻይና",
    "MG": "ማዳጋስካር",
    "MW": "ማላዊ",
    "MY": "ማሌዢያ",
    "MV": "ማልዲቭስ",
    "ML": "ማሊ",
    "MT": "ማልታ",
    "MH": "ማርሻል አይላንድ",
    "MQ": "ማርቲኒክ",
    "MR": "ሞሪቴኒያ",
    "MU": "ሞሪሸስ",
    "YT": "ሜይኦቴ",
    "MX": "ሜክሲኮ",
    "FM": "ሚክሮኔዢያ",
    "MD": "ሞልዶቫ",
    "MC": "ሞናኮ",
    "MN": "ሞንጎሊያ",
    "MS": "ሞንትሴራት",
    "MA": "ሞሮኮ",
    "MZ": "ሞዛምቢክ",
    "MM": "ማይናማር(በርማ)",
    "NA": "ናሚቢያ",
    "NR": "ናኡሩ",
    "NP": "ኔፓል",
    "NL": "ኔዘርላንድ",
    "NC": "ኒው ካሌዶኒያ",
    "NZ": "ኒው ዚላንድ",
    "NI": "ኒካራጓ",
    "NE": "ኒጀር",
    "NG": "ናይጄሪያ",
    "NU": "ኒኡይ",
    "NF": "ኖርፎልክ ደሴት",
    "MK": "መቄዶንያ",
    "MP": "የሰሜናዊ ማሪያና ደሴቶች",
    "NO": "ኖርዌ",
    "OM": "ኦማን",
    "PK": "ፓኪስታን",
    "PW": "ፓላው",
    "PS": "የፍልስጤም ግዛት",
    "PA": "ፓናማ",
    "PG": "ፓፑዋ ኒው ጊኒ",
    "PY": "ፓራጓይ",
    "PE": "ፔሩ",
    "PH": "ፊሊፒንስ",
    "PN": "ፒትካኢርን አይስላንድ",
    "PL": "ፖላንድ",
    "PT": "ፖርቱጋል",
    "PR": "ፖርታ ሪኮ",
    "QA": "ኳታር",
    "RE": "ሪዩኒየን",
    "RO": "ሮሜኒያ",
    "RU": "ራሺያ",
    "RW": "ሩዋንዳ",
    "SH": "ሴንት ሄለና",
    "KN": "ቅዱስ ኪትስ እና ኔቪስ",
    "LC": "ሴንት ሉቺያ",
    "PM": "ቅዱስ ፒዬር እና ሚኩኤሎን",
    "VC": "ቅዱስ ቪንሴንት እና ግሬናዲንስ",
    "WS": "ሳሞአ",
    "SM": "ሳን ማሪኖ",
    "ST": "ሳኦ ቶሜ እና ፕሪንሲፔ",
    "SA": "ሳውድአረቢያ",
    "SN": "ሴኔጋል",
    "SC": "ሲሼልስ",
    "SL": "ሴራሊዮን",
    "SG": "ሲንጋፖር",
    "SK": "ስሎቫኪያ",
    "SI": "ስሎቬኒያ",
    "SB": "ሰሎሞን ደሴት",
    "SO": "ሱማሌ",
    "ZA": "ደቡብ አፍሪካ",
    "GS": "ደቡብ ጆርጂያ እና የደቡብ ሳንድዊች ደሴቶች",
    "ES": "ስፔን",
    "LK": "ሲሪላንካ",
    "SD": "ሱዳን",
    "SR": "ሱሪናም",
    "SJ": "ስቫልባርድ እና ጃን ማየን",
    "SZ": "ሱዋዚላንድ",
    "SE": "ስዊድን",
    "CH": "ስዊዘርላንድ",
    "SY": "ሲሪያ",
    "TW": "ታይዋን",
    "TJ": "ታጃኪስታን",
    "TZ": "ታንዛኒያ",
    "TH": "ታይላንድ",
    "TL": "ምስራቅ ሌስት",
    "TG": "ቶጐ",
    "TK": "ቶክላው",
    "TO": "ቶንጋ",
    "TT": "ትሪናዳድ እና ቶቤጎ",
    "TN": "ቱኒዚያ",
    "TR": "ቱርክ",
    "TM": "ቱርክሜኒስታን",
    "TC": "የቱርኮችና የካኢኮስ ደሴቶች",
    "TV": "ቱቫሉ",
    "UG": "ዩጋንዳ",
    "UA": "ዩክሬን",
    "AE": "የተባበሩት አረብ ኤምሬትስ",
    "GB": "እንግሊዝ",
    "US": "ዩናይትድ ስቴትስ",
    "UM": "የዩ ኤስ ጠረፍ ላይ ያሉ ደሴቶች",
    "UY": "ኡራጓይ",
    "UZ": "ኡዝቤኪስታን",
    "VU": "ቫኑአቱ",
    "VE": "ቬንዙዌላ",
    "VN": "ቬትናም",
    "VG": "የእንግሊዝ ቨርጂን ደሴቶች",
    "VI": "የአሜሪካ ቨርጂን ደሴቶች",
    "WF": "ዋሊስ እና ፉቱና ደሴቶች",
    "EH": "ምዕራባዊ ሳህራ",
    "YE": "የመን",
    "ZM": "ዛምቢያ",
    "ZW": "ዚምቧቤ",
    "AX": "የአላንድ ደሴቶች",
    "BQ": "የካሪቢያን ኔዘርላንድስ",
    "CW": "ኩራሳዎ",
    "GG": "ጉርነሲ",
    "IM": "አይል ኦፍ ማን",
    "JE": "ጀርሲ",
    "ME": "ሞንተኔግሮ",
    "BL": "ቅዱስ በርቴሎሜ",
    "MF": "ሴንት ማርቲን",
    "RS": "ሰርብያ",
    "SX": "ሲንት ማርተን",
    "SS": "ደቡብ ሱዳን",
    "XK": "ኮሶቮ"
  }
}
