import { Languages, Environment } from '@shared/models';

export const environment: Environment = {
  production: true,
  defaultLanguage: Languages.EN,
  apiHost: 'https://user-gateway.rpartners-dev.com/api/v1',
  useRecaptcha: false,
  recaptchaSiteKey: '',
  webmasterCabinetUrl: 'https://webmaster.rpartners-dev.com',
  advertiserCabinetUrl: 'https://advertiser.rpartners-dev.com',
};
