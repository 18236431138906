{
  "locale": "ur",
  "countries": {
    "AF": "افغانستان",
    "AL": "البانیاہ",
    "DZ": "الجزائر",
    "AS": "امریکی ساموا",
    "AD": "اینڈورا",
    "AO": "انگولا",
    "AI": "انگویلا",
    "AQ": "انٹارکٹیکا",
    "AG": "انٹیگوا اور باربودا",
    "AR": "ارجنٹینا",
    "AM": "آرمینیا",
    "AW": "اروبا",
    "AU": "آسٹریلیا",
    "AT": "آسٹریا",
    "AZ": "آزربائیجان",
    "BS": "بہاماس",
    "BH": "بحرین",
    "BD": "بنگلہ دیش",
    "BB": "بارباڈوس",
    "BY": "بیلاروس",
    "BE": "بیلجیم",
    "BZ": "بیلیز",
    "BJ": "بینن",
    "BM": "برمودا",
    "BT": "بھوٹان",
    "BO": "بولیویا",
    "BA": "بوسنیا اور ہرزیگوینا",
    "BW": "بوٹسوانا",
    "BV": "جزیرہ بوویت",
    "BR": "برازیل",
    "IO": "بحرِہندکابرطانوی حصہ",
    "BN": "برونائی دارالسلام",
    "BG": "بلغاریہ",
    "BF": "برکینا فاسو",
    "BI": "برونڈی",
    "KH": "کمبوڈیا",
    "CM": "کیمرون",
    "CA": "کینیڈا",
    "CV": "کیپ وردے",
    "KY": "جزائر کیمن",
    "CF": "وسطی افریقی جمہوریہ",
    "TD": "چاڈ",
    "CL": "چلی",
    "CN": "چین",
    "CX": "کرسمس آئ لینڈ",
    "CC": "کوکوس جزائر",
    "CO": "کولمبیا",
    "KM": "کوموروس",
    "CG": "کانگو",
    "CD": "عوامی جمہوریہِ کانگو",
    "CK": "کوک آیلینڈ",
    "CR": "کوسٹا ریکا",
    "CI": "کوٹ ڈی آئیورائر",
    "HR": "کروشیا",
    "CU": "کیوبا",
    "CY": "قبرص",
    "CZ": "جمہوریہ چیک",
    "DK": "ڈنمارک",
    "DJ": "جبوتی",
    "DM": "ڈومینیکا",
    "DO": "ڈومینیکن ریپبلک",
    "EC": "ایکواڈور",
    "EG": "مصر",
    "SV": "ال سلواڈور",
    "GQ": "استوائی گنی",
    "ER": "ایریٹریا",
    "EE": "ایسٹونیا",
    "ET": "ایتھوپیا",
    "FK": "جزائر فاک لینڈ (مالویناس)",
    "FO": "جزائرفارو",
    "FJ": "فجی",
    "FI": "فن لینڈ",
    "FR": "فرانس",
    "GF": "فرانسیسی گانا",
    "PF": "فرانسیسی پولینیشیا",
    "TF": "جنوبی فرانسیسی علاقہ جات",
    "GA": "گبون",
    "GM": "گیمبیا",
    "GE": "جارجیا",
    "DE": "جرمنی",
    "GH": "گھانا",
    "GI": "جبل الطارق",
    "GR": "یونان",
    "GL": "گرین لینڈ",
    "GD": "گریناڈا",
    "GP": "گواڈیلوپ",
    "GU": "گوام",
    "GT": "گوئٹے مالا",
    "GN": "گنی",
    "GW": "گنی بساؤ",
    "GY": "گیوانا",
    "HT": "ہیٹی",
    "HM": "ہرڈ جزیرہ اور جزائر مکڈونلڈ",
    "VA": "ہولی سی، ویٹیکن",
    "HN": "ہنڈورس",
    "HK": "ہانگ کانگ",
    "HU": "ہنگری",
    "IS": "آئس لینڈ",
    "IN": "انڈیا",
    "ID": "انڈونیشیا",
    "IR": "اسلامی جمہوریہ ایران",
    "IQ": "عراق",
    "IE": "آئر لینڈ",
    "IL": "اسرائیل",
    "IT": "اٹلی",
    "JM": "جمیکا",
    "JP": "جاپان",
    "JO": "اردن",
    "KZ": "قازقستان",
    "KE": "کینیا",
    "KI": "کرباتی",
    "KP": "شمالی کوریا",
    "KR": "جنوبی کوریا",
    "KW": "کویت",
    "KG": "کرغزستان",
    "LA": "عوامی جمہوریہِ لاوء",
    "LV": "لیٹویا",
    "LB": "لبنان",
    "LS": "لیسوتھو",
    "LR": "لائبیریا",
    "LY": "لیبیا",
    "LI": "لیچسٹنسٹین",
    "LT": "لیتھوانیا",
    "LU": "لکسمبرگ",
    "MO": "مکاؤ",
    "MG": "مڈغاسکر",
    "MW": "ملاوی",
    "MY": "ملائیشیا",
    "MV": "مالدیپ",
    "ML": "مالی",
    "MT": "مالٹا",
    "MH": "مارشل جزائر",
    "MQ": "مارٹنیک",
    "MR": "موریطانیہ",
    "MU": "ماریشس",
    "YT": "میٹو",
    "MX": "میکسیکو",
    "FM": "مائیکرونیشیا",
    "MD": "جمہوریہ مالڈووا",
    "MC": "موناکو",
    "MN": "منگولیا",
    "MS": "مونٹسیریٹ",
    "MA": "مراکش",
    "MZ": "موزمبیق",
    "MM": "میانمار",
    "NA": "نامیبیا",
    "NR": "نورو",
    "NP": "نیپال",
    "NL": "نیدرلینڈ",
    "NC": "نیو کالیڈونیا",
    "NZ": "نیوزی لینڈ",
    "NI": "نکاراگوا",
    "NE": "نائجر",
    "NG": "نائجیریا",
    "NU": "نییو",
    "NF": "جزیرہ نورفوک",
    "MP": "شمالی ماریانا جزائر",
    "MK": "شمالی مقدونیہ",
    "NO": "ناروے",
    "OM": "عمان",
    "PK": "پاکستان",
    "PW": "پالاؤ",
    "PS": "فلسطین",
    "PA": "پاناما",
    "PG": "پاپوا نیو گنی",
    "PY": "پیراگوئے",
    "PE": "پیرو",
    "PH": "فلپائن",
    "PN": "پٹکیرن",
    "PL": "پولینڈ",
    "PT": "پرتگال",
    "PR": "پورٹو ریکو",
    "QA": "قطر",
    "RE": "ری یونین",
    "RO": "رومانیہ",
    "RU": "روس",
    "RW": "روانڈا",
    "SH": "سینٹ ہیلینا",
    "KN": "سینٹ کٹس اور نیویس",
    "LC": "سینٹ لوسیا",
    "PM": "سینٹ پیئر و میکوئیلون",
    "VC": "سینٹ ونسنٹ اور گریناڈینز",
    "WS": "ساموآ",
    "SM": "سان مارینو",
    "ST": "ساؤ ٹوم اور پرنسپے",
    "SA": "سعودی عرب",
    "SN": "سینیگال",
    "SC": "سیچیلز",
    "SL": "سیرا لیون",
    "SG": "سنگاپور",
    "SK": "سلوواکیا",
    "SI": "سلووینیا",
    "SB": "جزائرِ سولومن",
    "SO": "صومالیہ",
    "ZA": "جنوبی افریقہ",
    "GS": "جنوبی جارجیا اور جزائر جنوبی سینڈوچ",
    "ES": "سپین",
    "LK": "سری لنکا",
    "SD": "سوڈان",
    "SR": "سورینام",
    "SJ": "سوولبارڈ اور جان میین",
    "SZ": "سوزیلینڈ",
    "SE": "سویڈن",
    "CH": "سوئٹزرلینڈ",
    "SY": "شام",
    "TW": "تائیوان",
    "TJ": "تاجکستان",
    "TZ": "تنزانیہ",
    "TH": "تھائی لینڈ",
    "TL": "تیمور-لیس",
    "TG": "ٹوگو",
    "TK": "ٹوکیلو",
    "TO": "ٹونگا",
    "TT": "ٹرینیڈاڈ اور ٹوباگو",
    "TN": "تیونس",
    "TR": "ترکی",
    "TM": "ترکمنستان",
    "TC": "ترکی اور کیکوس جزائر",
    "TV": "تووالو",
    "UG": "یوگنڈا",
    "UA": "یوکرین",
    "AE": "متحدہ عرب امارات",
    "GB": "برطانیہ",
    "US": "ریاست ہائے متحدہ امریکہ",
    "UM": "ریاست ہائے متحدہ امریکہ کے علیحدہ چھوٹے جزائر",
    "UY": "یوراگوئے",
    "UZ": "ازبکستان",
    "VU": "وانوات",
    "VE": "وینزویلا",
    "VN": "ویت نام",
    "VG": "جزائرِورجن، برطانوی",
    "VI": "جزائرِورجن، امریکی",
    "WF": "والس اور فتونہ",
    "EH": "مغربی صحارا",
    "YE": "یمن",
    "ZM": "زامبیا",
    "ZW": "زمبابوے",
    "AX": "جزائرِ آلند",
    "BQ": "بونیرے, سینٹ یستتئوس اینڈ صبا",
    "CW": "کیوراساؤ",
    "GG": "گرنسی",
    "IM": "آیل آف مین",
    "JE": "جرسی",
    "ME": "مونٹینیگرو",
    "BL": "سینٹ باریٹی",
    "MF": "سینٹ مارٹن (فرانسیسی حصہ)",
    "RS": "سربیا",
    "SX": "سینٹ مارٹن (ولندیزی حصہ)",
    "SS": "جنوبی سوڈان",
    "XK": "کوسوو"
  }
}
