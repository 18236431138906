import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Toast, ToastService } from '@shared/components';

@Injectable()
export class HttpErrorHandlerService {
  private _translateService = inject(TranslateService);
  private _toastService = inject(ToastService);

  showErrorMessage(error: HttpErrorResponse, toast?: Partial<Toast>): void {
    const errorKey = error?.error?.error?.code ?? 'unknown_error';
    const errorMessage = this._translateService.instant(`httpErrorMessages.${errorKey}`);

    this._toastService.showToast({ type: 'error', text: errorMessage, ...toast });
  }
}
