{
  "locale": "he",
  "countries": {
    "AF": "אפגניסטן",
    "AX": "איי אולנד",
    "AL": "אלבניה",
    "DZ": "אלג׳יריה",
    "AS": "סמואה האמריקנית",
    "AD": "אנדורה",
    "AO": "אנגולה",
    "AI": "אנגילה",
    "AQ": "אנטארקטיקה",
    "AG": "אנטיגואה וברבודה",
    "AR": "ארגנטינה",
    "AM": "ארמניה",
    "AW": "ארובה",
    "AU": "אוסטרליה",
    "AT": "אוסטריה",
    "AZ": "אזרבייג׳ן",
    "BS": "איי בהאמה",
    "BH": "בחריין",
    "BD": "בנגלדש",
    "BB": "ברבדוס",
    "BY": "בלארוס",
    "BE": "בלגיה",
    "BZ": "בליז",
    "BJ": "בנין",
    "BM": "ברמודה",
    "BT": "בהוטן",
    "BO": "בוליביה",
    "BQ": "האיים הקריביים ההולנדיים",
    "BA": "בוסניה והרצגובינה",
    "BW": "בוצוואנה",
    "BV": "איי בובה",
    "BR": "ברזיל",
    "IO": "הטריטוריה הבריטית באוקיינוס ההודי",
    "BN": "ברוניי",
    "BG": "בולגריה",
    "BF": "בורקינה פאסו",
    "BI": "בורונדי",
    "KH": "קמבודיה",
    "CM": "קמרון",
    "CA": "קנדה",
    "CV": "כף ורדה",
    "KY": "איי קיימן",
    "CF": "הרפובליקה של מרכז אפריקה",
    "TD": "צ׳אד",
    "CL": "צ׳ילה",
    "CN": "סין",
    "CX": "האי כריסטמס",
    "CC": "איי קוקוס (קילינג)",
    "CO": "קולומביה",
    "KM": "קומורו",
    "CG": "קונגו - ברזאויל",
    "CD": "קונגו - קינשאסה",
    "CK": "איי קוק",
    "CR": "קוסטה ריקה",
    "CI": "חוף השנהב",
    "HR": "קרואטיה",
    "CU": "קובה",
    "CW": "קוראסאו",
    "CY": "קפריסין",
    "CZ": "צ׳כיה",
    "DK": "דנמרק",
    "DJ": "ג׳יבוטי",
    "DM": "דומיניקה",
    "DO": "הרפובליקה הדומיניקנית",
    "EC": "אקוודור",
    "EG": "מצרים",
    "SV": "אל סלבדור",
    "GQ": "גינאה המשוונית",
    "ER": "אריתריאה",
    "EE": "אסטוניה",
    "ET": "אתיופיה",
    "FK": "איי פוקלנד",
    "FO": "איי פארו",
    "FJ": "פיג׳י",
    "FI": "פינלנד",
    "FR": "צרפת",
    "GF": "גיאנה הצרפתית",
    "PF": "פולינזיה הצרפתית",
    "TF": "הטריטוריות הדרומיות של צרפת",
    "GA": "גבון",
    "GM": "גמביה",
    "GE": "גאורגיה",
    "DE": "גרמניה",
    "GH": "גאנה",
    "GI": "גיברלטר",
    "GR": "יוון",
    "GL": "גרינלנד",
    "GD": "גרנדה",
    "GP": "גוואדלופ",
    "GU": "גואם",
    "GT": "גואטמלה",
    "GG": "גרנסי",
    "GN": "גינאה",
    "GW": "גינאה ביסאו",
    "GY": "גיאנה",
    "HT": "האיטי",
    "HM": "איי הרד ומקדונלד",
    "VA": "הוותיקן",
    "HN": "הונדורס",
    "HK": "הונג קונג (מחוז מנהלי מיוחד של סין)",
    "HU": "הונגריה",
    "IS": "איסלנד",
    "IN": "הודו",
    "ID": "אינדונזיה",
    "IR": "איראן",
    "IQ": "עיראק",
    "IE": "אירלנד",
    "IM": "האי מאן",
    "IL": "ישראל",
    "IT": "איטליה",
    "JM": "ג׳מייקה",
    "JP": "יפן",
    "JE": "ג׳רסי",
    "JO": "ירדן",
    "KZ": "קזחסטן",
    "KE": "קניה",
    "KI": "קיריבאטי",
    "KP": "קוריאה הצפונית",
    "KR": "קוריאה הדרומית",
    "KW": "כווית",
    "KG": "קירגיזסטן",
    "LA": "לאוס",
    "LV": "לטביה",
    "LB": "לבנון",
    "LS": "לסוטו",
    "LR": "ליבריה",
    "LY": "לוב",
    "LI": "ליכטנשטיין",
    "LT": "ליטא",
    "LU": "לוקסמבורג",
    "MO": "מקאו (מחוז מנהלי מיוחד של סין)",
    "MK": "מקדוניה הצפונית",
    "MG": "מדגסקר",
    "MW": "מלאווי",
    "MY": "מלזיה",
    "MV": "האיים המלדיביים",
    "ML": "מאלי",
    "MT": "מלטה",
    "MH": "איי מרשל",
    "MQ": "מרטיניק",
    "MR": "מאוריטניה",
    "MU": "מאוריציוס",
    "YT": "מאיוט",
    "MX": "מקסיקו",
    "FM": "מיקרונזיה",
    "MD": "מולדובה",
    "MC": "מונקו",
    "MN": "מונגוליה",
    "ME": "מונטנגרו",
    "MS": "מונסראט",
    "MA": "מרוקו",
    "MZ": "מוזמביק",
    "MM": "מיאנמר (בורמה)",
    "NA": "נמיביה",
    "NR": "נאורו",
    "NP": "נפאל",
    "NL": "הולנד",
    "NC": "קלדוניה החדשה",
    "NZ": "ניו זילנד",
    "NI": "ניקרגואה",
    "NE": "ניז׳ר",
    "NG": "ניגריה",
    "NU": "ניווה",
    "NF": "איי נורפוק",
    "MP": "איי מריאנה הצפוניים",
    "NO": "נורווגיה",
    "OM": "עומאן",
    "PK": "פקיסטן",
    "PW": "פלאו",
    "PS": "השטחים הפלסטיניים",
    "PA": "פנמה",
    "PG": "פפואה גינאה החדשה",
    "PY": "פרגוואי",
    "PE": "פרו",
    "PH": "הפיליפינים",
    "PN": "איי פיטקרן",
    "PL": "פולין",
    "PT": "פורטוגל",
    "PR": "פוארטו ריקו",
    "QA": "קטאר",
    "RE": "ראוניון",
    "RO": "רומניה",
    "RU": "רוסיה",
    "RW": "רואנדה",
    "BL": "סנט ברתולומיאו",
    "SH": "סנט הלנה",
    "KN": "סנט קיטס ונוויס",
    "LC": "סנט לוסיה",
    "MF": "סן מרטן",
    "PM": "סנט פייר ומיקלון",
    "VC": "סנט וינסנט והגרנדינים",
    "WS": "סמואה",
    "SM": "סן מרינו",
    "ST": "סאו טומה ופרינסיפה",
    "SA": "ערב הסעודית",
    "SN": "סנגל",
    "RS": "סרביה",
    "SC": "איי סיישל",
    "SL": "סיירה לאונה",
    "SG": "סינגפור",
    "SX": "סנט מארטן",
    "SK": "סלובקיה",
    "SI": "סלובניה",
    "SB": "איי שלמה",
    "SO": "סומליה",
    "ZA": "דרום אפריקה",
    "GS": "ג׳ורג׳יה הדרומית ואיי סנדוויץ׳ הדרומיים",
    "SS": "דרום סודן",
    "ES": "ספרד",
    "LK": "סרי לנקה",
    "SD": "סודן",
    "SR": "סורינם",
    "SJ": "סוולבארד ויאן מאיין",
    "SZ": "סווזילנד",
    "SE": "שוודיה",
    "CH": "שווייץ",
    "SY": "סוריה",
    "TW": "טייוואן",
    "TJ": "טג׳יקיסטן",
    "TZ": "טנזניה",
    "TH": "תאילנד",
    "TL": "טימור לסטה",
    "TG": "טוגו",
    "TK": "טוקלאו",
    "TO": "טונגה",
    "TT": "טרינידד וטובגו",
    "TN": "טוניסיה",
    "TR": "טורקיה",
    "TM": "טורקמניסטן",
    "TC": "איי טורקס וקאיקוס",
    "TV": "טובאלו",
    "UG": "אוגנדה",
    "UA": "אוקראינה",
    "AE": ["איחוד האמירויות הערביות","איחוד האמירויות"],
    "GB": "הממלכה המאוחדת",
    "US": ["ארצות הברית","ארהב","ארה״ב"],
    "UM": "האיים המרוחקים הקטנים של ארה״ב",
    "UY": "אורוגוואי",
    "UZ": "אוזבקיסטן",
    "VU": "ונואטו",
    "VE": "ונצואלה",
    "VN": "וייטנאם",
    "VG": "איי הבתולה הבריטיים",
    "VI": "איי הבתולה של ארצות הברית",
    "WF": "איי ווליס ופוטונה",
    "EH": "סהרה המערבית",
    "YE": "תימן",
    "ZM": "זמביה",
    "ZW": "זימבבואה",
    "XK": "קוסובו"
  }
}
