import { trigger, state, style, transition, animate } from '@angular/animations';

export const accordionAnimation = trigger('accordionContent', [
  state(
    'open',
    style({
      height: '*',
      opacity: 1,
      padding: '0 60px 24px 24px',
    }),
  ),
  state(
    'closed',
    style({
      height: '0px',
      opacity: 0,
      padding: '0 15px',
    }),
  ),
  transition('open <=> closed', [animate('0.3s ease-in-out')]),
]);
