{
  "locale": "uk",
  "countries": {
    "AU": "Австралія",
    "AT": "Австрія",
    "AZ": "Азербайджан",
    "AX": "Аландські Острови",
    "AL": "Албанія",
    "DZ": "Алжир",
    "AS": "Американське Самоа",
    "AI": "Ангілья",
    "AO": "Ангола",
    "AD": "Андорра",
    "AQ": "Антарктика",
    "AG": "Антигуа і Барбуда",
    "MO": "Макао",
    "AR": "Аргентина",
    "AM": "Вірменія",
    "AW": "Аруба",
    "AF": "Афганістан",
    "BS": "Багамські Острови",
    "BD": "Бангладеш",
    "BB": "Барбадос",
    "BH": "Бахрейн",
    "BZ": "Беліз",
    "BE": "Бельгія",
    "BJ": "Бенін",
    "BM": "Бермудські Острови",
    "BY": "Білорусь",
    "BG": "Болгарія",
    "BO": "Болівія",
    "BA": "Боснія і Герцеговина",
    "BW": "Ботсвана",
    "BR": "Бразилія",
    "IO": "Британська Територія в Індійському Океані",
    "VG": "Британські Віргінські Острови",
    "BN": "Бруней-Даруссалам",
    "BF": "Буркіна-Фасо",
    "BI": "Бурунді",
    "BT": "Бутан",
    "VU": "Вануату",
    "VA": "Ватикан",
    "GB": "Великобританія",
    "VE": "Венесуела",
    "VI": "Віргінські Острови (США)",
    "WF": "Волліс і Футуна",
    "VN": "В'єтнам",
    "UM": "Зовнішні Віддалені Острови (США)",
    "GA": "Габон",
    "HT": "Гаїті",
    "GY": "Гаяна",
    "GM": "Гамбія",
    "GH": "Гана",
    "GP": "Гваделупа",
    "GT": "Гватемала",
    "GF": "Гвіана",
    "GN": "Гвінея",
    "GW": "Гвінея-Бісау",
    "GG": "Гернсі",
    "GI": "Гібралтар",
    "HN": "Гондурас",
    "HK": "Гонконг",
    "GD": "Гренада",
    "GR": "Греція",
    "GE": "Грузія",
    "GU": "Гуам",
    "GL": "Гренландія",
    "DK": "Данія",
    "JE": "Джерсі",
    "DJ": "Джибуті",
    "DM": "Домініка",
    "DO": "Домініканська Республіка",
    "CD": "Демократична Республіка Конго",
    "EC": "Еквадор",
    "GQ": "Екваторіальна Гвінея",
    "ER": "Еритрея",
    "EE": "Естонія",
    "ET": "Ефіопія",
    "EG": "Єгипет",
    "YE": "Ємен",
    "ZM": "Замбія",
    "ZW": "Зімбабве",
    "IL": "Ізраїль",
    "IN": "Індія",
    "ID": "Індонезія",
    "IQ": "Ірак",
    "IR": "Іран",
    "IE": "Ірландія",
    "IS": "Ісландія",
    "ES": "Іспанія",
    "IT": "Італія",
    "JO": "Йорданія",
    "CV": "Кабо-Верде",
    "KZ": "Казахстан",
    "KY": "Кайманові Острови",
    "KH": "Камбоджа",
    "CM": "Камерун",
    "CA": "Канада",
    "BQ": "Карибські Нідерланди",
    "QA": "Катар",
    "KE": "Кенія",
    "CY": "Кіпр",
    "KI": "Кірибаті",
    "KG": "Киргизстан",
    "TW": "Тайвань, Провінція Китаю",
    "KP": "Корейська Народно-Демократична Республіка",
    "CN": "Китай",
    "CC": "Кокосові Острови",
    "CO": "Колумбія",
    "KM": "Комори",
    "XK": "Косово",
    "CR": "Коста-Рика",
    "CI": "Кот-Д'Івуар",
    "CU": "Куба",
    "KW": "Кувейт",
    "CW": "Кюрасао",
    "LA": "Лаос",
    "LV": "Латвія",
    "LS": "Лесото",
    "LR": "Ліберія",
    "LB": "Ліван",
    "LY": "Лівія",
    "LT": "Литва",
    "LI": "Ліхтенштейн",
    "LU": "Люксембург",
    "MU": "Маврикій",
    "MR": "Мавританія",
    "MG": "Мадагаскар",
    "YT": "Майотта",
    "MW": "Малаві",
    "MY": "Малайзія",
    "ML": "Малі",
    "MV": "Мальдіви",
    "MT": "Мальта",
    "MA": "Марокко",
    "MQ": "Мартиніка",
    "MH": "Маршаллові Острови",
    "MX": "Мексика",
    "FM": "Мікронезія",
    "MZ": "Мозамбік",
    "MD": "Молдова",
    "MC": "Монако",
    "MN": "Монголія",
    "MS": "Монтсеррат",
    "MM": "М'янма",
    "NA": "Намібія",
    "NR": "Науру",
    "NP": "Непал",
    "NE": "Нігер",
    "NG": "Нігерія",
    "NL": "Нідерланди",
    "NI": "Нікарагуа",
    "DE": "Німеччина",
    "NU": "Ніуе",
    "NZ": "Нова Зеландія",
    "NC": "Нова Каледонія",
    "NO": "Норвегія",
    "AE": "Об'єднані Арабські Емірати",
    "OM": "Оман",
    "BV": "Острів Буве",
    "HM": "Острів Герд і Острови Макдоналд",
    "IM": "Острів Мен",
    "NF": "Острів Норфолк",
    "CX": "Острів Різдва",
    "CK": "Острови Кука",
    "SH": "Острів Святої Єлени",
    "TC": "Острови Теркс і Кайкос",
    "PK": "Пакистан",
    "PW": "Палау",
    "PS": "Палестина",
    "PA": "Панама",
    "PG": "Папуа-Нова Гвінея",
    "ZA": "Південна Африка",
    "PY": "Парагвай",
    "PE": "Перу",
    "GS": "Південна Джорджія та Південні Сандвічеві Острови",
    "KR": "Республіка Корея",
    "SS": "Південний Судан",
    "MK": "Північна Македонія",
    "MP": "Північні Маріанські Острови",
    "PN": "Піткерн",
    "PL": "Польща",
    "PT": "Португалія",
    "PR": "Пуерто-Рико",
    "CG": "Конго",
    "RE": "Реюньйон",
    "RU": "Росія",
    "RW": "Руанда",
    "RO": "Румунія",
    "EH": "Західна Сахара",
    "SV": "Сальвадор",
    "WS": "Самоа",
    "SM": "Сан-Марино",
    "ST": "Сан-Томе і Принсіпі",
    "SA": "Саудівська Аравія",
    "SZ": "Есватіні",
    "SJ": "Острови Шпіцберген та Ян-Маєн",
    "SC": "Сейшельські Острови",
    "BL": "Сен-Бартелемі",
    "MF": "Сен-Мартен",
    "PM": "Сен-П'єр і Мікелон",
    "SN": "Сенегал",
    "VC": "Сент-Вінсент і Гренадіни",
    "KN": "Сент-Кітс і Невіс",
    "LC": "Сент-Люсія",
    "RS": "Сербія",
    "SG": "Сингапур",
    "SX": "Сінт-Мартен",
    "SY": "Сирія",
    "SK": "Словаччина",
    "SI": "Словенія",
    "SB": "Соломонові Острови",
    "SO": "Сомалі",
    "SD": "Судан",
    "SR": "Суринам",
    "TL": "Тимор-Лешті",
    "US": "США",
    "SL": "Сьєрра-Леоне",
    "TJ": "Таджикистан",
    "TH": "Таїланд",
    "TZ": "Танзанія",
    "TG": "Того",
    "TK": "Токелау",
    "TO": "Тонга",
    "TT": "Тринідад і Тобаго",
    "TV": "Тувалу",
    "TN": "Туніс",
    "TM": "Туркменистан",
    "TR": "Туреччина",
    "UG": "Уганда",
    "HU": "Угорщина",
    "UZ": "Узбекистан",
    "UA": "Україна",
    "UY": "Уругвай",
    "FO": "Фарерські Острови",
    "FJ": "Фіджі",
    "PH": "Філіппіни",
    "FI": "Фінляндія",
    "FK": "Фолклендські Острови",
    "FR": "Франція",
    "PF": "Французька Полінезія",
    "TF": "Французькі Південні і Антарктичні Території",
    "HR": "Хорватія",
    "CF": "Центральноафриканська Республіка",
    "TD": "Чад",
    "ME": "Чорногорія",
    "CZ": "Чехія",
    "CL": "Чилі",
    "CH": "Швейцарія",
    "SE": "Швеція",
    "LK": "Шри-Ланка",
    "JM": "Ямайка",
    "JP": "Японія"
  }
}
