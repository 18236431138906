{
  "locale": "cy",
  "countries": {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "Samoa Americanaidd",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua a Barbuda",
    "AR": "Yr Ariannin",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Awstralia",
    "AT": "Awstria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Gwlad Belg",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolifia",
    "BA": "Bosnia a Herzegovina",
    "BW": "Botswana",
    "BV": "Ynys Bouvet",
    "BR": "Brasil",
    "IO": "Tiriogaeth Cefnfor India Prydain",
    "BN": "Brunei Darussalam",
    "BG": "Bwlgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Camerŵn",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Ynysoedd Cayman",
    "CF": "Gweriniaeth Canolbarth Affrica",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Ynys y Nadolig",
    "CC": "Ynysoedd Cocos (Keeling)",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, Gweriniaeth Ddemocrataidd y",
    "CK": "Ynysoedd Cook",
    "CR": "Costa Rica",
    "CI": ["Cote d'Ivoire", "Côte d'Ivoire"],
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Gweriniaeth Tsiec",
    "DK": "Denmarc",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Gweriniaeth Ddominicaidd",
    "EC": "Ecwador",
    "EG": "Yr Aifft",
    "SV": "El Salvador",
    "GQ": "Gini Cyhydeddol",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Ynysoedd y Falkland (Malvinas)",
    "FO": "Ynysoedd Ffaro",
    "FJ": "Ffiji",
    "FI": "Y Ffindir",
    "FR": "Ffrainc",
    "GF": "Guiana Ffrengig",
    "PF": "Polynesia Ffrainc",
    "TF": "Tiriogaethau De Ffrainc",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Yr Almaen",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Gwlad Groeg",
    "GL": "Yr Ynys Las",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Gini",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Ynys Heard ac Ynysoedd McDonald",
    "VA": "Holy See (Dinas-wladwriaeth y Fatican)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hwngari",
    "IS": "Gwlad yr Iâ",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran, Gweriniaeth Islamaidd",
    "IQ": "Irac",
    "IE": "Iwerddon",
    "IL": "Israel",
    "IT": "Yr Eidal",
    "JM": "Jamaica",
    "JP": "Japan",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Gogledd Corea",
    "KR": "De Korea",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Gweriniaeth Ddemocrataidd Pobl Lao",
    "LV": "Latfia",
    "LB": "Libanus",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithwania",
    "LU": "Lwcsembwrg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Ynysoedd Marshall",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mecsico",
    "FM": "Micronesia, Gwladwriaethau Ffederal o",
    "MD": "Moldofa, Gweriniaeth",
    "MC": "Monaco",
    "MN": "Mongolia",
    "MS": "Montserrat",
    "MA": "Moroco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Yr Iseldiroedd",
    "NC": "Caledonia Newydd",
    "NZ": "Seland Newydd",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Ynys Norfolk",
    "MK": "Gogledd Macedonia, Gweriniaeth",
    "MP": "Ynysoedd Gogledd Mariana",
    "NO": "Norwy",
    "OM": "Oman",
    "PK": "Pacistan",
    "PW": "Palau",
    "PS": ["Cyflwr Palestina", "Palestina"],
    "PA": "Panama",
    "PG": "Gini Newydd Papua",
    "PY": "Paraguay",
    "PE": "Periw",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Gwlad Pwyl",
    "PT": "Portiwgal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Aduniad",
    "RO": "Rwmania",
    "RU": ["Ffederasiwn Rwseg", "Rwsia"],
    "RW": "Rwanda",
    "SH": "Saint Helena",
    "KN": "Saint Kitts a Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre a Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome a Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slofacia",
    "SI": "Slofenia",
    "SB": "Ynysoedd Solomon",
    "SO": "Somalia",
    "ZA": "De Affrica",
    "GS": "De Georgia ac Ynysoedd De Sandwich",
    "ES": "Sbaen",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Swrinam",
    "SJ": "Svalbard a Jan Mayen",
    "SZ": "Eswatini",
    "SE": "Sweden",
    "CH": "Y Swistir",
    "SY": "Gweriniaeth Arabaidd Syria",
    "TW": ["Taiwan, Talaith China", "Taiwan"],
    "TJ": "Tajikistan",
    "TZ": "Tanzania, Gweriniaeth Unedig",
    "TH": "Gwlad Thai",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad a Tobago",
    "TN": "Tiwnisia",
    "TR": "Twrci",
    "TM": "Turkmenistan",
    "TC": "Ynysoedd y Twrciaid ac Ynysoedd Caicos",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Wcráin",
    "AE": "Emiradau Arabaidd Unedig",
    "GB": ["Y Deyrnas Unedig", "DU", "Prydain Fawr"],
    "US": ["Unol Daleithiau America", "UDA"],
    "UM": "Mân Ynysoedd Allanol yr Unol Daleithiau",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Fietnam",
    "VG": "Ynysoedd Virgin, Prydeinig",
    "VI": "Ynysoedd Virgin, U.S.",
    "WF": "Wallis a Futuna",
    "EH": "Sahara Gorllewinol",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Ynysoedd Åland",
    "BQ": "Bonaire, Sint Eustatius a Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Ynys Manaw",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Saint Barthélemy",
    "MF": "Saint Martin (rhan Ffrangeg)",
    "RS": "Serbia",
    "SX": "Sint Maarten (rhan Iseldireg)",
    "SS": "De Swdan",
    "XK": "Kosovo"
  }
}
