import { Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoopValueAccessorDirective,
    },
  ],
})
export class NoopValueAccessorDirective implements ControlValueAccessor {
  /* eslint-disable */
  writeValue(_obj: unknown): void {}
  registerOnChange(_fn: unknown): void {}
  registerOnTouched(_fn: unknown): void {}
}
