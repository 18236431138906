{
  "locale": "sw",
  "countries": {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "Samoa ya Marekani",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antaktiki",
    "AG": "Antigua na Barbuda",
    "AR": "Ajentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azabajani",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarusi",
    "BE": "Ubelgiji",
    "BZ": "Belize",
    "BJ": "Benign",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia na Herzegovina",
    "BW": "Botswana",
    "BV": "Kisiwa cha Bouvet",
    "BR": "Brazil",
    "IO": "Bahari ya Hindi ya Uingereza",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Kamboja",
    "CM": "Kamerun",
    "CA": "Canada",
    "CV": "Kofia ya kijani",
    "KY": "Visiwa vya Cayman",
    "CF": "Jamhuri ya Afrika ya Kati",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "Uchina",
    "CX": "Kisiwa cha Krismasi",
    "CC": "Cocos",
    "CO": "Kolombia",
    "KM": "Comoro",
    "CG": "Jamhuri ya Kongo",
    "CD": "Kongo, Jamhuri ya Kidemokrasia",
    "CK": "Visiwa vya Cook",
    "CR": "Costa Rica",
    "CI": "Pwani ya Pembe",
    "HR": "Kroatia",
    "CU": "Cuba",
    "CY": "Kupro",
    "CZ": "Jamhuri ya Czech",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Jamhuri ya Dominika",
    "EC": "Ekvado",
    "EG": "Misri",
    "SV": "El Salvador",
    "GQ": "Guinea ya Ikweta",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Visiwa vya Falkland",
    "FO": "Visiwa vya Faroe",
    "FJ": "Fiji",
    "FI": "Ufini",
    "FR": "Ufaransa",
    "GF": "Guiana ya Ufaransa",
    "PF": "Polynesia ya Ufaransa",
    "TF": "Wilaya za Kusini mwa Ufaransa",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Ujerumani",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Ugiriki",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Gine",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Visiwa vya Heard na MacDonald",
    "VA": "Holy See",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IL": "Israeli",
    "IT": "Italia",
    "JM": "Jamaica",
    "JP": "Japani",
    "JO": "Yordani",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea Kaskazini, Jamhuri ya Watu wa Kidemokrasiaatique",
    "KR": "Korea Kusini, Jamhuri",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Laos",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxemburg",
    "MO": "Macao",
    "MK": "Makedonia Kaskazini",
    "MG": "Madagaska",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Visiwa vya Marshall",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Maurice",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "MS": "Montserrat",
    "MA": "Moroko",
    "MZ": "Msumbiji",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Uholanzi",
    "NC": "Kaledonia mpya",
    "NZ": "New Zealand",
    "NI": "Nikaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Kisiwa cha Norfolk",
    "MP": "Mariana ya Kaskazini",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestina",
    "PA": "Panama",
    "PG": "Papua Guinea Mpya",
    "PY": "Paragwai",
    "PE": "Peru",
    "PH": "Ufilipino",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Ureno",
    "PR": "Porto Rico",
    "QA": "Qatar",
    "RE": "Mkutano",
    "RO": "Romania",
    "RU": "Urusi",
    "RW": "Rwanda",
    "SH": "Mtakatifu Helena",
    "KN": "Mtakatifu Kitts na Nevis",
    "LC": "Mtakatifu LUCIA",
    "PM": "Mtakatifu Pierre na Miquelon",
    "VC": "Saint Vincent na Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome na Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "SC": "Shelisheli",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Sulemani",
    "SO": "Somalia",
    "ZA": "Africa Kusini",
    "GS": "Georgia Kusini na Visiwa vya Sandwich Kusini",
    "ES": "Uhispania",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard na Kisiwa cha Jan Mayen",
    "SZ": "Ngwane, Ufalme wa Eswatini",
    "SE": "Uswidi",
    "CH": "Uswisi",
    "SY": "Syria",
    "TW": "Taiwan",
    "TJ": "Tajikistani",
    "TZ": "Tanzania, Jamhuri ya Muungano",
    "TH": "Thailand",
    "TL": "Timor Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad na Tobago",
    "TN": "Tunisia",
    "TR": "Uturuki",
    "TM": "Turkmenistan",
    "TC": "Visiwa vya Turks na Caicos",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "Falme za Kiarabu",
    "GB": "Uingereza",
    "US": "Amerika",
    "UM": "Visiwa Vidogo vilivyo nje ya Merika",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Visiwa vya Briteni vya Uingereza",
    "VI": "Visiwa vya Bikira za Amerika",
    "WF": "Wallis na futuna",
    "EH": "Sahara Magharibi",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Bara",
    "BQ": "Bonaire, Saint-Eustache na Saba",
    "CW": "Curacao",
    "GG": "Guernsey",
    "IM": "Kisiwa cha mwanadamu",
    "JE": "Jezi",
    "ME": "Montenegro",
    "BL": "Mtakatifu-Barthélemy",
    "MF": "Saint-Martin (sehemu ya Kifaransa)",
    "RS": "Serbia",
    "SX": "Saint-Martin (sehemu ya Uholanzi)",
    "SS": "Sudan Kusini",
    "XK": "Kosovo"
  }
}
