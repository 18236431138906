{
  "locale": "lv",
  "countries": {
    "AD": "Andora",
    "AE": "Apvienotie Arābu Emirāti",
    "AF": "Afganistāna",
    "AG": "Antigva un Barbuda",
    "AI": "Angilja",
    "AL": "Albānija",
    "AM": "Armēnija",
    "AO": "Angola",
    "AQ": "Antarktika",
    "AR": "Argentīna",
    "AS": "ASV Samoa",
    "AT": "Austrija",
    "AU": "Austrālija",
    "AW": "Aruba",
    "AX": "Olandes salas",
    "AZ": "Azerbaidžāna",
    "BA": "Bosnija un Hercegovina",
    "BB": "Barbadosa",
    "BD": "Bangladeša",
    "BE": "Beļģija",
    "BF": "Burkinafaso",
    "BG": "Bulgārija",
    "BH": "Bahreina",
    "BI": "Burundija",
    "BJ": "Benina",
    "BL": "Senbartelmī",
    "BM": "Bermudu salas",
    "BN": "Bruneja",
    "BO": "Bolīvija",
    "BQ": "Nīderlandes Karību salas",
    "BR": "Brazīlija",
    "BS": "Bahamu salas",
    "BT": "Butāna",
    "BV": "Buvē sala",
    "BW": "Botsvāna",
    "BY": "Baltkrievija",
    "BZ": "Beliza",
    "CA": "Kanāda",
    "CC": "Kokosu (Kīlinga) salas",
    "CD": "Kongo (Kinšasa)",
    "CF": "Centrālāfrikas Republika",
    "CG": "Kongo (Brazavila)",
    "CH": "Šveice",
    "CI": "Kotdivuāra",
    "CK": "Kuka salas",
    "CL": "Čīle",
    "CM": "Kamerūna",
    "CN": "Ķīna",
    "CO": "Kolumbija",
    "CR": "Kostarika",
    "CU": "Kuba",
    "CV": "Kaboverde",
    "CW": "Kirasao",
    "CX": "Ziemsvētku sala",
    "CY": "Kipra",
    "CZ": "Čehija",
    "DE": "Vācija",
    "DJ": "Džibutija",
    "DK": "Dānija",
    "DM": "Dominika",
    "DO": "Dominikāna",
    "DZ": "Alžīrija",
    "EC": "Ekvadora",
    "EE": "Igaunija",
    "EG": "Ēģipte",
    "EH": "Rietumsahāra",
    "ER": "Eritreja",
    "ES": "Spānija",
    "ET": "Etiopija",
    "FI": "Somija",
    "FJ": "Fidži",
    "FK": "Folklenda salas",
    "FM": "Mikronēzija",
    "FO": "Fēru salas",
    "FR": "Francija",
    "GA": "Gabona",
    "GB": "Lielbritānija",
    "GD": "Grenāda",
    "GE": "Gruzija",
    "GF": "Francijas Gviāna",
    "GG": "Gērnsija",
    "GH": "Gana",
    "GI": "Gibraltārs",
    "GL": "Grenlande",
    "GM": "Gambija",
    "GN": "Gvineja",
    "GP": "Gvadelupa",
    "GQ": "Ekvatoriālā Gvineja",
    "GR": "Grieķija",
    "GS": "Dienviddžordžija un Dienvidsendviču salas",
    "GT": "Gvatemala",
    "GU": "Guama",
    "GW": "Gvineja-Bisava",
    "GY": "Gajāna",
    "HK": "Ķīnas īpašās pārvaldes apgabals Honkonga",
    "HM": "Hērda sala un Makdonalda salas",
    "HN": "Hondurasa",
    "HR": "Horvātija",
    "HT": "Haiti",
    "HU": "Ungārija",
    "ID": "Indonēzija",
    "IE": "Īrija",
    "IL": "Izraēla",
    "IM": "Mena",
    "IN": "Indija",
    "IO": "Indijas okeāna Britu teritorija",
    "IQ": "Irāka",
    "IR": "Irāna",
    "IS": "Islande",
    "IT": "Itālija",
    "JE": "Džērsija",
    "JM": "Jamaika",
    "JO": "Jordānija",
    "JP": "Japāna",
    "KE": "Kenija",
    "KG": "Kirgizstāna",
    "KH": "Kambodža",
    "KI": "Kiribati",
    "KM": "Komoru salas",
    "KN": "Sentkitsa un Nevisa",
    "KP": "Ziemeļkoreja",
    "KR": "Dienvidkoreja",
    "KW": "Kuveita",
    "KY": "Kaimanu salas",
    "KZ": "Kazahstāna",
    "LA": "Laosa",
    "LB": "Libāna",
    "LC": "Sentlūsija",
    "LI": "Lihtenšteina",
    "LK": "Šrilanka",
    "LR": "Libērija",
    "LS": "Lesoto",
    "LT": "Lietuva",
    "LU": "Luksemburga",
    "LV": "Latvija",
    "LY": "Lībija",
    "MA": "Maroka",
    "MC": "Monako",
    "MD": "Moldova",
    "ME": "Melnkalne",
    "MF": "Senmartēna",
    "MG": "Madagaskara",
    "MH": "Māršala salas",
    "MK": "Ziemeļmaķedonija",
    "ML": "Mali",
    "MM": "Mjanma (Birma)",
    "MN": "Mongolija",
    "MO": "Ķīnas īpašās pārvaldes apgabals Makao",
    "MP": "Ziemeļu Marianas salas",
    "MQ": "Martinika",
    "MR": "Mauritānija",
    "MS": "Montserrata",
    "MT": "Malta",
    "MU": "Maurīcija",
    "MV": "Maldīvija",
    "MW": "Malāvija",
    "MX": "Meksika",
    "MY": "Malaizija",
    "MZ": "Mozambika",
    "NA": "Namībija",
    "NC": "Jaunkaledonija",
    "NE": "Nigēra",
    "NF": "Norfolkas sala",
    "NG": "Nigērija",
    "NI": "Nikaragva",
    "NL": "Nīderlande",
    "NO": "Norvēģija",
    "NP": "Nepāla",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Jaunzēlande",
    "OM": "Omāna",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Francijas Polinēzija",
    "PG": "Papua-Jaungvineja",
    "PH": "Filipīnas",
    "PK": "Pakistāna",
    "PL": "Polija",
    "PM": "Senpjēra un Mikelona",
    "PN": "Pitkērnas salas",
    "PR": "Puertoriko",
    "PS": "Palestīna",
    "PT": "Portugāle",
    "PW": "Palau",
    "PY": "Paragvaja",
    "QA": "Katara",
    "RE": "Reinjona",
    "RO": "Rumānija",
    "RS": "Serbija",
    "RU": "Krievija",
    "RW": "Ruanda",
    "SA": "Saūda Arābija",
    "SB": "Zālamana salas",
    "SC": "Seišelu salas",
    "SD": "Sudāna",
    "SE": "Zviedrija",
    "SG": "Singapūra",
    "SH": "Sv.Helēnas sala",
    "SI": "Slovēnija",
    "SJ": "Svalbāra un Jana Majena sala",
    "SK": "Slovākija",
    "SL": "Sjerraleone",
    "SM": "Sanmarīno",
    "SN": "Senegāla",
    "SO": "Somālija",
    "SR": "Surinama",
    "SS": "Dienvidsudāna",
    "ST": "Santome un Prinsipi",
    "SV": "Salvadora",
    "SX": "Sintmārtena",
    "SY": "Sīrija",
    "SZ": "Svazilenda",
    "TC": "Tērksas un Kaikosas salas",
    "TD": "Čada",
    "TF": "Francijas Dienvidjūru teritorija",
    "TG": "Togo",
    "TH": "Taizeme",
    "TJ": "Tadžikistāna",
    "TK": "Tokelau",
    "TL": "Austrumtimora",
    "TM": "Turkmenistāna",
    "TN": "Tunisija",
    "TO": "Tonga",
    "TR": "Turcija",
    "TT": "Trinidāda un Tobāgo",
    "TV": "Tuvalu",
    "TW": "Taivāna",
    "TZ": "Tanzānija",
    "UA": "Ukraina",
    "UG": "Uganda",
    "UM": "ASV Mazās Aizjūras salas",
    "US": "Amerikas Savienotās Valstis",
    "UY": "Urugvaja",
    "UZ": "Uzbekistāna",
    "VA": "Vatikāns",
    "VC": "Sentvinsenta un Grenadīnas",
    "VE": "Venecuēla",
    "VG": "Britu Virdžīnas",
    "VI": "ASV Virdžīnas",
    "VN": "Vjetnama",
    "VU": "Vanuatu",
    "WF": "Volisa un Futunas salas",
    "WS": "Samoa",
    "XK": "Kosova",
    "YE": "Jemena",
    "YT": "Majota",
    "ZA": "Dienvidāfrikas Republika",
    "ZM": "Zambija",
    "ZW": "Zimbabve"
  }
}
