{
  "locale": "km",
  "countries": {
    "AF": "អាហ្វហ្គានីស្ថាន",
    "AL": "អាល់បានី",
    "DZ": "អាល់ហ្សេរី",
    "AS": "សាម័រអាមេរិក",
    "AD": "អង់ដូរ៉ា",
    "AO": "អង់ហ្គោឡា",
    "AI": "អង់ហ្គីឡា",
    "AQ": "អង់តាក់ទិក",
    "AG": "អង់ទីហ្គានិងបាប៊ូដា",
    "AR": "អាហ្សង់ទីន",
    "AM": "អាមេនី",
    "AW": "អារូបា",
    "AU": "អូស្ត្រាលី",
    "AT": "អូទ្រីស",
    "AZ": "អាស៊ែបៃហ្សង់",
    "BS": "បាហាម៉ា",
    "BH": "បារ៉ែន",
    "BD": "បង់ក្លាដែស",
    "BB": "បាបាដុស",
    "BY": "បេឡារុស",
    "BE": "បែលហ្ស៊ីក",
    "BZ": "បេលី",
    "BJ": "បេណាំង",
    "BM": "ប៊ែម៉ូដ",
    "BT": "ប៊ូតង់",
    "BO": "បូលីវី",
    "BA": "បូស្នុីនិងហឺហ្សេហ្គូវីណា",
    "BW": "បុតស្វាណា",
    "BV": "កោះប៊ូវ៉េ",
    "BR": "ប្រេស៊ីល",
    "IO": "ដែនសមុទ្រ​ឥណ្ឌាអង់គ្លេស",
    "BN": "ព្រុយណេ",
    "BG": "ប៊ុលហ្គារី",
    "BF": "បួគីណាហ្វាសូ​​",
    "BI": "ប៊ូរុនឌី",
    "KH": "កម្ពុជា",
    "CM": "កាមេរូន",
    "CA": "កាណាដា",
    "CV": "កាប់វែរ",
    "KY": "ប្រជុំកោះកេមេន",
    "CF": "សាធារណរដ្ឋអាហ្វ្រីកកណ្ដាល​",
    "TD": "ឆាដ",
    "CL": "ស៊ីលី",
    "CN": "ចិន",
    "CX": "កោះគ្រីស្តម៉ាស់",
    "CC": "ប្រជុំកោះកូកូ",
    "CO": "កូឡុំប៊ី",
    "KM": "កូម័រ",
    "CG": "កុងហ្គោ",
    "CD": "សាធារណរដ្ឋប្រជាធិបតេយ្យកុងហ្គោ",
    "CK": "ប្រជុំកោះឃុក",
    "CR": "កូស្តារីកា",
    "CI": "កូតឌីវ៍រ",
    "HR": "ក្រូអាស៊ី",
    "CU": "គុយបា",
    "CY": "ស៊ីប",
    "CZ": "សាធារណរដ្ឋឆែក",
    "DK": "ដាណឹម៉ាក",
    "DJ": "ជីប៊ូទី",
    "DM": "ដូមីនីក",
    "DO": "សាធារណរដ្ឋដូមីនីក",
    "EC": "អេក្វាទ័រ",
    "EG": "អេហ្ស៊ីប",
    "SV": "អែលសាល់វ៉ាឌ័រ",
    "GQ": "ហ្គីណេអេក្វាទ័រ",
    "ER": "អេរីត្រេ",
    "EE": "អេស្តូនី",
    "ET": "អេត្យូពី",
    "FK": "ប្រជុំកោះម៉ាលូអុីន",
    "FO": "ប្រជុំកោះហ្វារ៉ូ",
    "FJ": "ហ្វុីជី",
    "FI": "ហ្វាំងឡង់",
    "FR": "បារាំង",
    "GF": "ហ្គាយ៉ានបារាំង",
    "PF": "ប៉ូលីណេស៊ីបារាំង",
    "TF": "ប្រជុំដែនដីភាគខាងត្បូងបារាំង",
    "GA": "ហ្គាបុង",
    "GM": "ហ្គំប៊ី",
    "GE": "ហ្សកហ្ស៊ី",
    "DE": "អាល្លឺម៉ង់",
    "GH": "ហ្គាណា",
    "GI": "ហ្ស៊ីប្រាល់តា",
    "GR": "ក្រិក",
    "GL": "ហ្គ្រោអង់ឡង់",
    "GD": "ហ្គ្រើណាដ",
    "GP": "ហ្គួដាលូប",
    "GU": "ហ្គាំ",
    "GT": "ក្វាតេម៉ាឡា",
    "GN": "ហ្គីណេ",
    "GW": "ហ្គីណេប៊ីស្សូ",
    "GY": "ហ្គីយ៉ាន",
    "HT": "ហៃទី",
    "HM": "ប្រជុំកោះហើរ៍ដនិងម៉ាកដូណាល់",
    "VA": "បុរីវ៉ាទីកង់",
    "HN": "ហុងឌូរ៉ាស",
    "HK": "ហុងកុង",
    "HU": "ហុងគ្រី",
    "IS": "អុីស្លង់",
    "IN": "ឥណ្ឌា",
    "ID": "ឥណ្ឌូណេស៊ី",
    "IR": "អុីរ៉ង់",
    "IQ": "អុីរ៉ាក់",
    "IE": "អៀរឡង់",
    "IL": "អុីស្រាអែល",
    "IT": "អុីតាលី",
    "JM": "ហ្សាម៉ាអុីក",
    "JP": "ជប៉ុន",
    "JO": "ហ៊្សកដានី",
    "KZ": "កាហ្សាក់ស្ថាន",
    "KE": "កេនយ៉ា",
    "KI": "គីរីបាទី",
    "KP": ["កូរ៉េខាងជើង", "សាធារណរដ្ឋប្រជាធិបតេយ្យប្រជាមានិតកូរ៉េ"],
    "KR": ["កូរ៉េខាងត្បូង", "សាធារណរដ្ឋកូរ៉េ"],
    "KW": "កូវ៉ែត",
    "KG": "កៀហ្ស៊ីស៊ីស្ថាន",
    "LA": "សាធារណរដ្ឋប្រជាធិបតេយ្យប្រជាមានិតឡាវ",
    "LV": "ឡេតូនី",
    "LB": "លីបង់",
    "LS": "ឡេសូតូ",
    "LR": "លីបេរីយ៉ា",
    "LY": "លីប៊ី",
    "LI": "លិចតិនស្តាញ",
    "LT": "លីទុយអានី",
    "LU": "លុចសំបួ",
    "MO": "ម៉ាកាវ",
    "MG": "ម៉ាដាហ្គាស្កា",
    "MW": "ម៉ាឡាវី",
    "MY": "ម៉ាឡេស៊ី",
    "MV": "ម៉ាល់ឌីវ",
    "ML": "ម៉ាលី",
    "MT": "ម៉ាល់ត៍",
    "MH": "ប្រជុំកោះម៉ាស្សាល់",
    "MQ": "ម៉ាទីនីក",
    "MR": "ម៉ូរីតានី",
    "MU": "ម៉ូរីស",
    "YT": "ម៉ាយ៉ូត",
    "MX": "មុិកស៊ិក",
    "FM": "រដ្ឋសហព័ន្ធមីក្រូណេស៊ី",
    "MD": "សាធារណរដ្ឋម៉ុលដាវី",
    "MC": "ម៉ូណាកូ",
    "MN": "ម៉ុងហ្គោលី",
    "MS": "ម៉ុងស៊ែរ៉ា",
    "MA": "ម៉ារ៉ុក",
    "MZ": "ម៉ូសំប៊ិក",
    "MM": ["ភូមា", "មីយ៉ានម៉ា"],
    "NA": "ណាមីប៊ី",
    "NR": "ណូរូ",
    "NP": "នេប៉ាល់",
    "NL": "ហូឡង់",
    "NC": "នូវែលកាឡេដូនី",
    "NZ": "នូវែលសេឡង់",
    "NI": "នីការ៉ាហ្គា",
    "NE": "នីហ្សេ",
    "NG": "នីហ្សេរីយ៉ា",
    "NU": "នីអេ",
    "NF": "កោះន័រហ្វុក",
    "MK": "សាធារណរដ្ឋម៉ាសេដ្វានខាងជើង",
    "MP": "ប្រជុំកោះម៉ារីយ៉ានខាងជើង",
    "NO": "ន័រវែស",
    "OM": "អូម៉ង់",
    "PK": "បាគីស្ថាន",
    "PW": "ប៉ាឡៅ",
    "PS": "ដែនដីប៉ាឡេស្ទីន",
    "PA": "ប៉ាណាម៉ា",
    "PG": "ប៉ាពូអាស៊ីនូវែលហ្គីណេ",
    "PY": "ប៉ារ៉ាហ្គាយ",
    "PE": "ប៉េរូ",
    "PH": "ហ្វីលីពីន",
    "PN": "ពីតកែរ៍ន",
    "PL": "ប៉ូឡូញ",
    "PT": "ព័រទុយហ្គាល់",
    "PR": "ព័រតូរីកូ",
    "QA": "កាតា",
    "RE": "រ៉េញ៊ូញ៊ុង",
    "RO": "រូម៉ានី",
    "RU": ["សហព័ន្ធរុស្ស៊ី", "រុស្ស៊ី"],
    "RW": "រវ៉ាន់ដា",
    "SH": "សាំងតេលែន",
    "KN": "សាំងគ្រីស្តុបនិងនីវែស",
    "LC": "សាំងលូស៊ី",
    "PM": "សាំងព្រែរ៍និងមីហ្គែឡុង",
    "VC": "សាំងវ៉ាងស្សង់និងហ្គឺណាឌីន",
    "WS": "សាម័រ",
    "SM": "សាំងម៉ារ៊ាំង",
    "ST": "សៅតូម៉េនិងប្រាំងស៊ីប",
    "SA": "អារ៉ាប៊ីសាអូឌីត",
    "SN": "សេណេហ្គាល់",
    "SC": "សីស្ហែល",
    "SL": "សៀរ៉ាឡេអូន",
    "SG": "សិង្ហបុរី",
    "SK": ["សាធារណរដ្ឋស្លូវ៉ាគី", "ស្លូវ៉ាគី"],
    "SI": "ស្លូវេនី",
    "SB": "ប្រជុំកោះសាឡូម៉ុង",
    "SO": "សូម៉ាលី",
    "ZA": "អាហ្វ្រិកខាងត្បូង",
    "GS": "ហ្សកហ្ស៊ីនិងប្រជុំកោះសាំងវុីចខាងត្បូង",
    "ES": "អេស្ប៉ាញ",
    "LK": "ស្រីលង្កា",
    "SD": "ស៊ូដង់",
    "SR": "សូរីណាម",
    "SJ": "ស្វាល់ប៉ានិងកោះហ្សង់ម៉ាយ៉េន",
    "SZ": "អ៊ែស្វាទីនី",
    "SE": "ស៊ុយអែត",
    "CH": "ស្វ៊ីស",
    "SY": "សាធារណរដ្ឋស៊ីរី",
    "TW": "តៃវ៉ាន់",
    "TJ": "តាហ្ស៊ីគីស្ថាន",
    "TZ": "តង់សានី",
    "TH": "ថៃ",
    "TL": "ទីម័រខាងកើត",
    "TG": "តូហ្គោ",
    "TK": "តូកេឡូ",
    "TO": "តុងហ្គា",
    "TT": "ទ្រីនីដាដនិងតូបាហ្គោ",
    "TN": "ទុយនីស៊ី",
    "TR": "តួកគី",
    "TM": "តួកម៉េនីស្ថាន",
    "TC": "ប្រជុំកោះទួកនិងកៃកេ",
    "TV": "ទូវ៉ាលូ",
    "UG": "អ៊ូហ្គង់ដា",
    "UA": "អ៊ុយក្រែន",
    "AE": "អេមីរ៉ាតអារ៉ាប់រួម",
    "GB": "ចក្រភពអង់គ្លេស",
    "US": "សហរដ្ឋអាមេរិក",
    "UM": "ប្រជុំកោះមីន័រអេឡួញ៉េអាមេរិក",
    "UY": "អ៊ុយរូហ្គាយ",
    "UZ": "អ៊ូសបេគីស្ថាន",
    "VU": "វ៉ានូទូ",
    "VE": "វ៉េណេស៊ុយអេឡា",
    "VN": "វៀតណាម",
    "VG": "ប្រជុំកោះវីអ៊ែអង់គ្លេស",
    "VI": "ប្រជុំកោះវីអ៊ែអាមេរិក",
    "WF": "វ៉ាលីសនិងហ្វូតូណា",
    "EH": "សាហារ៉ាខាងលិច",
    "YE": "យេម៉ែន",
    "ZM": "សំប៊ី",
    "ZW": "ស៊ីមបាវ៉េ",
    "AX": "ប្រជុំកោះអូឡង់",
    "BQ": "បូនែរ៍ សាំងអឺស្ទាហ្សឺស និងសាបា",
    "CW": "គុរ៉ាសៅ",
    "GG": "ហ្គេនស៊ី",
    "IM": "កោះម៉ាន",
    "JE": "ហ្សែរ៍ស្ស៊ី",
    "ME": "ម៉ុងតេណេហ្គ្រោ",
    "BL": "សាំងប៉ាតេឡាមុី",
    "MF": "សាំងម៉ាតាំង (បារាំង)",
    "RS": "ស៊ែប៊ី",
    "SX": "សាំងម៉ាតាំង (ហូឡង់)",
    "SS": "ស៊ូដង់ខាងត្បូង",
    "XK": "កូសូវ៉ូ"
  }
}
