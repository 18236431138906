{
  "locale": "uz",
  "countries": {
    "AD": "Andorra",
    "AE": "Birlashgan Arab Amirliklari",
    "AF": "Afgʻoniston",
    "AG": "Antigua va Barbuda",
    "AI": "Angilya",
    "AL": "Albaniya",
    "AM": "Armaniston",
    "AO": "Angola",
    "AQ": "Antarktida",
    "AR": "Argentina",
    "AS": "Amerika Samoasi",
    "AT": "Avstriya",
    "AU": "Avstraliya",
    "AW": "Aruba",
    "AX": "Aland orollari",
    "AZ": "Ozarbayjon",
    "BA": "Bosniya va Gertsegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "Belgiya",
    "BF": "Burkina-Faso",
    "BG": "Bolgariya",
    "BH": "Bahrayn",
    "BI": "Burundi",
    "BJ": "Benin",
    "BL": "Sen-Bartelemi",
    "BM": "Bermuda orollari",
    "BN": "Bruney",
    "BO": "Boliviya",
    "BQ": "Boneyr, Sint-Estatius va Saba",
    "BR": "Braziliya",
    "BS": "Bagama orollari",
    "BT": "Butan",
    "BV": "Buve oroli",
    "BW": "Botsvana",
    "BY": "Belarus",
    "BZ": "Beliz",
    "CA": "Kanada",
    "CC": "Kokos (Kiling) orollari",
    "CD": "Kongo – Kinshasa",
    "CF": "Markaziy Afrika Respublikasi",
    "CG": "Kongo – Brazzavil",
    "CH": "Shveytsariya",
    "CI": "Kot-d’Ivuar",
    "CK": "Kuk orollari",
    "CL": "Chili",
    "CM": "Kamerun",
    "CN": "Xitoy",
    "CO": "Kolumbiya",
    "CR": "Kosta-Rika",
    "CU": "Kuba",
    "CV": "Kabo-Verde",
    "CW": "Kyurasao",
    "CX": "Rojdestvo oroli",
    "CY": "Kipr",
    "CZ": "Chexiya",
    "DE": "Germaniya",
    "DJ": "Jibuti",
    "DK": "Daniya",
    "DM": "Dominika",
    "DO": "Dominikan Respublikasi",
    "DZ": "Jazoir",
    "EC": "Ekvador",
    "EE": "Estoniya",
    "EG": "Misr",
    "EH": "G‘arbiy Sahroi Kabir",
    "ER": "Eritreya",
    "ES": "Ispaniya",
    "ET": "Efiopiya",
    "FI": "Finlandiya",
    "FJ": "Fiji",
    "FK": "Folklend orollari",
    "FM": "Mikroneziya",
    "FO": "Farer orollari",
    "FR": "Fransiya",
    "GA": "Gabon",
    "GB": "Buyuk Britaniya",
    "GD": "Grenada",
    "GE": "Gruziya",
    "GF": "Fransuz Gvianasi",
    "GG": "Gernsi",
    "GH": "Gana",
    "GI": "Gibraltar",
    "GL": "Grenlandiya",
    "GM": "Gambiya",
    "GN": "Gvineya",
    "GP": "Gvadelupe",
    "GQ": "Ekvatorial Gvineya",
    "GR": "Gretsiya",
    "GS": "Janubiy Georgiya va Janubiy Sendvich orollari",
    "GT": "Gvatemala",
    "GU": "Guam",
    "GW": "Gvineya-Bisau",
    "GY": "Gayana",
    "HK": "Gonkong (Xitoy MMH)",
    "HM": "Xerd va Makdonald orollari",
    "HN": "Gonduras",
    "HR": "Xorvatiya",
    "HT": "Gaiti",
    "HU": "Vengriya",
    "ID": "Indoneziya",
    "IE": "Irlandiya",
    "IL": "Isroil",
    "IM": "Men oroli",
    "IN": "Hindiston",
    "IO": "Britaniyaning Hind okeanidagi hududi",
    "IQ": "Iroq",
    "IR": "Eron",
    "IS": "Islandiya",
    "IT": "Italiya",
    "JE": "Jersi",
    "JM": "Yamayka",
    "JO": "Iordaniya",
    "JP": "Yaponiya",
    "KE": "Keniya",
    "KG": "Qirgʻiziston",
    "KH": "Kambodja",
    "KI": "Kiribati",
    "KM": "Komor orollari",
    "KN": "Sent-Kits va Nevis",
    "KP": "Shimoliy Koreya",
    "KR": "Janubiy Koreya",
    "KW": "Quvayt",
    "KY": "Kayman orollari",
    "KZ": "Qozogʻiston",
    "LA": "Laos",
    "LB": "Livan",
    "LC": "Sent-Lyusiya",
    "LI": "Lixtenshteyn",
    "LK": "Shri-Lanka",
    "LR": "Liberiya",
    "LS": "Lesoto",
    "LT": "Litva",
    "LU": "Lyuksemburg",
    "LV": "Latviya",
    "LY": "Liviya",
    "MA": "Marokash",
    "MC": "Monako",
    "MD": "Moldova",
    "ME": "Chernogoriya",
    "MF": "Sent-Martin",
    "MG": "Madagaskar",
    "MH": "Marshall orollari",
    "MK": "Shimoliy Makedoniya",
    "ML": "Mali",
    "MM": "Myanma (Birma)",
    "MN": "Mongoliya",
    "MO": "Makao (Xitoy MMH)",
    "MP": "Shimoliy Mariana orollari",
    "MQ": "Martinika",
    "MR": "Mavritaniya",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Mavrikiy",
    "MV": "Maldiv orollari",
    "MW": "Malavi",
    "MX": "Meksika",
    "MY": "Malayziya",
    "MZ": "Mozambik",
    "NA": "Namibiya",
    "NC": "Yangi Kaledoniya",
    "NE": "Niger",
    "NF": "Norfolk oroli",
    "NG": "Nigeriya",
    "NI": "Nikaragua",
    "NL": "Niderlandiya",
    "NO": "Norvegiya",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Yangi Zelandiya",
    "OM": "Ummon",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Fransuz Polineziyasi",
    "PG": "Papua – Yangi Gvineya",
    "PH": "Filippin",
    "PK": "Pokiston",
    "PL": "Polsha",
    "PM": "Sen-Pyer va Mikelon",
    "PN": "Pitkern orollari",
    "PR": "Puerto-Riko",
    "PS": "Falastin hududi",
    "PT": "Portugaliya",
    "PW": "Palau",
    "PY": "Paragvay",
    "QA": "Qatar",
    "RE": "Reyunion",
    "RO": "Ruminiya",
    "RS": "Serbiya",
    "RU": "Rossiya",
    "RW": "Ruanda",
    "SA": "Saudiya Arabistoni",
    "SB": "Solomon orollari",
    "SC": "Seyshel orollari",
    "SD": "Sudan",
    "SE": "Shvetsiya",
    "SG": "Singapur",
    "SH": "Muqaddas Yelena oroli",
    "SI": "Sloveniya",
    "SJ": "Svalbard va Yan-Mayen",
    "SK": "Slovakiya",
    "SL": "Syerra-Leone",
    "SM": "San-Marino",
    "SN": "Senegal",
    "SO": "Somali",
    "SR": "Surinam",
    "SS": "Janubiy Sudan",
    "ST": "San-Tome va Prinsipi",
    "SV": "Salvador",
    "SX": "Sint-Marten",
    "SY": "Suriya",
    "SZ": "Svazilend",
    "TC": "Turks va Kaykos orollari",
    "TD": "Chad",
    "TF": "Fransuz Janubiy hududlari",
    "TG": "Togo",
    "TH": "Tailand",
    "TJ": "Tojikiston",
    "TK": "Tokelau",
    "TL": "Timor-Leste",
    "TM": "Turkmaniston",
    "TN": "Tunis",
    "TO": "Tonga",
    "TR": "Turkiya",
    "TT": "Trinidad va Tobago",
    "TV": "Tuvalu",
    "TW": "Tayvan",
    "TZ": "Tanzaniya",
    "UA": "Ukraina",
    "UG": "Uganda",
    "UM": "AQSH yondosh orollari",
    "US": "Amerika Qo‘shma Shtatlari",
    "UY": "Urugvay",
    "UZ": "Oʻzbekiston",
    "VA": "Vatikan",
    "VC": "Sent-Vinsent va Grenadin",
    "VE": "Venesuela",
    "VG": "Britaniya Virgin orollari",
    "VI": "AQSH Virgin orollari",
    "VN": "Vyetnam",
    "VU": "Vanuatu",
    "WF": "Uollis va Futuna",
    "WS": "Samoa",
    "XK": "Kosovo",
    "YE": "Yaman",
    "YT": "Mayotta",
    "ZA": "Janubiy Afrika Respublikasi",
    "ZM": "Zambiya",
    "ZW": "Zimbabve"
  }
}
