{
  "locale": "ml",
  "countries": {
    "AF": "അഫ്‌ഗാനിസ്ഥാൻ",
    "AL": "അൽബേനിയ",
    "DZ": "അൾജീരിയ",
    "AS": "അമേരിക്കൻ സമോവ",
    "AD": "അന്റോറ",
    "AO": "അംഗോള",
    "AI": "ആൻഗ്വില്ല",
    "AQ": "അൻറാർട്ടിക്ക",
    "AG": "ആൻറിഗ്വയും ബർബുഡയും",
    "AR": "അർജൻറീന",
    "AM": "അർമേനിയ",
    "AW": "അറൂബ",
    "AU": "ഓസ്‌ട്രേലിയ",
    "AT": "ഓസ്ട്രിയ",
    "AZ": "അസർബൈജാൻ",
    "BS": "ബഹാമാസ്",
    "BH": "ബഹ്റിൻ",
    "BD": "ബംഗ്ലാദേശ്",
    "BB": "ബാർബഡോസ്",
    "BY": "ബെലറൂസ്",
    "BE": "ബെൽജിയം",
    "BZ": "ബെലീസ്",
    "BJ": "ബെനിൻ",
    "BM": "ബർമുഡ",
    "BT": "ഭൂട്ടാൻ",
    "BO": "ബൊളീവിയ",
    "BA": "ബോസ്നിയയും ഹെർസഗോവിനയും",
    "BW": "ബോട്സ്വാന",
    "BV": "ബൗവെട്ട് ദ്വീപ്",
    "BR": "ബ്രസീൽ",
    "IO": "ബ്രിട്ടീഷ് ഇന്ത്യൻ മഹാസമുദ്ര പ്രദേശം",
    "BN": "ബ്രൂണൈ",
    "BG": "ബൾഗേറിയ",
    "BF": "ബുർക്കിനാ ഫാസോ",
    "BI": "ബറുണ്ടി",
    "KH": "കംബോഡിയ",
    "CM": "കാമറൂൺ",
    "CA": "കാനഡ",
    "CV": "കേപ്പ് വെർദെ",
    "KY": "കേമാൻ ദ്വീപുകൾ",
    "CF": "സെൻട്രൽ ആഫ്രിക്കൻ റിപ്പബ്ലിക്",
    "TD": "ഛാഡ്",
    "CL": "ചിലി",
    "CN": "ചൈന",
    "CX": "ക്രിസ്മസ് ദ്വീപ്",
    "CC": "കോക്കസ് ദ്വീപുകൾ",
    "CO": "കൊളംബിയ",
    "KM": "കോമൊറോസ്",
    "CG": "കോംഗോ - ബ്രാസവില്ലി",
    "CD": "കോംഗോ - കിൻഷാസ",
    "CK": "കുക്ക് ദ്വീപുകൾ",
    "CR": "കോസ്റ്ററിക്ക",
    "CI": "കോട്ട് ഡി വാർ",
    "HR": "ക്രൊയേഷ്യ",
    "CU": "ക്യൂബ",
    "CY": "സൈപ്രസ്",
    "CZ": "ചെക്ക് റിപ്പബ്ലിക്",
    "DK": "ഡെൻമാർക്ക്",
    "DJ": "ദിജിബൗട്ടി",
    "DM": "ഡൊമിനിക്ക",
    "DO": "ഡൊമിനിക്കൻ റിപ്പബ്ലിക്",
    "EC": "ഇക്വഡോർ",
    "EG": "ഈജിപ്ത്",
    "SV": "എൽ സാൽവദോർ",
    "GQ": "ഇക്വറ്റോറിയൽ ഗിനിയ",
    "ER": "എറിത്രിയ",
    "EE": "എസ്റ്റോണിയ‍",
    "ET": "എത്യോപ്യ",
    "FK": "ഫാക്ക്‌ലാന്റ് ദ്വീപുകൾ",
    "FO": "ഫറോ ദ്വീപുകൾ",
    "FJ": "ഫിജി",
    "FI": "ഫിൻലാൻഡ്",
    "FR": "ഫ്രാൻസ്",
    "GF": "ഫ്രഞ്ച് ഗയാന",
    "PF": "ഫ്രഞ്ച് പോളിനേഷ്യ",
    "TF": "ഫ്രഞ്ച് ദക്ഷിണ ഭൂപ്രദേശം",
    "GA": "ഗാബൺ",
    "GM": "ഗാംബിയ",
    "GE": "ജോർജ്ജിയ",
    "DE": "ജർമനി",
    "GH": "ഘാന",
    "GI": "ജിബ്രാൾട്ടർ",
    "GR": "ഗ്രീസ്",
    "GL": "ഗ്രീൻലാൻറ്",
    "GD": "ഗ്രനേഡ",
    "GP": "ഗ്വാഡലൂപ്പ്",
    "GU": "ഗ്വാം",
    "GT": "ഗ്വാട്ടിമാല",
    "GN": "ഗിനിയ",
    "GW": "ഗിനിയ-ബിസൗ",
    "GY": "ഗയാന",
    "HT": "ഹെയ്തി",
    "HM": "ഹിയേർഡും മക്‌ഡൊണാൾഡ് ദ്വീപുകളും",
    "VA": "വത്തിക്കാൻ",
    "HN": "ഹോണ്ടുറാസ്",
    "HK": "ഹോങ് കോങ് എസ്.ഏ.ആർ. ചൈന",
    "HU": "ഹംഗറി",
    "IS": "ഐസ്‌ലാന്റ്",
    "IN": "ഇന്ത്യ",
    "ID": "ഇന്തോനേഷ്യ",
    "IR": "ഇറാൻ",
    "IQ": "ഇറാഖ്",
    "IE": "അയർലാൻഡ്",
    "IL": "ഇസ്രായേൽ",
    "IT": "ഇറ്റലി",
    "JM": "ജമൈക്ക",
    "JP": "ജപ്പാൻ",
    "JO": "ജോർദ്ദാൻ",
    "KZ": "കസാഖിസ്ഥാൻ",
    "KE": "കെനിയ",
    "KI": "കിരിബാട്ടി",
    "KP": "ഉത്തരകൊറിയ",
    "KR": "ദക്ഷിണകൊറിയ",
    "KW": "കുവൈറ്റ്",
    "KG": "കിർഗിസ്ഥാൻ",
    "LA": "ലാവോസ്",
    "LV": "ലാറ്റ്വിയ",
    "LB": "ലെബനൻ",
    "LS": "ലെസോതോ",
    "LR": "ലൈബീരിയ",
    "LY": "ലിബിയ",
    "LI": "ലിച്ചൺസ്റ്റൈൻ",
    "LT": "ലിത്വാനിയ",
    "LU": "ലക്സംബർഗ്",
    "MO": "മക്കാവോ SAR ചൈന",
    "MG": "മഡഗാസ്കർ",
    "MW": "മലാവി",
    "MY": "മലേഷ്യ",
    "MV": "മാലിദ്വീപ്",
    "ML": "മാലി",
    "MT": "മാൾട്ട",
    "MH": "മാർഷൽ‍‍ ദ്വീപുകൾ",
    "MQ": "മാർട്ടിനിക്ക്",
    "MR": "മൗറിറ്റാനിയ",
    "MU": "മൗറീഷ്യസ്",
    "YT": "മയോട്ടി",
    "MX": "മെക്സിക്കോ",
    "FM": "മൈക്രോനേഷ്യ",
    "MD": "മൾഡോവ",
    "MC": "മൊണാക്കോ",
    "MN": "മംഗോളിയ",
    "MS": "മൊണ്ടെസരത്ത്",
    "MA": "മൊറോക്കൊ",
    "MZ": "മൊസാംബിക്ക്",
    "MM": "മ്യാൻമാർ (ബർമ്മ)",
    "NA": "നമീബിയ",
    "NR": "നൗറു",
    "NP": "നേപ്പാൾ",
    "NL": "നെതർലാൻഡ്‌സ്",
    "NC": "പുതിയ കാലിഡോണിയ",
    "NZ": "ന്യൂസിലാൻറ്",
    "NI": "നിക്കരാഗ്വ",
    "NE": "നൈജർ",
    "NG": "നൈജീരിയ",
    "NU": "ന്യൂയി",
    "NF": "നോർഫോക് ദ്വീപ്",
    "MK": "മാസിഡോണിയ",
    "MP": "ഉത്തര മറിയാനാ ദ്വീപുകൾ",
    "NO": "നോർവെ",
    "OM": "ഒമാൻ",
    "PK": "പാക്കിസ്ഥാൻ",
    "PW": "പലാവു",
    "PS": "പാലസ്‌തീൻ പ്രദേശങ്ങൾ",
    "PA": "പനാമ",
    "PG": "പാപ്പുവ ന്യൂ ഗിനിയ",
    "PY": "പരാഗ്വേ",
    "PE": "പെറു",
    "PH": "ഫിലിപ്പൈൻസ്",
    "PN": "പിറ്റ്‌കെയ്‌ൻ ദ്വീപുകൾ",
    "PL": "പോളണ്ട്",
    "PT": "പോർച്ചുഗൽ",
    "PR": "പ്യൂർട്ടോ റിക്കോ",
    "QA": "ഖത്തർ",
    "RE": "റീയൂണിയൻ",
    "RO": "റൊമാനിയ",
    "RU": "റഷ്യ",
    "RW": "റുവാണ്ട",
    "SH": "സെൻറ് ഹെലീന",
    "KN": "സെന്റ് കിറ്റ്‌സും നെവിസും",
    "LC": "സെൻറ് ലൂസിയ",
    "PM": "സെന്റ് പിയറിയും മിക്കലണും",
    "VC": "സെന്റ് വിൻസെന്റും ഗ്രനെഡൈൻസും",
    "WS": "സമോവ",
    "SM": "സാൻ മറിനോ",
    "ST": "സാവോ ടോമും പ്രിൻസിപെയും",
    "SA": "സൗദി അറേബ്യ",
    "SN": "സെനഗൽ",
    "SC": "സെയ്‌ഷെൽസ്",
    "SL": "സിയെറ ലിയോൺ",
    "SG": "സിംഗപ്പുർ",
    "SK": "സ്ലോവാക്യ",
    "SI": "സ്ലോവേനിയ",
    "SB": "സോളമൻ‍ ദ്വീപുകൾ",
    "SO": "സോമാലിയ",
    "ZA": "ദക്ഷിണാഫ്രിക്ക",
    "GS": "ദക്ഷിണ ജോർജ്ജിയയും ദക്ഷിണ സാൻഡ്‌വിച്ച് ദ്വീപുകളും",
    "ES": "സ്‌പെയിൻ",
    "LK": "ശ്രീലങ്ക",
    "SD": "സുഡാൻ",
    "SR": "സുരിനെയിം",
    "SJ": "സ്വാൽബാഡും ജാൻ മായേനും",
    "SZ": "സ്വാസിലാൻറ്",
    "SE": "സ്വീഡൻ",
    "CH": "സ്വിറ്റ്സർലാൻഡ്",
    "SY": "സിറിയ",
    "TW": "തായ്‌വാൻ",
    "TJ": "താജിക്കിസ്ഥാൻ",
    "TZ": "ടാൻസാനിയ",
    "TH": "തായ്‌ലാൻഡ്",
    "TL": "തിമോർ-ലെസ്റ്റെ",
    "TG": "ടോഗോ",
    "TK": "ടോക്കെലൂ",
    "TO": "ടോംഗ",
    "TT": "ട്രിനിഡാഡും ടുബാഗോയും",
    "TN": "ടുണീഷ്യ",
    "TR": "തുർക്കി",
    "TM": "തുർക്ക്മെനിസ്ഥാൻ",
    "TC": "ടർക്ക്‌സും കെയ്‌ക്കോ ദ്വീപുകളും",
    "TV": "ടുവാലു",
    "UG": "ഉഗാണ്ട",
    "UA": "ഉക്രെയ്‌ൻ",
    "AE": "യുണൈറ്റഡ് അറബ് എമിറൈറ്റ്‌സ്",
    "GB": "ബ്രിട്ടൻ",
    "US": "അമേരിക്കൻ ഐക്യനാടുകൾ",
    "UM": "യു.എസ്. ദ്വീപസമൂഹങ്ങൾ",
    "UY": "ഉറുഗ്വേ",
    "UZ": "ഉസ്‌ബെക്കിസ്ഥാൻ",
    "VU": "വന്വാതു",
    "VE": "വെനിസ്വേല",
    "VN": "വിയറ്റ്നാം",
    "VG": "ബ്രിട്ടീഷ് വെർജിൻ ദ്വീപുകൾ",
    "VI": "യു.എസ്. വെർജിൻ ദ്വീപുകൾ",
    "WF": "വാലിസ് ആന്റ് ഫ്യൂച്യുന",
    "EH": "പശ്ചിമ സഹാറ",
    "YE": "യെമൻ",
    "ZM": "സാംബിയ",
    "ZW": "സിംബാബ്‌വേ",
    "AX": "അലൻഡ് ദ്വീപുകൾ",
    "BQ": "ബൊണെയ്ർ, സിന്റ് യുസ്റ്റേഷ്യസ്, സാബ എന്നിവ",
    "CW": "കുറാകാവോ",
    "GG": "ഗേൺസി",
    "IM": "ഐൽ ഓഫ് മാൻ",
    "JE": "ജേഴ്സി",
    "ME": "മോണ്ടെനെഗ്രോ",
    "BL": "സെന്റ് ബാർത്തലമി",
    "MF": "സെൻറ് മാർട്ടിൻ",
    "RS": "സെർബിയ",
    "SX": "സിന്റ് മാർട്ടെൻ",
    "SS": "ദക്ഷിണ സുഡാൻ",
    "XK": "കൊസോവൊ"
  }
}
