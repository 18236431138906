{
  "locale": "tt",
  "countries": {
    "AF": "Әфганстан",
    "AL": "Албания",
    "DZ": "Алжир",
    "AS": "Америка Самоасы",
    "AD": "Андорра",
    "AO": "Ангола",
    "AI": "Ангилья",
    "AQ": "Антарктика",
    "AG": "Антигуа һәм Барбуда",
    "AR": "Аргентина",
    "AM": "Әрмәнстан",
    "AW": "Аруба",
    "AU": "Австралия",
    "AT": "Австрия",
    "AZ": "Әзәрбайҗан",
    "BS": "Багам утраулары",
    "BH": "Бәхрәйн",
    "BD": "Бангладеш",
    "BB": "Барбадос",
    "BY": "Беларусь",
    "BE": "Бельгия",
    "BZ": "Белиз",
    "BJ": "Бенин",
    "BM": "Бермуд утраулары",
    "BT": "Бутан",
    "BO": "Боливия",
    "BA": "Босния һәм Герцеговина",
    "BW": "Ботсвана",
    "BV": "Буве утравы",
    "BR": "Бразилия",
    "IO": "Британиянең Һинд Океанындагы Территориясе",
    "BN": "Бруней",
    "BG": "Болгария",
    "BF": "Буркина-Фасо",
    "BI": "Бурунди",
    "KH": "Камбоджа",
    "CM": "Камерун",
    "CA": "Канада",
    "CV": "Кабо-Верде",
    "KY": "Кайман утраулары",
    "CF": "Үзәк Африка Республикасы",
    "TD": "Чад",
    "CL": "Чили",
    "CN": "Кытай",
    "CX": "Раштуа утравы",
    "CC": "Кокос (Килинг) утраулары",
    "CO": "Колумбия",
    "KM": "Комор утраулары",
    "CG": "Конго",
    "CD": "Конго, Демократик Республикасы",
    "CK": "Кук утраулары",
    "CR": "Коста-Рика",
    "CI": "Кот-д’Ивуар",
    "HR": "Хорватия",
    "CU": "Куба",
    "CY": "Кипр",
    "CZ": "Чехия Республикасы",
    "DK": "Дания",
    "DJ": "Җибүти",
    "DM": "Доминика",
    "DO": "Доминикана Республикасы",
    "EC": "Эквадор",
    "EG": "Мисыр",
    "SV": "Сальвадор",
    "GQ": "Экваториаль Гвинея",
    "ER": "Эритрея",
    "EE": "Эстония",
    "ET": "Эфиопия",
    "FK": "Фолкленд утраулары",
    "FO": "Фарер утраулары",
    "FJ": "Фиджи",
    "FI": "Финляндия",
    "FR": "Франция",
    "GF": "Француз Гвианасы",
    "PF": "Француз Полинезиясе",
    "TF": "Франциянең Көньяк Территорияләре",
    "GA": "Габон",
    "GM": "Гамбия",
    "GE": "Грузия",
    "DE": "Германия",
    "GH": "Гана",
    "GI": "Гибралтар",
    "GR": "Греция",
    "GL": "Гренландия",
    "GD": "Гренада",
    "GP": "Гваделупа",
    "GU": "Гуам",
    "GT": "Гватемала",
    "GN": "Гвинея",
    "GW": "Гвинея-Бисау",
    "GY": "Гайана",
    "HT": "Гаити",
    "HM": "Херд утравы һәм Макдональд утраулары",
    "VA": "Изге күренеш (Ватикан шәһәре дәүләте)",
    "HN": "Гондурас",
    "HK": "Гонконг Махсус Идарәле Төбәге",
    "HU": "Венгрия",
    "IS": "Исландия",
    "IN": "Индия",
    "ID": "Индонезия",
    "IR": "Иран",
    "IQ": "Гыйрак",
    "IE": "Ирландия",
    "IL": "Израиль",
    "IT": "Италия",
    "JM": "Ямайка",
    "JP": "Япония",
    "JO": "Иордания",
    "KZ": "Казахстан",
    "KE": "Кения",
    "KI": "Кирибати",
    "KP": "Төньяк Корея",
    "KR": "Көньяк Корея",
    "KW": "Күвәйт",
    "KG": "Кыргызстан",
    "LA": "Лаос",
    "LV": "Латвия",
    "LB": "Ливан",
    "LS": "Лесото",
    "LR": "Либерия",
    "LY": "Ливия",
    "LI": "Лихтенштейн",
    "LT": "Литва",
    "LU": "Люксембург",
    "MO": "Макао Махсус Идарәле Төбәге",
    "MG": "Мадагаскар",
    "MW": "Малави",
    "MY": "Малайзия",
    "MV": "Мальдив утраулары",
    "ML": "Мали",
    "MT": "Мальта",
    "MH": "Маршалл утраулары",
    "MQ": "Мартиника",
    "MR": "Мавритания",
    "MU": "Маврикий",
    "YT": "Майотта",
    "MX": "Мексика",
    "FM": "Микронезия",
    "MD": "Молдова",
    "MC": "Монако",
    "MN": "Монголия",
    "MS": "Монтсеррат",
    "MA": "Марокко",
    "MZ": "Мозамбик",
    "MM": "Мьянма",
    "NA": "Намибия",
    "NR": "Науру",
    "NP": "Непал",
    "NL": "Нидерланд",
    "NC": "Яңа Каледония",
    "NZ": "Яңа Зеландия",
    "NI": "Никарагуа",
    "NE": "Нигер",
    "NG": "Нигерия",
    "NU": "Ниуэ",
    "NF": "Норфолк утравы",
    "MK": "Төньяк Македония",
    "MP": "Төньяк Мариана утраулары",
    "NO": "Норвегия",
    "OM": "Оман",
    "PK": "Пакистан",
    "PW": "Палау",
    "PS": "Палестина",
    "PA": "Панама",
    "PG": "Папуа - Яңа Гвинея",
    "PY": "Парагвай",
    "PE": "Перу",
    "PH": "Филиппин",
    "PN": "Питкэрн утраулары",
    "PL": "Польша",
    "PT": "Португалия",
    "PR": "Пуэрто-Рико",
    "QA": "Катар",
    "RE": "Реюньон",
    "RO": "Румыния",
    "RU": "Россия",
    "RW": "Руанда",
    "SH": "Изге Елена",
    "KN": "Сент-Китс һәм Невис",
    "LC": "Сент-Люсия",
    "PM": "Сен-Пьер һәм Микелон",
    "VC": "Сент-Винсент һәм Гренадин",
    "WS": "Самоа",
    "SM": "Сан-Марино",
    "ST": "Сан-Томе һәм Принсипи",
    "SA": "Согуд Гарәбстаны",
    "SN": "Сенегал",
    "SC": "Сейшел утраулары",
    "SL": "Сьерра-Леоне",
    "SG": "Сингапур",
    "SK": "Словакия",
    "SI": "Словения",
    "SB": "Сөләйман утраулары",
    "SO": "Сомали",
    "ZA": "Көньяк Африка",
    "GS": "Көньяк Георгия һәм Көньяк Сандвич утраулары",
    "ES": "Испания",
    "LK": "Шри-Ланка",
    "SD": "Судан",
    "SR": "Суринам",
    "SJ": "Шпицберген һәм Ян-Майен",
    "SZ": "Свазиленд",
    "SE": "Швеция",
    "CH": "Швейцария",
    "SY": "Сүрия",
    "TW": "Тайвань",
    "TJ": "Таҗикстан",
    "TZ": "Танзания",
    "TH": "Тайланд",
    "TL": "Тимор-Лесте",
    "TG": "Того",
    "TK": "Токелау",
    "TO": "Тонга",
    "TT": "Тринидад һәм Тобаго",
    "TN": "Тунис",
    "TR": "Төркия",
    "TM": "Төркмәнстан",
    "TC": "Теркс һәм Кайкос утраулары",
    "TV": "Тувалу",
    "UG": "Уганда",
    "UA": "Украина",
    "AE": "Берләшкән Гарәп Әмирлекләре",
    "GB": "Берләшкән Корольлек",
    "US": "АКШ",
    "UM": "АКШ Кече Читтәге утраулары",
    "UY": "Уругвай",
    "UZ": "Үзбәкстан",
    "VU": "Вануату",
    "VE": "Венесуэла",
    "VN": "Вьетнам",
    "VG": "Британия Виргин утраулары",
    "VI": "АКШ Виргин утраулары",
    "WF": "Уоллис һәм Футуна",
    "EH": "Көнбатыш Сахара",
    "YE": "Йәмән",
    "ZM": "Замбия",
    "ZW": "Зимбабве",
    "AX": "Аланд утраулары",
    "BQ": "Бонейр, Синт Эстатий һәм Саба",
    "CW": "Кюрасао",
    "GG": "Гернси",
    "IM": "Мэн утравы",
    "JE": "Джерси",
    "ME": "Черногория",
    "BL": "Сен-Бартельми",
    "MF": "Сент-Мартин",
    "RS": "Сербия",
    "SX": "Синт-Мартен",
    "SS": "Көньяк Судан",
    "XK": "Косово"
  }
}
