{
  "locale": "mt",
  "countries": {
    "AF": "l-Afganistan",
    "AL": "l-Albanija",
    "DZ": "l-Alġerija",
    "AS": "is-Samoa Amerikana",
    "AD": "Andorra",
    "AO": "l-Angola",
    "AI": "Anguilla",
    "AQ": "l-Antartika",
    "AG": "Antigua u Barbuda",
    "AR": "l-Arġentina",
    "AM": "l-Armenja",
    "AW": "Aruba",
    "AU": "l-Awstralja",
    "AT": "l-Awstrija",
    "AZ": "l-Ażerbajġan",
    "BS": "il-Bahamas",
    "BH": "il-Bahrain",
    "BD": "il-Bangladesh",
    "BB": "Barbados",
    "BY": "il-Belarussja",
    "BE": "il-Belġju",
    "BZ": "il-Belize",
    "BJ": "il-Benin",
    "BM": "Bermuda",
    "BT": "il-Bhutan",
    "BO": "il-Bolivja",
    "BA": "il-Bożnija-Ħerzegovina",
    "BW": "il-Botswana",
    "BV": "Gżira Bouvet",
    "BR": "Il-Brażil",
    "IO": "Territorju Brittaniku tal-Oċean Indjan",
    "BN": "il-Brunei",
    "BG": "il-Bulgarija",
    "BF": "il-Burkina Faso",
    "BI": "il-Burundi",
    "KH": "il-Kambodja",
    "CM": "il-Kamerun",
    "CA": "il-Kanada",
    "CV": "Cape Verde",
    "KY": "il-Gżejjer Cayman",
    "CF": "ir-Repubblika Ċentru-Afrikana",
    "TD": "iċ-Chad",
    "CL": "iċ-Ċili",
    "CN": "iċ-Ċina",
    "CX": "il-Gżira Christmas",
    "CC": "Gżejjer Cocos (Keeling)",
    "CO": "il-Kolombja",
    "KM": "Comoros",
    "CG": "il-Kongo - Brazzaville",
    "CD": "ir-Repubblika Demokratika tal-Kongo",
    "CK": "Gżejjer Cook",
    "CR": "il-Costa Rica",
    "CI": "il-Kosta tal-Avorju",
    "HR": "il-Kroazja",
    "CU": "Kuba",
    "CY": "Ċipru",
    "CZ": "ir-Repubblika Ċeka",
    "DK": "id-Danimarka",
    "DJ": "il-Djibouti",
    "DM": "Dominica",
    "DO": "ir-Repubblika Dominicana",
    "EC": "l-Ekwador",
    "EG": "l-Eġittu",
    "SV": "El Salvador",
    "GQ": "il-Guinea Ekwatorjali",
    "ER": "l-Eritrea",
    "EE": "l-Estonja",
    "ET": "l-Etjopja",
    "FK": "il-Gżejjer Falkland",
    "FO": "il-Gżejjer Faeroe",
    "FJ": "Fiġi",
    "FI": "il-Finlandja",
    "FR": "Franza",
    "GF": "il-Guyana Franċiża",
    "PF": "Polineżja Franċiża",
    "TF": "It-Territorji Franċiżi tan-Nofsinhar",
    "GA": "il-Gabon",
    "GM": "il-Gambja",
    "GE": "il-Georgia",
    "DE": "il-Ġermanja",
    "GH": "il-Ghana",
    "GI": "Ġibiltà",
    "GR": "il-Greċja",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "il-Gwatemala",
    "GN": "il-Guinea",
    "GW": "il-Guinea-Bissau",
    "GY": "il-Guyana",
    "HT": "il-Haiti",
    "HM": "il-Gżejjer Heard u l-Gżejjer McDonald",
    "VA": "l-Istat tal-Belt tal-Vatikan",
    "HN": "il-Honduras",
    "HK": "ir-Reġjun Amministrattiv Speċjali ta’ Hong Kong tar-Repubblika tal-Poplu taċ-Ċina",
    "HU": "l-Ungerija",
    "IS": "l-Iżlanda",
    "IN": "l-Indja",
    "ID": "l-Indoneżja",
    "IR": "l-Iran",
    "IQ": "l-Iraq",
    "IE": "l-Irlanda",
    "IL": "Iżrael",
    "IT": "l-Italja",
    "JM": "il-Ġamajka",
    "JP": "il-Ġappun",
    "JO": "il-Ġordan",
    "KZ": "il-Każakistan",
    "KE": "il-Kenja",
    "KI": "Kiribati",
    "KP": "il-Korea ta’ Fuq",
    "KR": "il-Korea t’Isfel",
    "KW": "il-Kuwajt",
    "KG": "il-Kirgiżistan",
    "LA": "il-Laos",
    "LV": "il-Latvja",
    "LB": "il-Libanu",
    "LS": "il-Lesoto",
    "LR": "il-Liberja",
    "LY": "il-Libja",
    "LI": "il-Liechtenstein",
    "LT": "il-Litwanja",
    "LU": "il-Lussemburgu",
    "MO": "ir-Reġjun Amministrattiv Speċjali tal-Macao tar-Repubblika tal-Poplu taċ-Ċina",
    "MG": "Madagascar",
    "MW": "il-Malawi",
    "MY": "il-Malasja",
    "MV": "il-Maldivi",
    "ML": "il-Mali",
    "MT": "Malta",
    "MH": "Gżejjer Marshall",
    "MQ": "Martinique",
    "MR": "il-Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "il-Messiku",
    "FM": "il-Mikroneżja",
    "MD": "il-Moldova",
    "MC": "Monaco",
    "MN": "il-Mongolja",
    "MS": "Montserrat",
    "MA": "il-Marokk",
    "MZ": "il-Mozambique",
    "MM": "il-Myanmar/Burma",
    "NA": "in-Namibja",
    "NR": "Nauru",
    "NP": "in-Nepal",
    "NL": "in-Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "in-Nikaragwa",
    "NE": "in-Niġer",
    "NG": "in-Niġerja",
    "NU": "Niue",
    "NF": "Gżira Norfolk",
    "MK": "il-Maċedonja ta’ Fuq",
    "MP": "Ġżejjer Mariana tat-Tramuntana",
    "NO": "in-Norveġja",
    "OM": "l-Oman",
    "PK": "il-Pakistan",
    "PW": "Palau",
    "PS": "it-Territorji Palestinjani",
    "PA": "il-Panama",
    "PG": "Papua New Guinea",
    "PY": "il-Paragwaj",
    "PE": "il-Perù",
    "PH": "il-Filippini",
    "PN": "Gżejjer Pitcairn",
    "PL": "il-Polonja",
    "PT": "il-Portugall",
    "PR": "Puerto Rico",
    "QA": "il-Qatar",
    "RE": "Réunion",
    "RO": "ir-Rumanija",
    "RU": "ir-Russja",
    "RW": "ir-Rwanda",
    "SH": "Saint Helena",
    "KN": "Saint Kitts u Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre u Miquelon",
    "VC": "Saint Vincent u l-Grenadini",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé u Príncipe",
    "SA": "l-Arabja Sawdija",
    "SN": "is-Senegal",
    "SC": "is-Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "is-Slovakkja",
    "SI": "is-Slovenja",
    "SB": "il-Gżejjer Solomon",
    "SO": "is-Somalja",
    "ZA": "l-Afrika t’Isfel",
    "GS": "il-Georgia tan-Nofsinhar u l-Gżejjer Sandwich tan-Nofsinhar",
    "ES": "Spanja",
    "LK": "is-Sri Lanka",
    "SD": "is-Sudan",
    "SR": "is-Suriname",
    "SJ": "Svalbard u Jan Mayen",
    "SZ": "l-Eswatini",
    "SE": "l-Iżvezja",
    "CH": "l-Iżvizzera",
    "SY": "is-Sirja",
    "TW": "it-Tajwan",
    "TJ": "it-Taġikistan",
    "TZ": "it-Tanzanija",
    "TH": "it-Tajlandja",
    "TL": "Timor Leste",
    "TG": "it-Togo",
    "TK": "it-Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad u Tobago",
    "TN": "it-Tuneżija",
    "TR": "it-Turkija",
    "TM": "it-Turkmenistan",
    "TC": "il-Gżejjer Turks u Caicos",
    "TV": "Tuvalu",
    "UG": "l-Uganda",
    "UA": "l-Ukrajna",
    "AE": "l-Emirati Għarab Magħquda",
    "GB": "ir-Renju Unit",
    "US": ["l-Istati Uniti", "l-Istati Uniti tal-Amerka"],
    "UM": "Il-Gżejjer Minuri Mbiegħda tal-Istati Uniti",
    "UY": "l-Urugwaj",
    "UZ": "l-Użbekistan",
    "VU": "Vanuatu",
    "VE": "il-Venezwela",
    "VN": "il-Vjetnam",
    "VG": "il-Gżejjer Verġni Brittaniċi",
    "VI": "il-Gżejjer Verġni tal-Istati Uniti",
    "WF": "Wallis u Futuna",
    "EH": "is-Saħara tal-Punent",
    "YE": "il-Jemen",
    "ZM": "iż-Żambja",
    "ZW": "iż-Żimbabwe",
    "AX": "il-Gżejjer Aland",
    "BQ": "in-Netherlands tal-Karibew",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Isle of Man",
    "JE": "Jersey",
    "ME": "il-Montenegro",
    "BL": "Saint Barthélemy",
    "MF": "Saint Martin",
    "RS": "is-Serbja",
    "SX": "Sint Maarten",
    "SS": "is-Sudan t’Isfel",
    "XK": "il-Kosovo"
  }
}
