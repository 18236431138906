{
  "locale": "so",
  "countries": {
    "AF": "Afgaanistaan",
    "AL": "Albaaniya",
    "DZ": "Aljeeriya",
    "AS": "Samowa Ameerika",
    "AD": "Andora",
    "AO": "Angoola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua iyo Barbuda",
    "AR": "Arjantiin",
    "AM": "Armeeniya",
    "AW": "Aruba",
    "AU": "Awstaraaliya",
    "AT": "Awsteriya",
    "AZ": "Azerbajaan",
    "BS": "Bahaamas",
    "BH": "Baxreyn",
    "BD": "Bangaaladheesh",
    "BB": "Baarbadoos",
    "BY": "Belarus",
    "BE": "Biljam",
    "BZ": "Belize",
    "BJ": "Biniin",
    "BM": "Bermuuda",
    "BT": "Bhutan",
    "BO": "Boliifiya",
    "BA": "Bosniya Hersigoviina",
    "BW": "Botuswaana",
    "BV": "Jasiiradda Bouvet",
    "BR": "Braasiil",
    "IO": "British Indian Ocean Territory",
    "BN": "Buruneeya",
    "BG": "Bulgaariya",
    "BF": "Burkiina Faaso",
    "BI": "Burundi",
    "KH": "Kamboodiya",
    "CM": "Kaameruun",
    "CA": "Kanada",
    "CV": "Cape Verde Islands",
    "KY": "Cayman Islands",
    "CF": "Jamhuuriyadda Afrikada Dhexe",
    "TD": "Jaad",
    "CL": "Jili",
    "CN": "Shiinaha",
    "CX": "Jasiiradda Kirismaska",
    "CC": "Jasiiradaha Cocos (Keeling)",
    "CO": "Kolombiya",
    "KM": "Komooros",
    "CG": "Kongo",
    "CD": "Jamhuuriyadda Dimuquraadiga Kongo",
    "CK": "Jaziiradda Cook",
    "CR": "Kosta Riika",
    "CI": "Ivory coast",
    "HR": "Korweeshiya",
    "CU": "Kuuba",
    "CY": "Qubrus",
    "CZ": "Jamhuuriyadda Jek",
    "DK": "Denmark",
    "DJ": "Jabuuti",
    "DM": "Domeenika",
    "DO": "Jamhuuriyadda Domeenika",
    "EC": "Ikuwadoor",
    "EG": "Masar",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eretereeya",
    "EE": "Estooniya",
    "ET": "Itoobiya",
    "FK": "Jaziiradaha Fooklaan",
    "FO": "Jasiiradaha Faroe",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "Faransiis",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "Gobollada Koofureed ee Faransiiska",
    "GA": "Gaaboon",
    "GM": "Gambiya",
    "GE": "Joorjiya",
    "DE": "Jarmal",
    "GH": "Gaana",
    "GI": "Gibraltar",
    "GR": "Giriig",
    "GL": "Greenland",
    "GD": "Giriinaada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guwaatamaala",
    "GN": "Gini",
    "GW": "Gini-Bisaaw",
    "GY": "Guyana",
    "HT": "Hayti",
    "HM": "Jasiiradaha Heard iyo McDonald Islands",
    "VA": "Faatikaan",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hangeri",
    "IS": "Iislaand",
    "IN": "Hindiya",
    "ID": "Indoneesiya",
    "IR": "Iiraan",
    "IQ": "Ciraaq",
    "IE": "Ayrlaand",
    "IL": "Israaʼiil",
    "IT": "Talyaani",
    "JM": "Jameyka",
    "JP": "Jabaan",
    "JO": "Urdun",
    "KZ": "Kasaakhistaan",
    "KE": "Kiiniya",
    "KI": "Kiribati",
    "KP": "Kuuriyada Waqooyi",
    "KR": "Kuuriyada Koonfureed",
    "KW": "Kuwayt",
    "KG": "Kirgistaan",
    "LA": "Laos",
    "LV": "Latfiya",
    "LB": "Lubnaan",
    "LS": "Losooto",
    "LR": "Laybeeriya",
    "LY": "Liibiya",
    "LI": "Liechtenstein",
    "LT": "Lituweeniya",
    "LU": "Luksemboorg",
    "MO": "Macao",
    "MG": "Madagaskar",
    "MW": "Malaawi",
    "MY": "Malaysia",
    "MV": "Maaldiqeen",
    "ML": "Maali",
    "MT": "Maalda",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Muritaaniya",
    "MU": "Murishiyoos",
    "YT": "Mayotte",
    "MX": "Meksiko",
    "FM": "Micronesia",
    "MD": "Moldofa",
    "MC": "Moonako",
    "MN": "Mongooliya",
    "MS": "Montserrat",
    "MA": "Marooko",
    "MZ": "Musambiig",
    "MM": "Myanmar",
    "NA": "Namiibiya",
    "NR": "Nauru",
    "NP": "Nebaal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "Neyuusilaand",
    "NI": "Nikaraaguwa",
    "NE": "Nayjer",
    "NG": "Nayjeeriya",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MK": "Makadooniya",
    "MP": "Northern Mariana Islands",
    "NO": "Noorweey",
    "OM": "Cumaan",
    "PK": "Bakistaan",
    "PW": "Palau",
    "PS": "Falastiin Daanka galbeed iyo Qasa",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Filibiin",
    "PN": "Pitcairn",
    "PL": "Booland",
    "PT": "Bortuqaal",
    "PR": "Puerto Rico",
    "QA": "Qadar",
    "RE": "Réunion",
    "RO": "Rumaaniya",
    "RU": "Ruush",
    "RW": "Ruwanda",
    "SH": "Saint Helena",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé and Príncipe",
    "SA": "Sacuudi Carabiya",
    "SN": "Sinigaal",
    "SC": "Sishelis",
    "SL": "Siraaliyoon",
    "SG": "Singaboor",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Soomaaliya",
    "ZA": "Koonfur Afrika",
    "GS": "Koonfurta Georgia iyo Koonfurta Sandwich Islands",
    "ES": "Isbeyn",
    "LK": "Sirilaanka",
    "SD": "Suudaan",
    "SR": "Suriname",
    "SJ": "Svalbard iyo Jan Mayen",
    "SZ": "Iswaasilaand",
    "SE": "Iswidhan",
    "CH": "Swiiserlaand",
    "SY": "Suuriya",
    "TW": "Taywaan",
    "TJ": "Tajikistan",
    "TZ": "Tansaaniya",
    "TH": "Taylaand",
    "TL": "Timorka bari",
    "TG": "Toogo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tuniisiya",
    "TR": "Turki",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Ugaanda",
    "UA": "Ukrayn",
    "AE": "Imaaraadka Carabta ee Midoobay",
    "GB": "United Kingdom",
    "US": "Maraykanka",
    "UM": "Jasiiradaha yaryar ee ka baxsan Mareykanka",
    "UY": "Uruguwaay",
    "UZ": "Uusbakistaan",
    "VU": "Vanuatu",
    "VE": "Fenisuweela",
    "VN": "Fiyetnaam",
    "VG": "British Virgin Islands",
    "VI": "U.S. Virgin Islands",
    "WF": "Wallis and Futuna",
    "EH": "Saxaraha Galbeed",
    "YE": "Yaman",
    "ZM": "Saambiya",
    "ZW": "Simbaabwe",
    "AX": "Jasiiradaha Åland",
    "BQ": "Bonaire, Sint Eustatius iyo Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Jasiiradda Man",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Saint Barthélemy",
    "MF": "Saint Martin (qayb Faransiis ah)",
    "RS": "Serbia",
    "SX": "Sint Maarten (Qaybta Nederlandka)",
    "SS": "Koonfur Suudaan",
    "XK": "Kosovo"
  }
}
