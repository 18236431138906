{
  "locale": "ku",
  "countries": {
    "AF": "Efxanistan",
    "AL": "Albanya",
    "DZ": "Cezayir",
    "AS": "Samoaya Amerîkanî",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguîla",
    "AQ": "Antarktîka",
    "AG": "Antîgua û Berbûda",
    "AR": "Arjentîn",
    "AM": "Ermenistan",
    "AW": "Arûba",
    "AU": "Awistralya",
    "AT": "Awistirya",
    "AZ": "Azerbaycan",
    "BS": "Bahama",
    "BH": "Behreyn",
    "BD": "Bangladeş",
    "BB": "Barbados",
    "BY": "Belarûs",
    "BE": "Belçîka",
    "BZ": "Belîze",
    "BJ": "Bênîn",
    "BM": "Bermûda",
    "BT": "Bûtan",
    "BO": "Bolîvya",
    "BA": "Bosniya û Herzegovîna",
    "BW": "Botswana",
    "BV": "Girava Bouvet",
    "BR": "Brazîl",
    "IO": "Erdê Okyanûsa Hindî ya Brîtanî",
    "BN": "Brûney",
    "BG": "Bulgaristan",
    "BF": "Burkîna Faso",
    "BI": "Burundî",
    "KH": "Kamboca",
    "CM": "Kamerûn",
    "CA": "Kanada",
    "CV": "Kap Verde",
    "KY": "Giravên Kaymanê",
    "CF": "Komara Afrîkaya Navend",
    "TD": "Çad",
    "CL": "Şîle",
    "CN": "Çîn",
    "CX": "Girava Sersalê",
    "CC": "Giravên Cocos (Keeling)",
    "CO": "Kolombiya",
    "KM": "Komor",
    "CG": "Kongo - Brazzaville",
    "CD": "Kongo - Kînşasa",
    "CK": "Giravên Cook",
    "CR": "Kosta Rîka",
    "CI": "Peravê Diranfîl",
    "HR": "Kroatya",
    "CU": "Kûba",
    "CY": "Kîpros",
    "CZ": "Çekya",
    "DK": "Danîmarka",
    "DJ": "Cîbûtî",
    "DM": "Domînîka",
    "DO": "Komara Domînîk",
    "EC": "Ekuador",
    "EG": "Misir",
    "SV": "El Salvador",
    "GQ": "Gîneya Rojbendî",
    "ER": "Erîtrea",
    "EE": "Estonya",
    "ET": "Etiyopya",
    "FK": "Giravên Malvîn",
    "FO": "Giravên Feroe",
    "FJ": "Fîjî",
    "FI": "Fînlenda",
    "FR": "Fransa",
    "GF": "Guyanaya Fransî",
    "PF": "Polînezyaya Fransî",
    "TF": "Erdên Başûr ên Fransî",
    "GA": "Gabon",
    "GM": "Gambiya",
    "GE": "Gurcistan",
    "DE": "Almanya",
    "GH": "Gana",
    "GI": "Cîbraltar",
    "GR": "Yewnanistan",
    "GL": "Grînlenda",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Gîne",
    "GW": "Gîne-Bissau",
    "GY": "Guyana",
    "HT": "Haîtî",
    "HM": "Girava Heard û Giravên McDonald",
    "VA": "Vatîkan",
    "HN": "Hondûras",
    "HK": "Hong Kong",
    "HU": "Macaristan",
    "IS": "Îslenda",
    "IN": "Hindistan",
    "ID": "Îndonezya",
    "IR": "Îran",
    "IQ": "Iraq",
    "IE": "Îrlenda",
    "IL": "Îsraêl",
    "IT": "Îtalya",
    "JM": "Jamaîka",
    "JP": "Japon",
    "JO": "Urdun",
    "KZ": "Qazaxistan",
    "KE": "Kenya",
    "KI": "Kirîbatî",
    "KP": "Korêya Bakur",
    "KR": "Korêya Başûr",
    "KW": "Kuweyt",
    "KG": "Qirgizistan",
    "LA": "Laos",
    "LV": "Letonya",
    "LB": "Libnan",
    "LS": "Lesoto",
    "LR": "Lîberya",
    "LY": "Lîbya",
    "LI": "Liechtenstein",
    "LT": "Lîtvanya",
    "LU": "Lûksembûrg",
    "MO": "Macao",
    "MG": "Madagaskar",
    "MW": "Malawî",
    "MY": "Malezya",
    "MV": "Maldîv",
    "ML": "Malî",
    "MT": "Malta",
    "MH": "Giravên Marşal",
    "MQ": "Martinique",
    "MR": "Morîtanya",
    "MU": "Maurîtius",
    "YT": "Mayotte",
    "MX": "Meksîk",
    "FM": "Mîkronezya",
    "MD": "Moldova",
    "MC": "Monako",
    "MN": "Mongolya",
    "MS": "Montserat",
    "MA": "Maroko",
    "MZ": "Mozambîk",
    "MM": "Myanmar (Birmanya)",
    "NA": "Namîbya",
    "NR": "Naûrû",
    "NP": "Nepal",
    "NL": "Holenda",
    "NC": "Kaledonyaya Nû",
    "NZ": "Nû Zelenda",
    "NI": "Nîkaragua",
    "NE": "Nîjer",
    "NG": "Nîjerya",
    "NU": "Niûe",
    "NF": "Girava Norfolk",
    "MK": "Makedonya",
    "MP": "Giravên Bakurê Marianan",
    "NO": "Norwêc",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Xakên filistînî",
    "PA": "Panama",
    "PG": "Papua Gîneya Nû",
    "PY": "Paraguay",
    "PE": "Perû",
    "PH": "Filîpîn",
    "PN": "Giravên Pitcairn",
    "PL": "Polonya",
    "PT": "Portûgal",
    "PR": "Porto Rîko",
    "QA": "Qeter",
    "RE": "Réunion",
    "RO": "Romanya",
    "RU": "Rûsya",
    "RW": "Rwanda",
    "SH": "Ezîze Helena",
    "KN": "Saint Kitts û Nevîs",
    "LC": "Saint Lucia",
    "PM": "Saint-Pierre û Miquelon",
    "VC": "Saint Vincent û Giravên Grenadîn",
    "WS": "Samoa",
    "SM": "San Marîno",
    "ST": "Sao Tome û Prînsîpe",
    "SA": "Erebistana Siyûdî",
    "SN": "Senegal",
    "SC": "Seyşel",
    "SL": "Sierra Leone",
    "SG": "Singapûr",
    "SK": "Slovakya",
    "SI": "Slovenya",
    "SB": "Giravên Salomon",
    "SO": "Somalya",
    "ZA": "Afrîkaya Başûr",
    "GS": "South Georgia û Giravên Sandwich-a Başûr",
    "ES": "Spanya",
    "LK": "Srî Lanka",
    "SD": "Sûdan",
    "SR": "Sûrînam",
    "SJ": "Svalbard û Jan Mayen",
    "SZ": "Swazîlenda",
    "SE": "Swêd",
    "CH": "Swîsre",
    "SY": "Sûrî",
    "TW": "Taywan",
    "TJ": "Tacîkistan",
    "TZ": "Tanzanya",
    "TH": "Taylenda",
    "TL": "Tîmora-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trînîdad û Tobago",
    "TN": "Tûnis",
    "TR": "Tirkiye",
    "TM": "Tirkmenistan",
    "TC": "Giravên Turk û Kaîkos",
    "TV": "Tûvalû",
    "UG": "Ûganda",
    "UA": "Ûkrayna",
    "AE": "Emîrtiyên Erebî yên Yekbûyî",
    "GB": "Keyaniya Yekbûyî",
    "US": "Dewletên Yekbûyî yên Amerîkayê",
    "UM": "Giravên Derveyî Piçûk ên Dewletên Yekbûyî",
    "UY": "Ûrûguay",
    "UZ": "Ûzbêkistan",
    "VU": "Vanûatû",
    "VE": "Venezuela",
    "VN": "Vîetnam",
    "VG": "Giravên Virgin, Brîtanî",
    "VI": "Giravên Virgin, U.S.",
    "WF": "Wallis û Futuna",
    "EH": "Sahraya Rojava",
    "YE": "Yemen",
    "ZM": "Zambiya",
    "ZW": "Zîmbabwe",
    "AX": "Giravên Åland",
    "BQ": "Bonaire, Sint Eustatius û Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Girava Man",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Saint-Barthélemy",
    "MF": "Saint Martin (beşa fransî)",
    "RS": "Serbistan",
    "SX": "Sint Maarten (Beşa Hollandî)",
    "SS": "Sûdana Başûr",
    "XK": "Kosova"
  }
}
