{
  "locale": "ha",
  "countries": {
    "AF": "Afaganistan",
    "AL": "Albaniya",
    "DZ": "Aljeriya",
    "AS": "Samowa Ta Amurka",
    "AD": "Andora",
    "AO": "Angola",
    "AI": "Angila",
    "AQ": "Antarctica",
    "AG": "Antigwa da Barbuba",
    "AR": "Arjantiniya",
    "AM": "Armeniya",
    "AW": "Aruba",
    "AU": "Ostareliya",
    "AT": "Ostiriya",
    "AZ": "Azarbaijan",
    "BS": "Bahamas",
    "BH": "Baharan",
    "BD": "Bangiladas",
    "BB": "Barbadas",
    "BY": "Belarus",
    "BE": "Belgiyom",
    "BZ": "Beliz",
    "BJ": "Binin",
    "BM": "Barmuda",
    "BT": "Butan",
    "BO": "Bolibiya",
    "BA": "Bosniya Harzagobina",
    "BW": "Baswana",
    "BV": "Tsibirin Bouvet",
    "BR": "Birazil",
    "IO": "Yankin Birtaniya Na Tekun Indiya",
    "BN": "Burune",
    "BG": "Bulgariya",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Kambodiya",
    "CM": "Kamaru",
    "CA": "Kanada",
    "CV": "Tsibiran Kap Barde",
    "KY": "Tsibiran Kaiman",
    "CF": "Jamhuriyar Afirka Ta Tsakiya",
    "TD": "Cadi",
    "CL": "Cayile",
    "CN": "Caina, Sin",
    "CX": "Tsibirin Kirsimeti",
    "CC": "Tsibiran Cocos (Keeling)",
    "CO": "Kolambiya",
    "KM": "Kwamoras",
    "CG": "Kongo",
    "CD": "Jamhuriyar Dimokuraɗiyyar Kongo",
    "CK": "Tsibiran Kuku",
    "CR": "Kwasta Rika",
    "CI": "Aibari Kwas",
    "HR": "Kurowaishiya",
    "CU": "Kyuba",
    "CY": "Sifurus",
    "CZ": "Jamhuriyar Cak",
    "DK": "Danmark",
    "DJ": "Jibuti",
    "DM": "Dominika",
    "DO": "Jamhuriyar Dominika",
    "EC": "Ekwador",
    "EG": "Masar, Misira",
    "SV": "El Salbador",
    "GQ": "Gini Ta Ikwaita",
    "ER": "Eritireya",
    "EE": "Estoniya",
    "ET": "Habasha",
    "FK": "Tsibiran Falkilan",
    "FO": "Tsibirin Faroe",
    "FJ": "Fiji",
    "FI": "Finlan",
    "FR": "Faransa",
    "GF": "Gini Ta Faransa",
    "PF": "Folinesiya Ta Faransa",
    "TF": "Southernasashen Kudancin Faransa",
    "GA": "Gabon",
    "GM": "Gambiya",
    "GE": "Jiwarjiya",
    "DE": "Jamus",
    "GH": "Gana",
    "GI": "Jibaraltar",
    "GR": "Girka",
    "GL": "Grinlan",
    "GD": "Girnada",
    "GP": "Gwadaluf",
    "GU": "Gwam",
    "GT": "Gwatamala",
    "GN": "Gini",
    "GW": "Gini Bisau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Tsibirin Heard da McDonald",
    "VA": "Batikan",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungari",
    "IS": "Aisalan",
    "IN": "Indiya",
    "ID": "Indunusiya",
    "IR": "Iran",
    "IQ": "Iraƙi",
    "IE": "Ayalan",
    "IL": "Iziraʼila",
    "IT": "Italiya",
    "JM": "Jamaika",
    "JP": "Japan",
    "JO": "Jordan",
    "KZ": "Kazakistan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Koreya Ta Arewa",
    "KR": "Koreya Ta Kudu",
    "KW": "Kwiyat",
    "KG": "Kirgizistan",
    "LA": "Lawas",
    "LV": "latibiya",
    "LB": "Labanan",
    "LS": "Lesoto",
    "LR": "Laberiya",
    "LY": "Libiya",
    "LI": "Licansitan",
    "LT": "Lituweniya",
    "LU": "Lukusambur",
    "MO": "Macao",
    "MG": "Madagaskar",
    "MW": "Malawi",
    "MY": "Malaisiya",
    "MV": "Maldibi",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Tsibiran Marshal",
    "MQ": "Martinik",
    "MR": "Moritaniya",
    "MU": "Moritus",
    "YT": "Mayoti",
    "MX": "Makasiko",
    "FM": "Mikuronesiya",
    "MD": "Maldoba",
    "MC": "Monako",
    "MN": "Mangoliya",
    "MS": "Manserati",
    "MA": "Maroko",
    "MZ": "Mozambik",
    "MM": "Burma, Miyamar",
    "NA": "Namibiya",
    "NR": "Nauru",
    "NP": "Nefal",
    "NL": "Holan",
    "NC": "Kaledoniya Sabuwa",
    "NZ": "Nuzilan",
    "NI": "Nikaraguwa",
    "NE": "Nijar",
    "NG": "Najeriya",
    "NU": "Niyu",
    "NF": "Tsibirin Narfalk",
    "MK": "Masedoniya",
    "MP": "Tsibiran Mariyana Na Arewa",
    "NO": "Norwe",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palasɗinu",
    "PA": "Panama",
    "PG": "Papuwa Nugini",
    "PY": "Paragai",
    "PE": "Peru",
    "PH": "Filipin",
    "PN": "Pitakarin",
    "PL": "Polan",
    "PT": "Portugal",
    "PR": "Porto Riko",
    "QA": "Kwatar",
    "RE": "Rawuniyan",
    "RO": "Romaniya",
    "RU": "Rasha",
    "RW": "Ruwanda",
    "SH": "San Helena",
    "KN": "San Kiti Da Nebis",
    "LC": "San Lusiya",
    "PM": "San Piyar Da Mikelan",
    "VC": "San Binsan Da Girnadin",
    "WS": "Samowa",
    "SM": "San Marino",
    "ST": "Sawo Tome Da Paransip",
    "SA": "Ƙasar Makka",
    "SN": "Sinigal",
    "SC": "Saishal",
    "SL": "Salewo",
    "SG": "Singapur",
    "SK": "Sulobakiya",
    "SI": "Sulobeniya",
    "SB": "Tsibiran Salaman",
    "SO": "Somaliya",
    "ZA": "Afirka Ta Kudu",
    "GS": "Kudancin Georgia da Kudancin Sandwich Island",
    "ES": "Sipen",
    "LK": "Siri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard da Jan Mayen",
    "SZ": "Suwazilan",
    "SE": "Suwedan",
    "CH": "Suwizalan",
    "SY": "Sham, Siriya",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzaniya",
    "TH": "Tailan",
    "TL": "Timor Ta Gabas",
    "TG": "Togo",
    "TK": "Takelau",
    "TO": "Tanga",
    "TT": "Tirinidad Da Tobago",
    "TN": "Tunisiya",
    "TR": "Turkiyya",
    "TM": "Turkumenistan",
    "TC": "Turkis Da Tsibiran Kaikwas",
    "TV": "Tubalu",
    "UG": "Yuganda",
    "UA": "Yukaran",
    "AE": "Haɗaɗɗiyar Daular Larabawa",
    "GB": "Birtaniya",
    "US": "Amurka",
    "UM": "Kananan Tsibiran Amurka",
    "UY": "Yurugai",
    "UZ": "Uzubekistan",
    "VU": "Banuwatu",
    "VE": "Benezuwela",
    "VN": "Biyetinam",
    "VG": "Tsibirin Birjin Na Birtaniya",
    "VI": "Tsibiran Birjin Ta Amurka",
    "WF": "Walis Da Futuna",
    "EH": "Yammacin Sahara",
    "YE": "Yamal",
    "ZM": "Zambiya",
    "ZW": "Zimbabuwe",
    "AX": "Tsibirin Åland",
    "BQ": "Bonaire, Sint Eustatius da Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Isle na Man",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Saint Barthélemy",
    "MF": "Saint Martin (Bangaren Faransa)",
    "RS": "Sabiya",
    "SX": "Sint Maarten (Sashin Yaren mutanen Holland)",
    "SS": "Sudan ta Kudu",
    "XK": "Kosovo"
  }
}
