{
  "locale": "ro",
  "countries": {
    "AD": "Andorra",
    "AE": "Emiratele Arabe Unite",
    "AF": "Afganistan",
    "AG": "Antigua și Barbuda",
    "AI": "Anguilla",
    "AL": "Albania",
    "AM": "Armenia",
    "AO": "Angola",
    "AQ": "Antarctica",
    "AR": "Argentina",
    "AS": "Samoa Americană",
    "AT": "Austria",
    "AU": "Australia",
    "AW": "Aruba",
    "AX": "Insulele Åland",
    "AZ": "Azerbaidjan",
    "BA": "Bosnia și Herțegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "Belgia",
    "BF": "Burkina Faso",
    "BG": "Bulgaria",
    "BH": "Bahrain",
    "BI": "Burundi",
    "BJ": "Benin",
    "BL": "Sfântul Bartolomeu",
    "BM": "Bermuda",
    "BN": "Brunei",
    "BO": "Bolivia",
    "BQ": "Insulele Caraibe Olandeze",
    "BR": "Brazilia",
    "BS": "Bahamas",
    "BT": "Bhutan",
    "BV": "Insula Bouvet",
    "BW": "Botswana",
    "BY": "Belarus",
    "BZ": "Belize",
    "CA": "Canada",
    "CC": "Insulele Cocos (Keeling)",
    "CD": "Congo - Kinshasa",
    "CF": "Republica Centrafricană",
    "CG": "Congo - Brazzaville",
    "CH": "Elveția",
    "CI": "Côte d’Ivoire",
    "CK": "Insulele Cook",
    "CL": "Chile",
    "CM": "Camerun",
    "CN": "China",
    "CO": "Columbia",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CV": "Capul Verde",
    "CW": "Curaçao",
    "CX": "Insula Christmas",
    "CY": "Cipru",
    "CZ": "Cehia",
    "DE": "Germania",
    "DJ": "Djibouti",
    "DK": "Danemarca",
    "DM": "Dominica",
    "DO": "Republica Dominicană",
    "DZ": "Algeria",
    "EC": "Ecuador",
    "EE": "Estonia",
    "EG": "Egipt",
    "EH": "Sahara Occidentală",
    "ER": "Eritreea",
    "ES": "Spania",
    "ET": "Etiopia",
    "FI": "Finlanda",
    "FJ": "Fiji",
    "FK": "Insulele Falkland",
    "FM": "Micronezia",
    "FO": "Insulele Feroe",
    "FR": "Franța",
    "GA": "Gabon",
    "GB": "Regatul Unit",
    "GD": "Grenada",
    "GE": "Georgia",
    "GF": "Guyana Franceză",
    "GG": "Guernsey",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GL": "Groenlanda",
    "GM": "Gambia",
    "GN": "Guineea",
    "GP": "Guadelupa",
    "GQ": "Guineea Ecuatorială",
    "GR": "Grecia",
    "GS": "Georgia de Sud și Insulele Sandwich de Sud",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guineea-Bissau",
    "GY": "Guyana",
    "HK": "R.A.S. Hong Kong a Chinei",
    "HM": "Insula Heard și Insulele McDonald",
    "HN": "Honduras",
    "HR": "Croația",
    "HT": "Haiti",
    "HU": "Ungaria",
    "ID": "Indonezia",
    "IE": "Irlanda",
    "IL": "Israel",
    "IM": "Insula Man",
    "IN": "India",
    "IO": "Teritoriul Britanic din Oceanul Indian",
    "IQ": "Irak",
    "IR": "Iran",
    "IS": "Islanda",
    "IT": "Italia",
    "JE": "Jersey",
    "JM": "Jamaica",
    "JO": "Iordania",
    "JP": "Japonia",
    "KE": "Kenya",
    "KG": "Kârgâzstan",
    "KH": "Cambodgia",
    "KI": "Kiribati",
    "KM": "Comore",
    "KN": "Saint Kitts și Nevis",
    "KP": "Coreea de Nord",
    "KR": "Coreea de Sud",
    "KW": "Kuweit",
    "KY": "Insulele Cayman",
    "KZ": "Kazahstan",
    "LA": "Laos",
    "LB": "Liban",
    "LC": "Sfânta Lucia",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Liberia",
    "LS": "Lesotho",
    "LT": "Lituania",
    "LU": "Luxemburg",
    "LV": "Letonia",
    "LY": "Libia",
    "MA": "Maroc",
    "MC": "Monaco",
    "MD": "Republica Moldova",
    "ME": "Muntenegru",
    "MF": "Sfântul Martin",
    "MG": "Madagascar",
    "MH": "Insulele Marshall",
    "MK": "Macedonia de Nord",
    "ML": "Mali",
    "MM": "Myanmar",
    "MN": "Mongolia",
    "MO": "R.A.S. Macao a Chinei",
    "MP": "Insulele Mariane de Nord",
    "MQ": "Martinica",
    "MR": "Mauritania",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Mauritius",
    "MV": "Maldive",
    "MW": "Malawi",
    "MX": "Mexic",
    "MY": "Malaysia",
    "MZ": "Mozambic",
    "NA": "Namibia",
    "NC": "Noua Caledonie",
    "NE": "Niger",
    "NF": "Insula Norfolk",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Țările de Jos",
    "NO": "Norvegia",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Noua Zeelandă",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Polinezia Franceză",
    "PG": "Papua-Noua Guinee",
    "PH": "Filipine",
    "PK": "Pakistan",
    "PL": "Polonia",
    "PM": "Saint-Pierre și Miquelon",
    "PN": "Insulele Pitcairn",
    "PR": "Puerto Rico",
    "PS": "Teritoriile Palestiniene",
    "PT": "Portugalia",
    "PW": "Palau",
    "PY": "Paraguay",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "România",
    "RS": "Serbia",
    "RU": "Rusia",
    "RW": "Rwanda",
    "SA": "Arabia Saudită",
    "SB": "Insulele Solomon",
    "SC": "Seychelles",
    "SD": "Sudan",
    "SE": "Suedia",
    "SG": "Singapore",
    "SH": "Sfânta Elena",
    "SI": "Slovenia",
    "SJ": "Svalbard și Jan Mayen",
    "SK": "Slovacia",
    "SL": "Sierra Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SO": "Somalia",
    "SR": "Suriname",
    "SS": "Sudanul de Sud",
    "ST": "Sao Tomé și Príncipe",
    "SV": "El Salvador",
    "SX": "Sint-Maarten",
    "SY": "Siria",
    "SZ": "Eswatini",
    "TC": "Insulele Turks și Caicos",
    "TD": "Ciad",
    "TF": "Teritoriile Australe și Antarctice Franceze",
    "TG": "Togo",
    "TH": "Thailanda",
    "TJ": "Tadjikistan",
    "TK": "Tokelau",
    "TL": "Timorul de Est",
    "TM": "Turkmenistan",
    "TN": "Tunisia",
    "TO": "Tonga",
    "TR": "Turcia",
    "TT": "Trinidad și Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "TZ": "Tanzania",
    "UA": "Ucraina",
    "UG": "Uganda",
    "UM": "Insulele Îndepărtate ale S.U.A.",
    "US": "Statele Unite ale Americii",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VA": "Statul Cetății Vaticanului",
    "VC": "Saint Vincent și Grenadinele",
    "VE": "Venezuela",
    "VG": "Insulele Virgine Britanice",
    "VI": "Insulele Virgine Americane",
    "VN": "Vietnam",
    "VU": "Vanuatu",
    "WF": "Wallis și Futuna",
    "WS": "Samoa",
    "XK": "Kosovo",
    "YE": "Yemen",
    "YT": "Mayotte",
    "ZA": "Africa de Sud",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  }
}
