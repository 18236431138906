import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';

import { IconSizes } from '@shared/models';

@Component({
  selector: 'ui-icon',
  standalone: true,
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @HostBinding('class.ui-icon') hostClass = true;
  @HostBinding('class') get hostClassSize(): IconSizes {
    return this.size();
  }

  /**
   * Corresponds to svg file name inside src/assets/icons
   */
  iconName = input.required<string>();
  size = input<IconSizes>('m');
}
