import { ChangeDetectionStrategy, Component, HostListener, input, signal } from '@angular/core';
import { NgClass } from '@angular/common';
import { accordionAnimation } from '../animations/accordion-animation';

@Component({
  selector: 'ui-accordion',
  standalone: true,
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [accordionAnimation],
  imports: [NgClass],
})
export class AccordionComponent {
  readonly title = input.required<string>();

  readonly isExpanded = signal<boolean>(false);
  readonly isAnimating = signal<boolean>(false);

  @HostListener('click', ['$event'])
  onClick(): void {
    this.isAnimating.set(true);
    this.isExpanded.set(!this.isExpanded());
  }

  onTransitionEnd(): void {
    this.isAnimating.set(false);
  }
}
