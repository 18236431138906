{ 
  "locale": "eu",
  "countries": {
    "AF": "Afganistan",
    "AL": "Albania",
    "DZ": "Aljeria",
    "AS": "Samoa Estatubatuarra",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Aingira",
    "AQ": "Antartika", 
    "AG": "Antigua eta Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba", 
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamak",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Bielorrusia",
    "BE": "Belgika",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermudas",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia-Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet uhartea",
    "BR": "Brasil",
    "IO": "Indiako Ozeanoko Britainiar Lurraldea",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Kanbodia",
    "CM": "Kamerun",
    "CA": "Kanada",
    "CV": "Cabo Verde",
    "KY": "Kaiman uharteak", 
    "CF": "Afrika Erdiko Errepublika",
    "TD": "Txad",
    "CL": "Txile",
    "CN": "Txina",
    "CX": "Christmas uhartea",
    "CC": "Cocosak (Keeling uharteak)",
    "CO": "Kolonbia",
    "KM": "Komoreak",
    "CG": "Kongo",
    "CD": "Kongoko Errepublika Demokratikoa",
    "CK": "Cook uharteak",
    "CR": "Costa Rica",
    "CI": "Boli Kosta",
    "HR": "Kroazia",
    "CU": "Kuba",
    "CY": "Zipre",
    "CZ": "Txekia",
    "DK": "Danimarka",
    "DJ": "Djibuti",
    "DM": "Dominika",
    "DO": "Dominikar Errepublika",
    "EC": "Ekuador",
    "EG": "Egipto",
    "SV": "El Salvador",
    "GQ": "Ekuatore Ginea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Etiopia",
    "FK": "Malvinak",
    "FO": "Faroe uharteak",
    "FJ": "Fiji",
    "FI": "Finlandia",
    "FR": "Frantzia",
    "GF": "Guyana Frantsesa",
    "PF": "Polinesia Frantsesa", 
    "TF": "Frantziaren Lurralde Australak", 
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Alemania",
    "GH": "Ghana",
    "GI": "Gibraltar", 
    "GR": "Grezia",
    "GL": "Groenlandia",
    "GD": "Grenada",
    "GP": "Guadalupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Ginea",
    "GW": "Ginea Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard eta McDonald uharteak",
    "VA": "Vatikanoa (Egoitza Santua)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungaria",
    "IS": "Islandia",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Irlanda",
    "IL": "Israel",
    "IT": "Italia",
    "JM": "Jamaika",
    "JP": "Japonia",
    "JO": "Jordania",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Ipar Korea",
    "KR": "Hego Korea",
    "KW": "Kuwait",
    "KG": "Kirgizistan",
    "LA": "Laos",
    "LV": "Letonia",
    "LB": "Libano",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libia",
    "LI": "Liechtenstein",
    "LT": "Lituania",
    "LU": "Luxenburgo",
    "MO": "Macao",
    "MK": "Ipar Mazedonia",
    "MG": "Madagaskar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldivak",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Uharteak",
    "MQ": "Martinika",
    "MR": "Mauritania",
    "MU": "Maurizio",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Mikronesia",
    "MD": "Moldavia",
    "MC": "Monako",
    "MN": "Mongolia",
    "MS": "Montserrat",
    "MA": "Maroko",
    "MZ": "Mozambike",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Herbehereak",
    "NC": "Kaledonia Berria",
    "NZ": "Zeelanda Berria",
    "NI": "Nikaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk uhartea",
    "MP": "Ipar Marianak",
    "NO": "Norvegia",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestina",
    "PA": "Panama",
    "PG": "Papua Ginea Berria",
    "PY": "Paraguai",
    "PE": "Peru",
    "PH": "Filipinak",
    "PN": "Pitcairn uharteak",
    "PL": "Polonia",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Errumania",
    "RU": "Errusia",
    "RW": "Ruanda",
    "SH": "Santa Helena, Ascension eta Tristan da Cunha",
    "KN": "Saint Kitts eta Nevis",
    "LC": "Santa Luzia",
    "PM": "Saint-Pierre eta Mikelune",
    "VC": "Saint Vincent eta Grenadinak",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome eta Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "SC": "Seychelleak",
    "SL": "Sierra Leona",
    "SG": "Singapur",
    "SK": "Eslovakia",
    "SI": "Eslovenia",
    "SB": "Salomon Uharteak",
    "SO": "Somalia",
    "ZA": "Hegoafrika",
    "GS": "Hegoaldeko Georgiak eta Hegoaldeko Sandwichak",
    "ES": "Espainia",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Surinam",
    "SJ": "Svalbard eta Jan Mayen",
    "SZ": "Swazilandia",
    "SE": "Suedia",
    "CH": "Suitza",
    "SY": "Siriako Arabiar Errepublika",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailandia",
    "TL": "Ekialdeko Timor",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad eta Tobago",
    "TN": "Tunisia",
    "TR": "Turkia",
    "TM": "Turkmenistan",
    "TC": "Turkak eta Caicoak",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraina",
    "AE": "Arabiar Emirerri Batuak",
    "GB": "Erresuma Batua",
    "US": "Estatu Batuak",
    "UM": "Ameriketako Estatu Batuetako itsasoz haraindiko uharteak",
    "UY": "Uruguai",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Birjina britainiar uharteak",
    "VI": "Birjina Uharte Estatubatuarrak",
    "WF": "Wallis eta Futuna",
    "EH": "Mendebaldeko Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland uharteak",
    "BQ": "Bonaire, San Eustakio eta Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Man uhartea",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "San Bartolome",
    "MF": "San Martin (frantsesa)",
    "RS": "Serbia",
    "SX": "San Martin (herbeheretarra)",
    "SS": "Hego Sudan",
    "XK": "Kosovo"
  }
}
