{
  "locale": "mk",
  "countries": {
    "AD": "Андора",
    "AE": "Обединети Арапски Емирати",
    "AF": "Авганистан",
    "AG": "Антигва и Барбуда",
    "AI": "Ангвила",
    "AL": "Албанија",
    "AM": "Ерменија",
    "AO": "Ангола",
    "AQ": "Антарктик",
    "AR": "Аргентина",
    "AS": "Американска Самоа",
    "AT": "Австрија",
    "AU": "Австралија",
    "AW": "Аруба",
    "AX": "Оландски Острови",
    "AZ": "Азербејџан",
    "BA": "Босна и Херцеговина",
    "BB": "Барбадос",
    "BD": "Бангладеш",
    "BE": "Белгија",
    "BF": "Буркина Фасо",
    "BG": "Бугарија",
    "BH": "Бахреин",
    "BI": "Бурунди",
    "BJ": "Бенин",
    "BL": "Свети Вартоломеј",
    "BM": "Бермуди",
    "BN": "Брунеј",
    "BO": "Боливија",
    "BQ": "Карипска Холандија",
    "BR": "Бразил",
    "BS": "Бахами",
    "BT": "Бутан",
    "BV": "Остров Буве",
    "BW": "Боцвана",
    "BY": "Белорусија",
    "BZ": "Белизе",
    "CA": "Канада",
    "CC": "Кокосови (Килиншки) Острови",
    "CD": "Конго - Киншаса",
    "CF": "Централноафриканска Република",
    "CG": "Конго - Бразавил",
    "CH": "Швајцарија",
    "CI": "Брегот на Слоновата Коска",
    "CK": "Кукови Острови",
    "CL": "Чиле",
    "CM": "Камерун",
    "CN": "Кина",
    "CO": "Колумбија",
    "CR": "Костарика",
    "CU": "Куба",
    "CV": "Зелен ’Рт",
    "CW": "Курасао",
    "CX": "Божиќен Остров",
    "CY": "Кипар",
    "CZ": "Чешка",
    "DE": "Германија",
    "DJ": "Џибути",
    "DK": "Данска",
    "DM": "Доминика",
    "DO": "Доминиканска Република",
    "DZ": "Алжир",
    "EC": "Еквадор",
    "EE": "Естонија",
    "EG": "Египет",
    "EH": "Западна Сахара",
    "ER": "Еритреја",
    "ES": "Шпанија",
    "ET": "Етиопија",
    "FI": "Финска",
    "FJ": "Фиџи",
    "FK": "Фолкландски Острови",
    "FM": "Микронезија",
    "FO": "Фарски Острови",
    "FR": "Франција",
    "GA": "Габон",
    "GB": "Обединето Кралство",
    "GD": "Гренада",
    "GE": "Грузија",
    "GF": "Француска Гвајана",
    "GG": "Гернзи",
    "GH": "Гана",
    "GI": "Гибралтар",
    "GL": "Гренланд",
    "GM": "Гамбија",
    "GN": "Гвинеја",
    "GP": "Гвадалупе",
    "GQ": "Екваторска Гвинеја",
    "GR": "Грција",
    "GS": "Јужна Џорџија и Јужни Сендвички Острови",
    "GT": "Гватемала",
    "GU": "Гуам",
    "GW": "Гвинеја-Бисау",
    "GY": "Гвајана",
    "HK": "Хонг Конг С.А.Р Кина",
    "HM": "Остров Херд и Острови Мекдоналд",
    "HN": "Хондурас",
    "HR": "Хрватска",
    "HT": "Хаити",
    "HU": "Унгарија",
    "ID": "Индонезија",
    "IE": "Ирска",
    "IL": "Израел",
    "IM": "Остров Ман",
    "IN": "Индија",
    "IO": "Британска Индоокеанска Територија",
    "IQ": "Ирак",
    "IR": "Иран",
    "IS": "Исланд",
    "IT": "Италија",
    "JE": "Џерси",
    "JM": "Јамајка",
    "JO": "Јордан",
    "JP": "Јапонија",
    "KE": "Кенија",
    "KG": "Киргистан",
    "KH": "Камбоџа",
    "KI": "Кирибати",
    "KM": "Коморски Острови",
    "KN": "Свети Кристофер и Невис",
    "KP": "Северна Кореја",
    "KR": "Јужна Кореја",
    "KW": "Кувајт",
    "KY": "Кајмански Острови",
    "KZ": "Казахстан",
    "LA": "Лаос",
    "LB": "Либан",
    "LC": "Света Луција",
    "LI": "Лихтенштајн",
    "LK": "Шри Ланка",
    "LR": "Либерија",
    "LS": "Лесото",
    "LT": "Литванија",
    "LU": "Луксембург",
    "LV": "Латвија",
    "LY": "Либија",
    "MA": "Мароко",
    "MC": "Монако",
    "MD": "Молдавија",
    "ME": "Црна Гора",
    "MF": "Сент Мартин",
    "MG": "Мадагаскар",
    "MH": "Маршалски Острови",
    "MK": "Северна Македонија",
    "ML": "Мали",
    "MM": "Мјанмар (Бурма)",
    "MN": "Монголија",
    "MO": "Макао САР",
    "MP": "Северни Маријански Острови",
    "MQ": "Мартиник",
    "MR": "Мавританија",
    "MS": "Монсерат",
    "MT": "Малта",
    "MU": "Маврициус",
    "MV": "Малдиви",
    "MW": "Малави",
    "MX": "Мексико",
    "MY": "Малезија",
    "MZ": "Мозамбик",
    "NA": "Намибија",
    "NC": "Нова Каледонија",
    "NE": "Нигер",
    "NF": "Норфолшки Остров",
    "NG": "Нигерија",
    "NI": "Никарагва",
    "NL": "Холандија",
    "NO": "Норвешка",
    "NP": "Непал",
    "NR": "Науру",
    "NU": "Ниује",
    "NZ": "Нов Зеланд",
    "OM": "Оман",
    "PA": "Панама",
    "PE": "Перу",
    "PF": "Француска Полинезија",
    "PG": "Папуа Нова Гвинеја",
    "PH": "Филипини",
    "PK": "Пакистан",
    "PL": "Полска",
    "PM": "Сент Пјер и Микелан",
    "PN": "Питкернски Острови",
    "PR": "Порторико",
    "PS": "Палестински територии",
    "PT": "Португалија",
    "PW": "Палау",
    "PY": "Парагвај",
    "QA": "Катар",
    "RE": "Реунион",
    "RO": "Романија",
    "RS": "Србија",
    "RU": "Русија",
    "RW": "Руанда",
    "SA": "Саудиска Арабија",
    "SB": "Соломонски Острови",
    "SC": "Сејшели",
    "SD": "Судан",
    "SE": "Шведска",
    "SG": "Сингапур",
    "SH": "Света Елена",
    "SI": "Словенија",
    "SJ": "Свалбард и Жан Мејен",
    "SK": "Словачка",
    "SL": "Сиера Леоне",
    "SM": "Сан Марино",
    "SN": "Сенегал",
    "SO": "Сомалија",
    "SR": "Суринам",
    "SS": "Јужен Судан",
    "ST": "Сао Томе и Принсипе",
    "SV": "Ел Салвадор",
    "SX": "Свети Мартин",
    "SY": "Сирија",
    "SZ": "Свазиленд",
    "TC": "Острови Туркс и Каикос",
    "TD": "Чад",
    "TF": "Француски Јужни Територии",
    "TG": "Того",
    "TH": "Тајланд",
    "TJ": "Таџикистан",
    "TK": "Токелау",
    "TL": "Источен Тимор (Тимор Лесте)",
    "TM": "Туркменистан",
    "TN": "Тунис",
    "TO": "Тонга",
    "TR": "Турција",
    "TT": "Тринидад и Тобаго",
    "TV": "Тувалу",
    "TW": "Тајван",
    "TZ": "Танзанија",
    "UA": "Украина",
    "UG": "Уганда",
    "UM": "Американски територии во Пацификот",
    "US": "Соединети Американски Држави",
    "UY": "Уругвај",
    "UZ": "Узбекистан",
    "VA": "Ватикан",
    "VC": "Свети Винсент и Гренадините",
    "VE": "Венецуела",
    "VG": "Британски Девствени Острови",
    "VI": "Американски Девствени Острови",
    "VN": "Виетнам",
    "VU": "Вануату",
    "WF": "Валис и Футуна",
    "WS": "Самоа",
    "XK": "Косово",
    "YE": "Јемен",
    "YT": "Мајот",
    "ZA": "Јужноафриканска Република",
    "ZM": "Замбија",
    "ZW": "Зимбабве"
  }
}
