import { SectionIds } from '../enums';
import { MenuItem } from '../interfaces/menu-item.interface';

export const MENU_ITEMS: MenuItem[] = [
  {
    label: 'general.whyUs',
    link: '',
    sectionId: SectionIds.FirstSchemas,
  },
  {
    label: 'general.benefits',
    link: '',
    sectionId: SectionIds.Benefits,
  },
  {
    label: 'general.faq',
    link: '',
    sectionId: SectionIds.FAQ,
  },
];
