{
  "locale": "sq",
  "countries": {
    "AF": "Afganistan",
    "AL": "Shqipëri",
    "DZ": "Algjeri",
    "AS": "Samoa Amerikane",
    "AD": "Andorrë",
    "AO": "Angolë",
    "AI": "Anguilë",
    "AQ": "Antarktidë",
    "AG": "Antigua & Barbuda",
    "AR": "Argjentinë",
    "AM": "Armeni",
    "AW": "Arubë",
    "AU": "Australi",
    "AT": "Austri",
    "AZ": "Azerbajxhan",
    "BS": "Bahamas",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Bjellorusi",
    "BE": "Belgjikë",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Butan",
    "BO": "Bolivi",
    "BA": "Bosnjë & Hercegovinë",
    "BW": "Botsvanë",
    "BV": "Ishulli Buve",
    "BR": "Brazil",
    "IO": "Territori Britanik i Oqeanit Indian",
    "BN": "Brunej",
    "BG": "Bullgari",
    "BF": "Burkina Faso",
    "BI": "Burund",
    "KH": "Kamboxhia",
    "CM": "Kamerun",
    "CA": "Kanada",
    "CV": "Kepi i Gjelbër",
    "KY": "Ishujt Kajman",
    "CF": "Republika Afrikano-Qendrore",
    "TD": "Çad",
    "CL": "Kili",
    "CN": "Kinë",
    "CX": "Ishulli i Krishtlindjes",
    "CC": "Ishujt Kokos (Kiling)",
    "CO": "Kolumbi",
    "KM": "Komore",
    "CG": "Kongo-Brazavil",
    "CD": "Kongo-Kinshasa",
    "CK": "Ishulli Kuk",
    "CR": "Kosta Rikë",
    "CI": "Bregu i Fildishtë",
    "HR": "Kroaci",
    "CU": "Kubë",
    "CY": "Qipro",
    "CZ": "Republika Çeke",
    "DK": "Danimarkë",
    "DJ": "Xhibut",
    "DM": "Dominikë",
    "DO": "Republika Dominikane",
    "EC": "Ekuador",
    "EG": "Egjipt",
    "SV": "El Salvador",
    "GQ": "Guineja Ekuatoriale",
    "ER": "Eritre",
    "EE": "Estoni",
    "ET": "Etiopi",
    "FK": "Ishujt Folkland",
    "FO": "Ishujt Faroe",
    "FJ": "Fixhi",
    "FI": "Finlandë",
    "FR": "Francë",
    "GF": "Guajana Frënge",
    "PF": "Polinezia Frënge",
    "TF": "Territoret Frënge Jugore",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Gjeorgji",
    "DE": "Gjermani",
    "GH": "Gana",
    "GI": "Gjibraltar",
    "GR": "Greqi",
    "GL": "Groenlandë",
    "GD": "Grenadë",
    "GP": "Guadalup",
    "GU": "Guam",
    "GT": "Guatemalë",
    "GN": "Guine",
    "GW": "Guinea-Bisau",
    "GY": "Guajanë",
    "HT": "Haiti",
    "HM": "Ishujt Hërd & Mekdonald",
    "VA": "Vatikan",
    "HN": "Honduras",
    "HK": "RVA i Hong Kongut Kinë",
    "HU": "Hungari",
    "IS": "Islandë",
    "IN": "Indi",
    "ID": "Indonezi",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Irlandë",
    "IL": "Izrael",
    "IT": "Itali",
    "JM": "Xhamajkë",
    "JP": "Japoni",
    "JO": "Jordani",
    "KZ": "Kazakistan",
    "KE": "Kenia",
    "KI": "Kiribati",
    "KP": "Koreja e Veriut",
    "KR": "Koreja e Jugut",
    "KW": "Kuvajt",
    "KG": "Kirgizstan",
    "LA": "Laos",
    "LV": "Letoni",
    "LB": "Liban",
    "LS": "Lesoto",
    "LR": "Liberi",
    "LY": "Libi",
    "LI": "Lihtenshtein",
    "LT": "Lituani",
    "LU": "Luksemburg",
    "MO": "RVA i Makaos Kinë",
    "MG": "Madagaskar",
    "MW": "Malavi",
    "MY": "Malajzi",
    "MV": "Maldive",
    "ML": "Mali",
    "MT": "Maltë",
    "MH": "Ishujt Marshall",
    "MQ": "Martinikë",
    "MR": "Mauritani",
    "MU": "Mauritius",
    "YT": "Majote",
    "MX": "Meksikë",
    "FM": "Mikronezi",
    "MD": "Moldavi",
    "MC": "Monako",
    "MN": "Mongoli",
    "MS": "Montserat",
    "MA": "Marok",
    "MZ": "Mozambik",
    "MM": "Mianmar (Burma)",
    "NA": "Namibi",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Holandë",
    "NC": "Kaledonia e Re",
    "NZ": "Zelandë e Re",
    "NI": "Nikaragua",
    "NE": "Niger",
    "NG": "Nigeri",
    "NU": "Niue",
    "NF": "Ishujt Norfolk",
    "MK": "Maqedoni",
    "MP": "Ishujt Veriorë Mariana",
    "NO": "Norvegji",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Territoret Palestineze",
    "PA": "Panama",
    "PG": "Papua Guineja e Re",
    "PY": "Paraguai",
    "PE": "Peru",
    "PH": "Filipine",
    "PN": "Ishujt Pitkern",
    "PL": "Poloni",
    "PT": "Portugali",
    "PR": "Porto Riko",
    "QA": "Katar",
    "RE": "Reunion",
    "RO": "Rumani",
    "RU": "Rusi",
    "RW": "Ruandë",
    "SH": "Shën Helena",
    "KN": "Shën Kits e Nevis",
    "LC": "Shën Luçia",
    "PM": "Shën Pier dhe Mikëlon",
    "VC": "Shën Vinsent & Grenadinet",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome & Prinsipe",
    "SA": "Arabi Saudite",
    "SN": "Senegal",
    "SC": "Sejshelle",
    "SL": "Sierra Leone",
    "SG": "Singapor",
    "SK": "Sllovaki",
    "SI": "Slloveni",
    "SB": "Ishujt Solomon",
    "SO": "Somali",
    "ZA": "Afrikë e Jugut",
    "GS": "Xhorxha Jugore dhe Ishujt Sanduiç të Jugut",
    "ES": "Spanjë",
    "LK": "Sri Lankë",
    "SD": "Sudan",
    "SR": "Surinam",
    "SJ": "Svalbard & Zhan Majen",
    "SZ": "Svaziland",
    "SE": "Suedi",
    "CH": "Zvicër",
    "SY": "Siri",
    "TW": "Tajvan",
    "TJ": "Taxhikistan",
    "TZ": "Tanzani",
    "TH": "Tajlandë",
    "TL": "Timori Lindor",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad & Tobago",
    "TN": "Tunizi",
    "TR": "Turqi",
    "TM": "Turkmenistan",
    "TC": "Ishujt Turk & Kaikos",
    "TV": "Tuvalu",
    "UG": "Ugandë",
    "UA": "Ukrainë",
    "AE": "Emiratet e Bashkuara Arabe",
    "GB": "Mbretëria e Bashkuar",
    "US": "Shtetet e Bashkuara të Amerikës",
    "UM": "Ishujt e Përtejmë SHBA-së",
    "UY": "Uruguai",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuelë",
    "VN": "Vietnam",
    "VG": "Ishujt e Virgjër Britanikë",
    "VI": "Ishujt e Virgjër Amerikanë",
    "WF": "Uollis e Futuna",
    "EH": "Sahara Perëndimore",
    "YE": "Jemen",
    "ZM": "Zambia",
    "ZW": "Zimbabve",
    "AX": "Ishujt Aland",
    "BQ": "Karaibet Holandeze",
    "CW": "Kurasao",
    "GG": "Gërnsi",
    "IM": "Ishulli i Robit",
    "JE": "Xhërsi",
    "ME": "Mali i Zi",
    "BL": "Shën Bartolomeo",
    "MF": "Shën Martin",
    "RS": "Serbi",
    "SX": "Shën Martin",
    "SS": "Sudan i Jugut",
    "XK": "Kosovë"
  }
}
