{
  "locale": "sd",
  "countries": {
    "AF": "افغانستان",
    "AL": "البانيا",
    "DZ": "الجيريا",
    "AS": "آمريڪي ساموا",
    "AD": "اندورا",
    "AO": "انگولا",
    "AI": "انگويلا",
    "AQ": "انٽارڪٽيڪا",
    "AG": "انٽيگا ۽ باربوڊا",
    "AR": "ارجنٽينا",
    "AM": "ارمینیا",
    "AW": "عروبا",
    "AU": "آسٽريليا",
    "AT": "آسٽريا",
    "AZ": "آذربائيجان",
    "BS": "باهاماس",
    "BH": "بحرين",
    "BD": "بنگلاديش",
    "BB": "باربڊوس",
    "BY": "بیلارس",
    "BE": "بيلجيم",
    "BZ": "بيليز",
    "BJ": "بينن",
    "BM": "برمودا",
    "BT": "ڀوٽان",
    "BO": "بوليويا",
    "BA": "بوسنيا ۽ ھرزيگوينا",
    "BW": "بوٽسوانا",
    "BV": "بووٽ ٻيٽ",
    "BR": "برازيل",
    "IO": "برطانوي هندي سمنڊ خطو",
    "BN": "برونائي",
    "BG": "بلغاريا",
    "BF": "برڪينا فاسو",
    "BI": "برونڊي",
    "KH": "ڪمبوڊيا",
    "CM": "ڪيمرون",
    "CA": "ڪينيڊا",
    "CV": "ڪيپ وردي",
    "KY": "ڪي مين ٻيٽ",
    "CF": "وچ آفريقي جمهوريه",
    "TD": "چاڊ",
    "CL": "چلي",
    "CN": "چين",
    "CX": "ڪرسمس ٻيٽ",
    "CC": "ڪوڪوس ٻيٽ",
    "CO": "ڪولمبيا",
    "KM": "ڪوموروس",
    "CG": "ڪانگو - برازاویل",
    "CD": "ڪانگو -ڪنشاسا",
    "CK": "ڪوڪ ٻيٽ",
    "CR": "ڪوسٽا ريڪا",
    "CI": "ڪوٽ ڊي وار",
    "HR": "ڪروئيشيا",
    "CU": "ڪيوبا",
    "CY": "سائپرس",
    "CZ": "چيڪيا",
    "DK": "ڊينمارڪ",
    "DJ": "ڊجبيوتي",
    "DM": "ڊومينيڪا",
    "DO": "ڊومينيڪن جمهوريه",
    "EC": "ايڪواڊور",
    "EG": "مصر",
    "SV": "ال سلواڊور",
    "GQ": "ايڪوٽوريل گائينا",
    "ER": "ايريٽيريا",
    "EE": "ايسٽونيا",
    "ET": "ايٿوپيا",
    "FK": "فاڪ لينڊ ٻيٽ",
    "FO": "فارو ٻيٽ",
    "FJ": "فجي",
    "FI": "فن لينڊ",
    "FR": "فرانس",
    "GF": "فرانسيسي گيانا",
    "PF": "فرانسيسي پولينيشيا",
    "TF": "فرانسيسي ڏاکڻي علائقا",
    "GA": "گبون",
    "GM": "گيمبيا",
    "GE": "جارجيا",
    "DE": "جرمني",
    "GH": "گهانا",
    "GI": "جبرالٽر",
    "GR": "يونان",
    "GL": "گرين لينڊ",
    "GD": "گرينڊا",
    "GP": "گواڊیلوپ",
    "GU": "گوام",
    "GT": "گوئٽي مالا",
    "GN": "گني",
    "GW": "گني بسائو",
    "GY": "گيانا",
    "HT": "هيٽي",
    "HM": "هرڊ ۽ مڪڊونلڊ ٻيٽ",
    "VA": "ويٽڪين سٽي",
    "HN": "هنڊورس",
    "HK": "هانگ ڪانگ SAR",
    "HU": "هنگري",
    "IS": "آئس لينڊ",
    "IN": "ڀارت",
    "ID": "انڊونيشيا",
    "IR": "ايران",
    "IQ": "عراق",
    "IE": "آئرلينڊ",
    "IL": "اسرائيل",
    "IT": "اٽلي",
    "JM": "جميڪا",
    "JP": "جاپان",
    "JO": "اردن",
    "KZ": "قازقستان",
    "KE": "ڪينيا",
    "KI": "ڪرباتي",
    "KP": "اتر ڪوريا",
    "KR": "ڏکڻ ڪوريا",
    "KW": "ڪويت",
    "KG": "ڪرغستان",
    "LA": "لائوس",
    "LV": "لاتويا",
    "LB": "لبنان",
    "LS": "ليسوٿو",
    "LR": "لائبیریا",
    "LY": "لبيا",
    "LI": "لچي ٽينسٽين",
    "LT": "لٿونيا",
    "LU": "لگزمبرگ",
    "MO": "مڪائو SAR چين",
    "MG": "مدگاسڪر",
    "MW": "مالاوي",
    "MY": "ملائيشيا",
    "MV": "مالديپ",
    "ML": "مالي",
    "MT": "مالٽا",
    "MH": "مارشل ٻيٽ",
    "MQ": "مارتينڪ",
    "MR": "موريتانيا",
    "MU": "موريشس",
    "YT": "مياتي",
    "MX": "ميڪسيڪو",
    "FM": "مائڪرونيشيا",
    "MD": "مالدووا",
    "MC": "موناڪو",
    "MN": "منگوليا",
    "MS": "مونٽسراٽ",
    "MA": "مراڪش",
    "MZ": "موزمبیق",
    "MM": "ميانمار (برما)",
    "NA": "نيميبيا",
    "NR": "نائورو",
    "NP": "نيپال",
    "NL": "نيدرلينڊ",
    "NC": "نیو ڪالیڊونیا",
    "NZ": "نيو زيلينڊ",
    "NI": "نڪراگوا",
    "NE": "نائيجر",
    "NG": "نائيجيريا",
    "NU": "نووي",
    "NF": "نورفوڪ ٻيٽ",
    "MK": "اتر مقدونيا",
    "MP": "اتريان ماريانا ٻيٽ",
    "NO": "ناروي",
    "OM": "عمان",
    "PK": "پاڪستان",
    "PW": "پلائو",
    "PS": "فلسطيني علائقا",
    "PA": "پناما",
    "PG": "پاپوا نیو گني",
    "PY": "پيراگوءِ",
    "PE": "پيرو",
    "PH": "فلپائن",
    "PN": "پٽڪئرن ٻيٽ",
    "PL": "پولينڊ",
    "PT": "پرتگال",
    "PR": "پيوئرٽو ريڪو",
    "QA": "قطر",
    "RE": "ري يونين",
    "RO": "رومانيا",
    "RU": "روس",
    "RW": "روانڊا",
    "SH": "سينٽ ھيلينا",
    "KN": "سينٽ ڪٽس و نيوس",
    "LC": "سينٽ لوسيا",
    "PM": "سینٽ پیئر و میڪوئیلون",
    "VC": "سینٽ ونسنت ۽ گریناڊینز",
    "WS": "ساموا",
    "SM": "سین مرینو",
    "ST": "سائو ٽوم ۽ پرنسپیي",
    "SA": "سعودي عرب",
    "SN": "سينيگال",
    "SC": "شي شلز",
    "SL": "سيرا ليون",
    "SG": "سنگاپور",
    "SK": "سلوواڪيا",
    "SI": "سلوینیا",
    "SB": "سولومون ٻيٽَ",
    "SO": "سوماليا",
    "ZA": "ڏکڻ آفريقا",
    "GS": "ڏکڻ جارجيا ۽ ڏکڻ سينڊوچ ٻيٽ",
    "ES": "اسپين",
    "LK": "سري لنڪا",
    "SD": "سوڊان",
    "SR": "سورينام",
    "SJ": "سوالبارڊ ۽ جان ماین",
    "SZ": "ايسواٽني",
    "SE": "سوئيڊن",
    "CH": "سوئزرلينڊ",
    "SY": "شام",
    "TW": "تائیوان",
    "TJ": "تاجڪستان",
    "TZ": "تنزانيا",
    "TH": "ٿائيليند",
    "TL": "تيمور ليستي",
    "TG": "ٽوگو",
    "TK": "ٽوڪلائو",
    "TO": "ٽونگا",
    "TT": "ٽريني ڊيڊ ۽ ٽوباگو ٻيٽ",
    "TN": "تيونيسيا",
    "TR": "ترڪي",
    "TM": "ترڪمانستان",
    "TC": "ترڪ ۽ ڪيڪوس ٻيٽ",
    "TV": "توالو",
    "UG": "يوگنڊا",
    "UA": "يوڪرين",
    "AE": "متحده عرب امارات",
    "GB": "برطانيہ",
    "US": "آمريڪا جون گڏيل رياستون",
    "UM": "آمريڪي خارجي ٻيٽ",
    "UY": "يوروگوءِ",
    "UZ": "ازبڪستان",
    "VU": "وينيٽيو",
    "VE": "وينزويلا",
    "VN": "ويتنام",
    "VG": "برطانوي ورجن ٻيٽ",
    "VI": "آمريڪي ورجن ٻيٽ",
    "WF": "والس ۽ فتونا",
    "EH": "اولهه صحارا",
    "YE": "يمن",
    "ZM": "زيمبيا",
    "ZW": "زمبابوي",
    "AX": "الند ٻيٽ",
    "BQ": "ڪيريبين نيدرلينڊ",
    "CW": "ڪيوراسائو",
    "GG": "گورنسي",
    "IM": "انسانن جو ٻيٽ",
    "JE": "جرسي",
    "ME": "مونٽي نيگرو",
    "BL": "سینٽ برٿلیمی",
    "MF": "سينٽ مارٽن",
    "RS": "سربيا",
    "SX": "سنٽ مارٽن",
    "SS": "ڏکڻ سوڊان",
    "XK": "ڪوسووو"
  }
}
