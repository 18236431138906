{
  "locale": "bn",
  "countries": {
    "AD": "অ্যান্ডোরা",
    "AE": "সংযুক্ত আরব আমিরাত",
    "AF": "আফগানিস্তান",
    "AG": "অ্যান্টিগুয়া ও বার্বুডা",
    "AI": "এ্যাঙ্গুইলা",
    "AL": "আলবেনিয়া",
    "AM": "আর্মেনিয়া",
    "AO": "অ্যাঙ্গোলা",
    "AQ": "অ্যান্টার্কটিকা",
    "AR": "আর্জেন্টিনা",
    "AS": "আমেরিকান সামোয়া",
    "AT": "অস্ট্রিয়া",
    "AU": "অস্ট্রেলিয়া",
    "AW": "আরুবা",
    "AX": "অলান্দ দ্বীপপুঞ্জ",
    "AZ": "আজারবাইজান",
    "BA": "বসনিয়া ও হার্জেগোভিনা",
    "BB": "বার্বাডোস",
    "BD": "বাংলাদেশ",
    "BE": "বেলজিয়াম",
    "BF": "বুর্কিনা ফাসো",
    "BG": "বুলগেরিয়া",
    "BH": "বাহরাইন",
    "BI": "বুরুন্ডি",
    "BJ": "বেনিন",
    "BL": "সেন্ট বার্থলেমি",
    "BM": "বারমুডা",
    "BN": "ব্রুনাই দারুসালাম",
    "BO": "বলিভিয়া, বহুজাতিক রাষ্ট্র",
    "BQ": "ক্যারিবীয় নেদারল্যান্ডস",
    "BR": "ব্রাজিল",
    "BS": "বাহামা দ্বীপপুঞ্জ",
    "BT": "ভূটান",
    "BV": "বোভেট দ্বীপ",
    "BW": "বতসোয়ানা",
    "BY": "বেলারুশ",
    "BZ": "বেলিজ",
    "CA": "কানাডা",
    "CC": "কোকোস (কিলিং) দ্বীপপুঞ্জ",
    "CD": "গণতান্ত্রিক কঙ্গো প্রজাতন্ত্র",
    "CF": "মধ্য আফ্রিকান প্রজাতন্ত্র",
    "CG": "কঙ্গো প্রজাতন্ত্র",
    "CH": "সুইজারল্যান্ড",
    "CI": "কোত দিভোয়ার",
    "CK": "কুক দ্বীপপুঞ্জ",
    "CL": "চিলি",
    "CM": "ক্যামেরুন",
    "CN": "গণচীন",
    "CO": "কলম্বিয়া",
    "CR": "কোস্টা রিকা",
    "CU": "কিউবা",
    "CV": "কেপ ভার্দ",
    "CW": "কিউরাসাও",
    "CX": "ক্রিস্টমাস দ্বীপ",
    "CY": "সাইপ্রাস",
    "CZ": "চেক প্রজাতন্ত্র",
    "DE": "জার্মানি",
    "DJ": "জিবুতি",
    "DK": "ডেনমার্ক",
    "DM": "ডোমিনিকা",
    "DO": "ডোমিনিকান প্রজাতন্ত্র",
    "DZ": "আলজেরিয়া",
    "EC": "ইকুয়েডর",
    "EE": "এস্তোনিয়া",
    "EG": "মিশর",
    "EH": "পশ্চিম সাহারা",
    "ER": "ইরিত্রিয়া",
    "ES": "স্পেন",
    "ET": "ইথিওপিয়া",
    "FI": "ফিনল্যান্ড",
    "FJ": "ফিজি",
    "FK": "ফক্‌ল্যান্ড দ্বীপপুঞ্জ (মালভিনাস)",
    "FM": "মাইক্রোনেশিয়া যুক্তরাজ্য",
    "FO": "ফারো দ্বীপপুঞ্জ",
    "FR": "ফ্রান্স",
    "GA": "গাবন",
    "GB": "যুক্তরাজ্য এবং উত্তর আয়ারল্যান্ড",
    "GD": "গ্রানাডা",
    "GE": "জর্জিয়া",
    "GF": "ফরাসি গায়ানা",
    "GG": "Guernsey",
    "GH": "ঘানা",
    "GI": "জিব্রাল্টার",
    "GL": "গ্রিনল্যান্ড",
    "GM": "গাম্বিয়া",
    "GN": "গিনি",
    "GP": "গুয়াদলুপ",
    "GQ": "বিষুবীয় গিনি",
    "GR": "গ্রিস",
    "GS": "দক্ষিণ জর্জিয়া ও দক্ষিণ স্যান্ডউইচ দ্বীপপুঞ্জ",
    "GT": "গুয়াতেমালা",
    "GU": "গুয়াম",
    "GW": "গিনি-বিসাউ",
    "GY": "গায়ানা",
    "HK": "হংকং",
    "HM": "হার্ড দ্বীপ এবং ম্যাকডোনাল্ড দ্বীপপুঞ্জ",
    "HN": "হন্ডুরাস",
    "HR": "ক্রোয়েশিয়া",
    "HT": "হাইতি",
    "HU": "হাঙ্গেরি",
    "ID": "ইন্দোনেশিয়া",
    "IE": "প্রজাতন্ত্রী আয়ারল্যান্ড",
    "IL": "ইসরায়েল",
    "IM": "আইল অব ম্যান",
    "IN": "ভারত",
    "IO": "ব্রিটিশ ভারত মহাসাগরীয় এলাকা",
    "IQ": "ইরাক",
    "IR": "ইরান",
    "IS": "আইসল্যান্ড",
    "IT": "ইতালি",
    "JE": "Jersey",
    "JM": "জামাইকা",
    "JO": "জর্দান",
    "JP": "জাপান",
    "KE": "কেনিয়া",
    "KG": "কির্গিজস্তান",
    "KH": "কম্বোডিয়া",
    "KI": "কিরিবাস",
    "KM": "কোমোরোস",
    "KN": "সেন্ট কিট্‌স ও নেভিস",
    "KP": "কোরিয়া গণতান্ত্রিক গণপ্রজাতন্ত্রী",
    "KR": "কোরিয়া প্রজাতন্ত্র",
    "KW": "কুয়েত",
    "KY": "কেইম্যান দ্বীপপুঞ্জ",
    "KZ": "কাজাখস্তান",
    "LA": "গণতান্ত্রিক গণপ্রজাতন্ত্রী লাওস",
    "LB": "লেবানন",
    "LC": "সেন্ট লুসিয়া",
    "LI": "লিশটেনস্টাইন",
    "LK": "শ্রীলঙ্কা",
    "LR": "লাইবেরিয়া",
    "LS": "লেসোথো",
    "LT": "লিথুয়ানিয়া",
    "LU": "লুক্সেমবুর্গ",
    "LV": "লাতভিয়া",
    "LY": "লিবিয়া",
    "MA": "মরোক্কো",
    "MC": "মোনাকো",
    "MD": "মলদোভা প্রজাতন্ত্র",
    "ME": "মন্টিনিগ্রো",
    "MF": "সেন্ট মার্টিন (ফরাসি অংশ)",
    "MG": "মাদাগাস্কার",
    "MH": "মার্শাল দ্বীপপুঞ্জ",
    "MK": "ম্যাসেডোনিয়ার প্রাক্তন যুগোস্লাভ প্রজাতন্ত্র",
    "ML": "মালি",
    "MM": "মায়ানমার",
    "MN": "মঙ্গোলিয়া",
    "MO": "মাকাও",
    "MP": "উত্তর মারিয়ানা দ্বীপপুঞ্জ",
    "MQ": "মার্তিনিক",
    "MR": "মৌরিতানিয়া",
    "MS": "মন্টসেরাট",
    "MT": "মাল্টা",
    "MU": "মরিশাস",
    "MV": "মালদ্বীপ",
    "MW": "মালাউই",
    "MX": "মেক্সিকো",
    "MY": "মালয়েশিয়া",
    "MZ": "মোজাম্বিক",
    "NA": "নামিবিয়া",
    "NC": "নিউ ক্যালিডোনিয়া",
    "NE": "নাইজার",
    "NF": "নরফোক দ্বীপ",
    "NG": "নাইজেরিয়া",
    "NI": "নিকারাগুয়া",
    "NL": "নেদারল্যান্ডস",
    "NO": "নরওয়ে",
    "NP": "নেপাল",
    "NR": "নাউরু",
    "NU": "নিউয়ে",
    "NZ": "নিউজিল্যান্ড",
    "OM": "ওমান",
    "PA": "পানামা",
    "PE": "পেরু",
    "PF": "ফরাসি পলিনেশিয়া",
    "PG": "পাপুয়া নিউগিনি",
    "PH": "ফিলিপাইন",
    "PK": "পাকিস্তান",
    "PL": "পোল্যান্ড",
    "PM": "সাঁ পিয়ের ও মিক‌লোঁ",
    "PN": "পিটকেয়ার্ন",
    "PR": "পুয়ের্তো রিকো",
    "PS": "ফিলিস্তিন রাষ্ট্র",
    "PT": "পর্তুগাল",
    "PW": "পালাউ",
    "PY": "প্যারাগুয়ে",
    "QA": "কাতার",
    "RE": "রেউনিওঁ",
    "RO": "রোমানিয়া",
    "RS": "সার্বিয়া",
    "RU": "রাশিয়া",
    "RW": "রুয়ান্ডা",
    "SA": "সৌদি আরব",
    "SB": "সলোমন দ্বীপপুঞ্জ",
    "SC": "সেশেল",
    "SD": "সুদান",
    "SE": "সুইডেন",
    "SG": "সিঙ্গাপুর",
    "SH": "সেন্ট হেলেনা, অ্যাসেনশন ও ত্রিস্তান দা কুনহা",
    "SI": "স্লোভেনিয়া",
    "SJ": "স্বালবার্ড ও জান মায়েন",
    "SK": "স্লোভাকিয়া",
    "SL": "সিয়েরা লিওন",
    "SM": "সান মারিনো",
    "SN": "সেনেগাল",
    "SO": "সোমালিয়া",
    "SR": "সুরিনাম",
    "SS": "দক্ষিণ সুদান",
    "ST": "সাঁউ তুমি ও প্রিন্সিপি",
    "SV": "এল সালভাদোর",
    "SX": "সিন্ট মার্টেন (ওলন্দাজ অংশ)",
    "SY": "আরব প্রজাতন্ত্র সিরিয়া",
    "SZ": "সোয়াজিল্যান্ড",
    "TC": "টার্কস্‌ ও কেইকোস দ্বীপপুঞ্জ",
    "TD": "চাদ",
    "TF": "ফরাসি সাউদার্ন টেরিটোরিজ",
    "TG": "টোগো",
    "TH": "থাইল্যান্ড",
    "TJ": "তাজিকিস্তান",
    "TK": "টোকেলাউ",
    "TL": "তিমোর্‌ ল্যেশ্ত্যি",
    "TM": "তুর্কমেনিস্তান",
    "TN": "তিউনিসিয়া",
    "TO": "টোঙ্গা",
    "TR": "তুরস্ক",
    "TT": "ত্রিনিদাদ ও টোবাগো",
    "TV": "টুভালু",
    "TW": "তাইওয়ান, চীনের প্রদেশ",
    "TZ": "তানজানিয়া যুক্তপ্রজাতন্ত্র",
    "UA": "ইউক্রেন",
    "UG": "উগান্ডা",
    "UM": "মার্কিন যুক্তরাষ্ট্রের ক্ষুদ্র পার্শ্ববর্তী দ্বীপপুঞ্জ",
    "US": "মার্কিন যুক্তরাষ্ট্র",
    "UY": "উরুগুয়ে",
    "UZ": "উজবেকিস্তান",
    "VA": "ভ্যাটিকান সিটি",
    "VC": "সেন্ট ভিনসেন্ট ও গ্রেনাডাইন দ্বীপপুঞ্জ",
    "VE": "ভেনেজুয়েলার বোলিভিয় প্রজাতন্ত্র",
    "VG": "ব্রিটিশ ভার্জিন দ্বীপপুঞ্জ",
    "VI": "মার্কিন ভার্জিন দ্বীপপুঞ্জ ",
    "VN": "ভিয়েত নাম",
    "VU": "ভানুয়াটু",
    "WF": "ওয়ালিম ও ফুটুনা দ্বীপপুঞ্জের",
    "WS": "সামোয়া",
    "XK": "কসোভো",
    "YE": "ইয়েমেন",
    "YT": "মায়োত",
    "ZA": "দক্ষিণ আফ্রিকা",
    "ZM": "জাম্বিয়া",
    "ZW": "জিম্বাবুয়ে"
  }
}
