{
  "locale": "tk",
  "countries": {
    "AD": "Andorra",
    "AE": "Birleşen Arap Emiratlary",
    "AF": "Owganystan",
    "AG": "Antigua we Barbuda",
    "AI": "Angilýa",
    "AL": "Albaniýa",
    "AM": "Ermenistan",
    "AO": "Angola",
    "AQ": "Antarktida",
    "AR": "Argentina",
    "AS": "Amerika Samoa",
    "AT": "Awstriýa",
    "AU": "Awstraliýa",
    "AW": "Aruba",
    "AX": "Aland adalary",
    "AZ": "Azerbaýjan",
    "BA": "Bosniýa we Gertsegowina",
    "BB": "Barbados",
    "BD": "Bangladeş",
    "BE": "Belgiýa",
    "BF": "Burkina-Faso",
    "BG": "Bolgariýa",
    "BH": "Bahreýn",
    "BI": "Burundi",
    "BJ": "Benin",
    "BL": "Sen-Bartelemi",
    "BM": "Bermuda adalary",
    "BN": "Bruneý",
    "BO": "Boliwiýa",
    "BQ": "Boneýr, Sint-Estatius we Saba",
    "BR": "Braziliýa",
    "BS": "Bagama adalary",
    "BT": "Butan",
    "BV": "Buwe adasy",
    "BW": "Botswana",
    "BY": "Belarusiýa",
    "BZ": "Beliz",
    "CA": "Kanada",
    "CC": "Kokos adalary",
    "CD": "Kongo Demokratik Respublikasy",
    "CF": "Merkezi Afrika Respublikasy",
    "CG": "Kongo Respublikasy",
    "CH": "Şweýsariýa",
    "CI": "Kot-d’Iwuar",
    "CK": "Kuka adalary",
    "CL": "Çili",
    "CM": "Kamerun",
    "CN": "Kitaý",
    "CO": "Kolumbiýa",
    "CR": "Kosta-Rika",
    "CU": "Kuba",
    "CV": "Kabo-Werde",
    "CW": "Kýurasao",
    "CX": "Rojdestwo adasy",
    "CY": "Kipr",
    "CZ": "Çehiýa",
    "DE": "Germaniýa",
    "DJ": "Jibuti",
    "DK": "Daniýa",
    "DM": "Dominika",
    "DO": "Dominikan Respublikasy",
    "DZ": "Aljyr",
    "EC": "Ekwador",
    "EE": "Estoniýa",
    "EG": "Müsür",
    "EH": "Sahara Arap Demokratik Respublikasy",
    "ER": "Eritriýa",
    "ES": "Ispaniýa",
    "ET": "Efiopiýa",
    "FI": "Finlandiýa",
    "FJ": "Fiji",
    "FK": "Folklend adalary",
    "FM": "Mikroneziýa",
    "FO": "Farer adalary",
    "FR": "Fransiýa",
    "GA": "Gabon",
    "GB": "Beýik Britaniýa",
    "GD": "Grenada",
    "GE": "Gruziýa",
    "GF": "Gwiana",
    "GG": "Gernsi",
    "GH": "Gana",
    "GI": "Gibraltar",
    "GL": "Grenlandiýa",
    "GM": "Gambiýa",
    "GN": "Gwineýa",
    "GP": "Gwadelupa",
    "GQ": "Ekwatorial Gwineýa",
    "GR": "Gretsiýa",
    "GS": "Günorta Jorjiýa we Günorta Sandwiç adalary",
    "GT": "Gwatemala",
    "GU": "Guam",
    "GW": "Gwineýa-Bisau",
    "GY": "Gaýana",
    "HK": "Gonkong",
    "HM": "Hurd we Makdonald",
    "HN": "Gonduras",
    "HR": "Horwatiýa",
    "HT": "Gaiti",
    "HU": "wengriýa",
    "ID": "Indoneziýa",
    "IE": "Irlandiýa",
    "IL": "Ysraýyl",
    "IM": "Men adasy",
    "IN": "Hindistan",
    "IO": "Hindi ummanynda Britaniýa teritoriýasy",
    "IQ": "Eýran",
    "IR": "Iran",
    "IS": "Islandiýa",
    "IT": "Italiýa",
    "JE": "Jersi",
    "JM": "Ýamaýka",
    "JO": "Iordaniýa",
    "JP": "Ýaponiýa",
    "KE": "Keniýa",
    "KG": "Gyrgyzystan",
    "KH": "Kambodja",
    "KI": "Kiribati",
    "KM": "Komor adalary",
    "KN": "Sent-Kits we Newis",
    "KP": "Demirgazyk Koreýa",
    "KR": "Günorta Koreýa",
    "KW": "Kuweýt",
    "KY": "Kaýman adalary",
    "KZ": "Gazagystan",
    "LA": "Laos",
    "LB": "Liwan",
    "LC": "Sent-Lýusiýa",
    "LI": "Lihtenşteýn",
    "LK": "Şri-Lanka",
    "LR": "Liberiýa",
    "LS": "Lesoto",
    "LT": "Litwa",
    "LU": "Lýuksemburg",
    "LV": "Latwiýa",
    "LY": "Liwiýa",
    "MA": "Marokko",
    "MC": "Monako",
    "MD": "Moldowiýa",
    "ME": "Çernogoriýa",
    "MF": "Sent-Marten",
    "MG": "Madagaskar",
    "MH": "Marşall adalary",
    "MK": "Demirgazyk Makedoniýa",
    "ML": "Mali",
    "MM": "Mýanma",
    "MN": "Mongoliýa",
    "MO": "Makao",
    "MP": "Demirgazyk Mariana adalary",
    "MQ": "Martinika",
    "MR": "Mawritaniýa",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Mawrikiý",
    "MV": "Maldiw adalary",
    "MW": "Malawi",
    "MX": "Meksika",
    "MY": "Malaýziýa",
    "MZ": "Mozambik",
    "NA": "Namibiýa",
    "NC": "Täze Kaledoniýa",
    "NE": "Niger",
    "NF": "Norfolk adasy",
    "NG": "Nigeriýa",
    "NI": "Nikaragua",
    "NL": "Gollandiýa",
    "NO": "Norwegiýa",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Täze Zelandiýa",
    "OM": "Ummon",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Fransuz Polineziýasy",
    "PG": "Papua – Täze Gwineýa",
    "PH": "Filippin",
    "PK": "Pakistan",
    "PL": "Polşa",
    "PM": "Sen-Pýer we Mikelon",
    "PN": "Pitkern adalary",
    "PR": "Puerto-Riko",
    "PS": "Palestina döwleti",
    "PT": "Portugaliýa",
    "PW": "Palau",
    "PY": "Paragwaý",
    "QA": "Katar",
    "RE": "Reýunion",
    "RO": "Ruminiýa",
    "RS": "Serbiýa",
    "RU": "Rossiýa",
    "RW": "Ruanda",
    "SA": "Saud Arabystan",
    "SB": "Solomon adalary",
    "SC": "Seýşel adalary",
    "SD": "Sudan",
    "SE": "Şwesiýa",
    "SG": "Singapur",
    "SH": "Keramatly Ýelena adasy",
    "SI": "Sloweniýa",
    "SJ": "Swalbard we Ýan-Maýen",
    "SK": "Slowakiýa",
    "SL": "Sierra-Leone",
    "SM": "San-Marino",
    "SN": "Senegal",
    "SO": "Somali",
    "SR": "Surinam",
    "SS": "Günorta Sudan",
    "ST": "San-Tome we Prinsipi",
    "SV": "Salwador",
    "SX": "Sint-Marten",
    "SY": "Siriýa",
    "SZ": "Swazilend",
    "TC": "Terks we Kaýkos adalary",
    "TD": "Çad",
    "TF": "Fransiýanyň Günorta we Antarktika sebitleri",
    "TG": "Togo",
    "TH": "Taýland",
    "TJ": "Täjigistan",
    "TK": "Tokelau",
    "TL": "Gündogar Timor",
    "TM": "Türkmenistan",
    "TN": "Tunis",
    "TO": "Tonga",
    "TR": "Türkiýe",
    "TT": "Trinidad we Tobago",
    "TV": "Tuwalu",
    "TW": "Taýwan",
    "TZ": "Tanzaniýa",
    "UA": "Ukraina",
    "UG": "Uganda",
    "UM": "ABŞ-nyň daşky kiçi adalary",
    "US": "Amerika",
    "UY": "Urugwaý",
    "UZ": "Özbegistan ",
    "VA": "Watikan",
    "VC": "Sent-Winsent we Grenadin",
    "VE": "Wenesuela",
    "VG": "Britaniýa Wirgin adalary",
    "VI": "ABŞ-nyň Wirgin adalary",
    "VN": "Wýetnam",
    "VU": "Wanuatu",
    "WF": "Wallis we Futuna",
    "WS": "Samoa",
    "XK": "Kosowo",
    "YE": "Ýemen",
    "YT": "Maýotta",
    "ZA": "Günorta Afrika Respublikasy",
    "ZM": "Zambiýa",
    "ZW": "Zimbabwe"
  }
}
